import _ from 'lodash';

export const getInitialState = () => ({
  client: {
    businessName: '',
    number: null,
    website: null,
    businessTypeId: null,
    contentTypeIds: [],
    timeRangeId: null,
    priceRangeId: null,
  },
  creative: {
    number: null,
    instagramUrl: null,
    portfolio: null,
    zipcode: null,
    skillsets: [],
    cameraEquipments: [],
    lensEquipments: [],
    accessories: [],
    interestIds: [],
    styleIds: [],
    writerInterestIds: [],
    subjectIds: [],
  },
});
export default {
  SET_USER_PROFILE(state, user) {
    const role = _.get(user, 'role.name');
    state[role] = {
      ...state[role],
      ...user,
    };
  },
  RESET(state) {
    Object.assign(state, getInitialState());
  },
};

<template>
  <div class="w-100 d-flex justify-space-between align-center">
    <v-list-item-avatar class="file-image mr-1" width="20" height="20" min-width="25">
      <img :src="require('@/assets/svg/theme/image.svg')">
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title class="pb-1">
        <div class="d-flex align-center">
          <div class="file-name truncate-name">
            {{item.folderName}}
          </div>
          <div class="upload-size ml-3">
            {{downloadText}}
          </div>
        </div>
      </v-list-item-title>
      <template v-if="showProgress">
        <v-progress-linear
          :value="updatedProgress"
          color="info"
        ></v-progress-linear>
        <span v-if="showCompress" class="compress-text">
          {{zipProgressText}}
          </span>
      </template>
    </v-list-item-content>
    <v-list-item-action>
      <template v-if="item.completed">
        <v-icon  size="16" color="#02e648" class="icon">
          mdi-check-circle
        </v-icon>
      </template>
      <template v-else-if="item.error">
        <v-tooltip
          top
        >
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" size="16" color="red"
              @click="removeItem()"
            > mdi-close-circle </v-icon>
          </template>
          <span>
            {{item.error}}
          </span>
        </v-tooltip>
      </template>
      <template v-else>
        <v-icon size="16" class="icon"> mdi-check-circle </v-icon>
      </template>
    </v-list-item-action>
  </div>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';
// import { getUser, convertBytesToSize } from '@/helpers';
import _ from 'lodash';
import JSZip from 'jszip';
import saveAs from 'file-saver';

import JSZipUtils from 'jszip-utils';

const FileSaver = require('file-saver');

const urlToPromise = (url) => new Promise(((resolve, reject) => {
  JSZipUtils.getBinaryContent(url, (err, data) => {
    if (err) {
      reject(err);
    } else {
      resolve(data);
    }
  });
}));

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {
    zipProgressText() {
      return `Compressing(${this.zipProgress}%)`;
    },
    updatedProgress() {
      const { files } = this.item;
      if (files.length > 1) {
        return this.zipProgress;
      }
      return this.uploadPercentage;
    },
    downloadText() {
      const { files } = this.item;
      if (this.item.completed) { return `Downloaded ${files.length} file(s)`; }
      return `Downloading ${files.length} file(s)`;
      // if (files.length === 1) {
      //   return 'Downloading 1 file';
      // }
      // if (this.downloadingFileIndex === files.length) {
      //   return `Downloading ${files.length} files `;
      // }
      // return `Downloading ${this.downloadingFileIndex} of ${files.length}`;
    },

    showProgress() {
      return this.showDownloadProgress || this.showCompress;
    },
  },
  data() {
    return ({
      uploadPercentage: 0,
      showDownloadProgress: true,
      totalSize: 0,
      uploadedSize: 0,
      showCompress: false,
      zipProgress: 0,
      downloadingFileIndex: 0,
    });
  },
  methods: {
    ...mapActions(['removeUploadItemByIndex']),
    ...mapActions('contentKit', ['downloadContentKitFiles']),
    removeItem() {
      this.removeUploadItemByIndex(this.index);
    },
  },
  async mounted() {
    const { files } = this.item;
    const that = this;
    if (files.length === 1) {
      axios({
        url: files[0].url,
        method: 'GET',
        responseType: 'blob',
        onDownloadProgress(progressEvent) {
          that.uploadPercentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
        },
      }).then((response) => {
        this.item.completed = true;
        this.uploadPercentage = 100;
        FileSaver.saveAs(response.data, files[0].fileName);
      });
      return;
    }
    const zip = new JSZip();
    const arr = _.map(files, 'fileName');
    const dupValues = _.filter(arr,
      (val) => _.findIndex(arr, (a) => a === val) !== _.findLastIndex(arr, (a) => a === val));
    _.map(files, (data, i) => {
      this.downloadingFileIndex = i + 1;
      this.uploadPercentage = Math.round(((this.downloadingFileIndex) / files.length) * 90);
      const { url } = data;
      let { fileName } = data;
      if (_.includes(dupValues, fileName)) {
        const lastIndex = fileName.lastIndexOf('.');
        fileName = `${fileName.substr(0, lastIndex)}-${i}${fileName.substr(lastIndex)}`;
      }
      zip.file(fileName, urlToPromise(url), { binary: true });
    });
    this.showDownloadProgress = false;
    this.showCompress = true;
    zip.generateAsync({ type: 'blob' }, (metadata) => {
      this.zipProgress = metadata.percent.toFixed(0);
    })
      .then((blob) => {
        this.item.completed = true;
        this.uploadPercentage = 100;
        saveAs(blob, `${this.item.folderName}.zip`);
      }, () => {
      });
  },
};
</script>

<style scoped lang="scss">
  .file-image {
    border-radius: 0 !important;
  }
  .file-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.truncate-name {
      width: 48%;
    }
  }
  .upload-size, .compress-text {
    font-size: 12px;
    letter-spacing: -0.09px;
    color: #bababa;
    width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>

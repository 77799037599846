<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    hide-overlay
    mobile-break-point="600"
    :mini-variant="$vuetify.breakpoint.sm || collapsed"
    :left="$vuetify.breakpoint.xsOnly"
    :clipped="$vuetify.breakpoint.xsOnly"
    :class="{resetDrawer: $vuetify.breakpoint.xsOnly, collapsed: collapsed}"
    class="navigation-drawer-container"
  >
    <div v-if="$vuetify.breakpoint.smAndUp">
      <router-link :to="agency_customer
            ? `/organizations/${agencyId}` : '/dashboard'">
            <div v-if="$vuetify.breakpoint.sm || collapsed" class="logo-short">
          <v-img src="@/assets/svg/logo-short.svg" />
        </div>
        <div v-else class="logo">
          <v-img
            class="logo"
            src="@/assets/svg/logo.svg"
            contain
          />
        </div>
      </router-link>
    </div>
    <v-list dense nav class="py-0 nav-list" :key="navRefreshKey">
      <v-list-item
        v-for="item in navbarItems()"
        :key="item.title"
        :to="item.path"
      >
        <v-list-item-icon class="d-flex align-center">
          <img
          v-if="$vuetify.breakpoint.xsOnly"
          :src="require(`@/assets/svg/${item.icon}.svg`)"
          />
          <img
          v-else
          :src="require(`@/assets/svg/${$route.fullPath.indexOf(item.path) >= 0
          ? `${item.icon}-active` :item.icon}.svg`)"
          />
        </v-list-item-icon>

        <v-list-item-content>
            <v-badge
            v-if="(customer || agency_customer) && item.title === 'Brand Kit'
              && businessInfoLoaded && showBrandProfileBadge"
            overlap
            color="#F29F33"
            content="!"
            class="brand-badge">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-badge>
            <v-list-item-title v-else>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <template
      v-if="$vuetify.breakpoint.sm || collapsed">
        <v-list-item
          v-for="item in actionItems"
          :key="item.title"
          @click="item.action"
        >
          <v-list-item-icon class="d-flex align-center">
            <img :src="item.icon" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <div v-if="actionItems.length > 0" class="massive-action mt-7">
        <!-- add section title -->
        <!-- <v-list-group
        color="#FFF"
          no-action
          sub-group> -->
          <!-- <template v-slot:activator> -->
            <v-list-item-content>
              <v-list-item-title
              class="quickActions ml-5"
              >
              Quick Actions
              </v-list-item-title>
            </v-list-item-content>
          <!-- </template> -->
          <v-list-item
            v-for="item in actionItems"
            :key="item.title"
            @click="item.action"
          >
            <v-list-item-icon class="d-flex align-center">
              <img :src="item.icon" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        <!-- </v-list-group> -->
          </div>
    </v-list>
    <template v-slot:append>
      <div class="footer" v-if="$vuetify.breakpoint.xsOnly">
        <!-- <v-btn class="logout" text color="primary" @click="onLogout">
          Sign Out
        </v-btn> -->
      </div>
      <div class="footer" v-else :class="{expanded: !collapsed && $vuetify.breakpoint.mdAndUp}">
          <!-- <v-btn class="logout" x-large text icon color="black lighten-2" @click="onLogout">
            <img src="@/assets/svg/logout.svg" class="logout-icon" />
          </v-btn>
      </div>
      <div class="footer" v-else> -->
        <v-card class="modal-card pa-4 mb-2" v-if="cardVisibility"
         v-click-outside="selectOutsideClick">
        <!-- <v-btn text color="info">Schedule a Call with Blended Sense</v-btn> -->
          <div> Have a question or need support within
          the app? Contact Technical Support at
          <a class="anchorlink" href="mailto:support@blendedsense.com">
          support@blendedsense.com </a>
          </div>
        </v-card>
        <v-btn
          class="help" :x-small="collapsed || $vuetify.breakpoint.sm"
          :class="{ 'mb-2': $vuetify.breakpoint.sm}"
          color="#ffffff1a" @click="cardVisibility=!cardVisibility">
          <v-img src="@/assets/svg/comment.svg" class="help-icon"/>
          <span class="text" v-if="!(collapsed || $vuetify.breakpoint.sm)">Help?</span>
        </v-btn>
        <v-btn
          icon
          class="logout" text color="#939393"
          @click="toggleCollapse()"
          :class="{ block: collapsed, 'd-none': $vuetify.breakpoint.sm}"
        >
          <v-icon> mdi-code-tags </v-icon>
          <!-- <img src="@/assets/svg/logout.svg" class="logout-icon" /> -->
        </v-btn>
        <!-- <v-btn rounded icon class="collapse-btn" @click="toggleCollapse()"
          v-if="$vuetify.breakpoint.mdAndUp">
          <v-icon> {{collapseIcon}} </v-icon>
        </v-btn> -->
      </div>
    </template>
    <v-container
      fluid
      v-if="showLogoutConfirm"
    >
      <Modal
        :fullscreen="$vuetify.breakpoint.xsOnly"
        :modal="showLogoutConfirm"
        width="650"
        persistent
      >
          <v-card class="pa-5 confirm-popup">
            <v-row class="pa-0">
              <v-col>
                Logout may cancel upload/download process in progress.
                Are you sure you want to continue?
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="d-flex justify-end py-0">
                  <v-btn text
                    @click="showLogoutConfirm = false"
                    class="py-0"
                    color="primaryGray1"
                  >
                    Cancel
                  </v-btn>
                  <v-btn text
                    type="submit"
                    class="ml-2 btn-purple"
                    @click="logout()"
                  >
                    Yes, Logout.
                  </v-btn>
              </v-col>
            </v-row>
          </v-card>
      </Modal>
     </v-container>
  </v-navigation-drawer>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import vClickOutside from 'v-click-outside';
import Modal from '@/components/common/Modal';
import EventBus from '@/helpers/event-bus';

export default {
  name: 'NavigationDrawer',
  components: {
    Modal,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      drawer: this.$vuetify.breakpoint.smAndUp && true,
      cardVisibility: false,
      showLogoutConfirm: false,
      collapsed: false,
      sweepInviteCount: '',
      businessInfoLoaded: false,
      workspaces: [],
      navRefreshKey: Date.now(),
      hasContentKits: false,
    };
  },
  watch: {
    $route() {
      this.navRefreshKey = Date.now();
    },
  },
  methods: {
    ...mapActions('user', ['logoutUser']),
    ...mapActions('sweep', ['getSweepInvitiesCount']),
    ...mapActions('business',
      ['getBusiness', 'loadBusinessWorkspaces', 'checkBusinessHasContentKits']),
    ...mapActions('brandProfile', ['getBrandProfile']),
    navbarItems() {
      let items = [];
      if (this.customer) {
        items = [
          { title: 'Dashboard', icon: 'homedashboard', path: '/dashboard' },
          { title: 'Asset Library', icon: 'library', path: `/library/${this.projectId}/content_kits?businessId=${this.businessId}` },
          { title: 'Brand Kit', icon: 'brand-profile', path: '/brand_profile' },
          { title: 'Orders', icon: 'content_plan', path: '/orders' },
          { title: 'Sweeps', icon: 'calendar', path: '/sweeps' },
          { title: 'Settings', icon: 'gear', path: '/settings' },
        ];
        // const blendsExist = _.get(this.project, 'business.subscriptionBlends', []).length > 0;
        if (this.role === 'client') {
          items.splice(4, 0, { title: 'Billing', icon: 'dollar', path: '/billing' });
        }
      }
      if (_.includes(['executive', 'creative', 'editor', 'producer', 'admin', 'community_manager'], this.role)) {
        items = [
          { title: 'Dashboard', icon: 'homedashboard', path: '/dashboard' },
          { title: 'Businesses', icon: 'businesses-new', path: '/projects' },
          // { title: 'Profile', icon: 'user', path: '/profile' },
          // { title: 'Settings', icon: 'gear', path: '/settings' },
        ];
        if (this.role === 'creative') {
          items.push({ title: 'Portfolio', icon: 'adminusers', path: '/profile' });
        }
        if (this.role !== 'editor') {
          items.splice(2, 0, { title: this.sweepsTitle, icon: 'calendar', path: '/sweeps' });
        }
      }
      if (['admin', 'sales'].includes(this.role)) {
        items = [{ title: 'Dashboard', icon: 'homedashboard', path: '/dashboard' }];
      }
      if (this.canAccess('userManagement')) {
        items.push({ title: 'Users', icon: 'usersIcon', path: '/user_management' });
      }
      if (this.canAccess('projectManagement')) {
        if (this.role === 'admin') {
          items.push({ title: 'Businesses', icon: 'businesses-new', path: '/projects' });
        }
        // items.push({
        //   title: 'Project Management', icon: 'projects', path: '/project_management'
        // });
      }
      if (this.role === 'admin') {
        items.push({ title: 'Sweeps', icon: 'calendar', path: '/sweeps' });
        items.push({ title: 'Sweep Blocks', icon: 'calendar', path: '/sweep_blocks' });
        items.push({ title: 'Pay Center', icon: 'paycenter', path: '/pay_center' });
        items.push({ title: 'Promo Codes', icon: 'coupons', path: '/coupons' });
        items.push({ title: 'Production Menu', icon: 'productionmenu', path: '/production_menu' });
        // items.push({ title: 'Subscriptions', icon: 'subscriptions', path: '/subscriptions' });
        items.push({ title: 'APP Preferences', icon: 'gear', path: '/app_preferences' });
      }
      if (this.role === 'community_manager') {
        items = [
          { title: 'Dashboard', icon: 'homedashboard', path: '/dashboard' },
          { title: 'Businesses', icon: 'businesses-new', path: '/projects' },
          { title: 'Users', icon: 'usersIcon', path: '/user_management' },
          // { title: 'Profile', icon: 'user', path: '/profile' },
          // { title: 'Settings', icon: 'gear', path: '/settings' },
        ];
      }
      if (['agency_owner', 'agency_member'].includes(this.role)) {
        items = [
          { title: 'Dashboard', icon: 'homedashboard', path: '/dashboard' },
          { title: 'Businesses', icon: 'businesses-new', path: `/organizations/${this.agencyId}` },
          { title: 'Asset Library', icon: 'library', path: `/organization/${this.agencyId}/library` },
          { title: 'Brand Kit', icon: 'brand-profile', path: `/organization/${this.agencyId}/brandKit` },
          { title: 'Orders', icon: 'content_plan', path: '/orders' },
        ];
        items.push(
          { title: 'Sweeps', icon: 'calendar', path: '/sweeps' },
          { title: 'Settings', icon: 'gear', path: '/settings' },
        );
        // const blendsExist = _.get(this.business, 'subscriptionBlends', []).length > 0;
        if (this.role === 'agency_owner') {
          items.splice(5, 0, { title: 'Billing', icon: 'dollar', path: '/billing' });
        }
      }
      return items;
    },
    onLogout() {
      if (this.uploadingItemsCount || this.downloadingItemsCount) {
        this.showLogoutConfirm = true;
      } else {
        this.logout();
      }
    },
    logout() {
      this.logoutUser();
      this.$router.push('/');
    },
    toggleCollapse() {
      this.collapsed = !this.collapsed;
    },
    canAccess(type) {
      return _.get(this.userDetails, type, false);
    },
    selectOutsideClick() {
      if (this.cardVisibility) {
        this.cardVisibility = false;
      }
    },
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    ...mapGetters(['uploadingItems', 'downloadItems']),
    ...mapGetters('project', ['currentProject', 'userProjects']),
    ...mapGetters('brandProfile', ['brandProfile']),
    ...mapGetters('dashboard', ['project']),
    ...mapGetters('business', ['business']),
    contentKits() {
      return _.get(this.project.contentKits, '[0].id');
    },
    actionItems() {
      const items = [];
      if (this.customer || this.agency_customer) {
        items.push({
          title: 'Upload Footage',
          // eslint-disable-next-line
          icon: require('@/assets/svg/upload-gray.svg'),
          action: () => {
            EventBus.$emit('openMassivePortal');
          },
        });
      }
      return items;
    },
    showBrandProfileBadge() {
      if (!Object.keys(this.brandProfile).length) {
        return true;
      }

      const {
        brandColors,
        brandKits,
        brandKitLinks,
        purpose,
        brandMusic,
        brandStyle,
        brandKitInspirations,
        brandFonts,
        website,
        facebook,
        instagram,
        linkedin,
        pinterest,
        youtube,
      } = this.brandProfile;
      // Check for length, because brandFonts can be an empty object for
      // new profiles.
      const hasBrandFonts = Object.keys(brandFonts).length
        && ((brandFonts.primary && !!brandFonts.primary.name)
             || (brandFonts.secondary && !!brandFonts.secondary.name));

      const brandMusicData = Object.keys(brandMusic).length > 0;
      const brandStyleData = Object.keys(brandStyle).length > 0;
      if (
        (website || facebook || instagram || linkedin || pinterest || youtube)
      && brandKits.length > 0
      && brandColors.length > 0
      && hasBrandFonts
      && brandStyleData
      && brandMusicData
      && purpose
      && (brandKitInspirations.length > 0 || brandKitLinks.length > 0)) {
        return false;
      }
      return true;
    },
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    customer() {
      return ['client', 'member'].includes(this.role);
    },
    agency_customer() {
      return _.includes(['agency_owner', 'agency_member'], this.role);
    },
    projectId() {
      if (this.customer) {
        return _.get(this.workspaces, '[0].id');
      }
      return this.currentProject.id;
    },

    businessId() {
      const business = _.get(this.userDetails, 'userBusinesses[0]');
      const current = _.get(this.userDetails, 'currentBusinessId');
      // eslint-disable-next-line
      return this.sharedWithBusinessId || this.currentProject.businessId || business.id || current;
    },
    sharedWithBusinessId() {
      return this.currentProject.sharedWithBusinessId;
    },
    agencyId() {
      const business = _.get(this.userDetails, 'userBusinesses[0].id');
      const current = _.get(this.userDetails, 'currentBusinessId');
      return business || current;
    },

    userHasBusiness() {
      return this.userDetails.currentBusinessId;
    },

    canAccessUserManagement() {
      return _.get(this.userDetails, 'userManagement', false);
    },
    collapseIcon() {
      return this.collapsed ? 'mdi-chevron-right' : 'mdi-chevron-left';
    },
    uploadingItemsCount() {
      return _.filter(this.uploadingItems, { completed: false }).length;
    },
    downloadingItemsCount() {
      return _.filter(this.downloadItems, { completed: false }).length;
    },
    sweepsTitle() {
      return this.sweepInviteCount > 0 ? `Sweeps (${this.sweepInviteCount})` : 'Sweeps';
    },
  },
  async mounted() {
    if (['client', 'member', 'agency_owner', 'agency_member'].includes(this.role)) {
      if (this.userDetails.currentBusinessId) {
        const businessId = this.userDetails.currentBusinessId;
        await this.getBusiness(businessId);
        await this.getBrandProfile({
          businessId,
          reference: 'business',
        });
        this.businessInfoLoaded = true;
      }
    }
    if (['client', 'agency_owner', 'agency_member', 'member'].includes(this.role)) {
      // load all workspaces for client
      const { projects } = await this.loadBusinessWorkspaces({ businessId: this.businessId });
      this.workspaces = projects;
      this.hasContentKits = await this.checkBusinessHasContentKits({ businessId: this.businessId });
    }
    if (this.role === 'creative') {
      const result = await this.getSweepInvitiesCount();
      if (result.success) {
        this.sweepInviteCount = result.sweepInviteCount;
      }
      EventBus.$on('sweep-invite-counts', (val) => {
        if (val !== '') {
          this.sweepInviteCount = this.sweepInviteCount - 1;
        }
      });
    }
    EventBus.$on('toggle-navigation', (val) => {
      this.collapsed = val;
    });
  },
  beforeDestroy() {
    EventBus.$off('toggle-navigation');
    EventBus.$off('sweep-invite-counts');
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (max-device-width: 768px) {
  .massive-action {
    ::v-deep .v-list-group--sub-group.v-list-group--active .v-list-item__icon .v-icon {
      color: #939393 !important;
    }
    ::v-deep .v-list-group--sub-group {
      .v-list-group__header {
        margin: 0px !important;
        padding: 5px 8px 5px 20px !important;
        .v-list-item__icon {
          margin-right: 10px !important;
          .v-icon {
            color: #939393;
          }
        }
      }
    }
  }
}
.massive-action {
  ::v-deep .v-list-group--sub-group {
    .v-list-group__header {
      margin: 0px 17px 4px 15px;
      padding: 0px 8px;
      .v-list-item__icon {
        margin-right: 10px !important;
        .v-icon {
          color: #939393;
        }
      }
    }
    .v-list-group__items {
      .v-list-item {
        padding-left: 40px !important;
      }
    }
  }
  ::v-deep .v-list-group--sub-group.v-list-group--active {
    .v-list-item__icon .v-icon {
      color: #FFFF;
    }
  }
  .quickActions {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.05em;
    color: #939393;
  }
}
.massive-list-group {
  ::v-deep .v-list-item .v-list-group__header__prepend-icon{
    display: none;
  }
}
.brand-badge {
  ::v-deep .v-badge__badge {
    font-size: 12px;
    height: 15px;
    min-width: 15px;
    padding: 2px 6px;
    inset: auto auto calc(100% - 7px) calc(100% - 100px) !important;
  }
}
.logo {
  height: 64px;
  margin: 4px 0;
  padding: 0 15px;
}
.logo-short {
  padding: 10px;
  // max-width: 50px;
  // justify-content: center;
  // align-items: center;
  // display: flex;
}
.nav-list {
  background: none;
  margin-top: 35px;
}
.theme--light.v-navigation-drawer {
  background-color: $charcoalBlack;
  overflow: visible;
}
.resetDrawer {
  height: calc(100vh - 72px) !important;
  margin-top: 72px;
}
.v-list-item__icon img {
  width: 19px;
  height: 19px;
  object-fit: contain;
}
.v-list-item .v-list-item__icon {
  margin-right: 10px !important;
}
.v-list-item__icon {
    margin-top: auto !important;
    margin-bottom: auto !important;
}
.v-list-item__title {
  color: #939393;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 19px !important;
}
.v-list--nav {
  padding: 0 !important;
}
::v-deep .v-list-item--active {
  background: #ffffff1a;
  // color: #FFFF !important;
  .v-list-item__title {
    color: #FFFF !important;
  }
}
.footer.expanded{
  display: flex;
  justify-content: space-between;
  align-items: center;
  .v-btn.block {
    display: block;
  }
  .modal-card {
    bottom: 50px
  }
}
.footer {
    .v-card:not(.v-card--flat):not(.v-picker--time):not(.v-picker--date) {
      border-radius: 6px !important;
    }
  }
.modal-card {
    position: absolute;
    left: 15px;
    bottom: 80px;
    font-family: $fontFamily1;
    font-size: 14px;
    min-width: 240px !important;
    z-index: 2;
    width: 100%;
}
.anchorlink {
  color:$neutral1;
  font-weight: 600;
}
.help {
  height: 30px !important;
  margin: 0px 8px;
  border-radius: 10px;
  box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.05);
  padding: 2px 13px 4px 13px !important;
  ::v-deep .v-btn__content {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #939393;
  }
}
.footer.expanded {
  .help {
    margin: 16px;
  }
}
.help {
  .text {
    margin-left: 8px;
  }
}
.logout {
    margin: 8px;
}
.logout-icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
}
.confirm-popup {
  border-radius: 0 !important;
}
.collapse-btn {
  display: none;
  position: absolute;
  top: 80px;
  right: -17px;
}
.navigation-drawer-container:hover {
  box-shadow: none !important;
  .collapse-btn {
    display: block;
  }
}
.navigation-drawer-container.collapsed {
  .v-list--nav .v-list-item {
    justify-content: center;
    margin-right: 0px;
}
}
@media (min-width: 961px) {
   .v-list--nav .v-list-item{
    margin: 0px 17px 4px 15px !important;
    &:last-child {
      margin-bottom: 0px !important;
    }
  }
  .navigation-drawer-container.collapsed {
    .v-list--nav .v-list-item{
      padding-left: 20px !important;
    }
  }
}
@media (min-width: 600px) and (max-width: 960px) {
  ::v-deep .v-list-item--active {
      border-radius: 0 !important;
      margin-right: 0;
  }
  .v-list--nav .v-list-item {
    margin-right: 0;
    padding-left: 20px !important;
    padding-right: 25px !important;
  }
  .navigation-drawer-container.collapsed {
    .v-list--nav .v-list-item{
      padding-left: 35px !important;
    }
  }
  .modal-card {
    bottom: 43px;
    left: 8px;
  }
}
@media (max-width: 600px) {
    .nav-list {
        margin-top: 25px;
    }
    .theme--light.v-navigation-drawer {
        background-color: white;
    }
    ::v-deep .v-list-item--active {
        // background-color: rgba(101, 214, 235, 0.19) !important;
        .v-list-item__title {
              color: #939393 !important;
        }
    }
    .footer {
      display: flex;
      justify-content: center;
    }
    .resetDrawer {
      height: calc(100vh - 42px) !important;
      margin-top: 64px;
    }
    .v-list--nav .v-list-item {
      padding-left: 20px;
      padding-bottom: 5px;
      padding-top: 5px;
    }
}
</style>

import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';
import ClickOutside from 'vuetify/lib/directives/click-outside';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VNavigationDrawer,{staticClass:"navigation-drawer-container",class:{resetDrawer: _vm.$vuetify.breakpoint.xsOnly, collapsed: _vm.collapsed},attrs:{"app":"","hide-overlay":"","mobile-break-point":"600","mini-variant":_vm.$vuetify.breakpoint.sm || _vm.collapsed,"left":_vm.$vuetify.breakpoint.xsOnly,"clipped":_vm.$vuetify.breakpoint.xsOnly},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.$vuetify.breakpoint.xsOnly)?_c('div',{staticClass:"footer"}):_c('div',{staticClass:"footer",class:{expanded: !_vm.collapsed && _vm.$vuetify.breakpoint.mdAndUp}},[(_vm.cardVisibility)?_c(VCard,{directives:[{def: ClickOutside,name:"click-outside",rawName:"v-click-outside",value:(_vm.selectOutsideClick),expression:"selectOutsideClick"}],staticClass:"modal-card pa-4 mb-2"},[_c('div',[_vm._v(" Have a question or need support within the app? Contact Technical Support at "),_c('a',{staticClass:"anchorlink",attrs:{"href":"mailto:support@blendedsense.com"}},[_vm._v(" support@blendedsense.com ")])])]):_vm._e(),_c(VBtn,{staticClass:"help",class:{ 'mb-2': _vm.$vuetify.breakpoint.sm},attrs:{"x-small":_vm.collapsed || _vm.$vuetify.breakpoint.sm,"color":"#ffffff1a"},on:{"click":function($event){_vm.cardVisibility=!_vm.cardVisibility}}},[_c(VImg,{staticClass:"help-icon",attrs:{"src":require("@/assets/svg/comment.svg")}}),(!(_vm.collapsed || _vm.$vuetify.breakpoint.sm))?_c('span',{staticClass:"text"},[_vm._v("Help?")]):_vm._e()],1),_c(VBtn,{staticClass:"logout",class:{ block: _vm.collapsed, 'd-none': _vm.$vuetify.breakpoint.sm},attrs:{"icon":"","text":"","color":"#939393"},on:{"click":function($event){return _vm.toggleCollapse()}}},[_c(VIcon,[_vm._v(" mdi-code-tags ")])],1)],1)]},proxy:true}]),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('div',[_c('router-link',{attrs:{"to":_vm.agency_customer
          ? ("/organizations/" + _vm.agencyId) : '/dashboard'}},[(_vm.$vuetify.breakpoint.sm || _vm.collapsed)?_c('div',{staticClass:"logo-short"},[_c(VImg,{attrs:{"src":require("@/assets/svg/logo-short.svg")}})],1):_c('div',{staticClass:"logo"},[_c(VImg,{staticClass:"logo",attrs:{"src":require("@/assets/svg/logo.svg"),"contain":""}})],1)])],1):_vm._e(),_c(VList,{key:_vm.navRefreshKey,staticClass:"py-0 nav-list",attrs:{"dense":"","nav":""}},[_vm._l((_vm.navbarItems()),function(item){return _c(VListItem,{key:item.title,attrs:{"to":item.path}},[_c(VListItemIcon,{staticClass:"d-flex align-center"},[(_vm.$vuetify.breakpoint.xsOnly)?_c('img',{attrs:{"src":require(("@/assets/svg/" + (item.icon) + ".svg"))}}):_c('img',{attrs:{"src":require(("@/assets/svg/" + (_vm.$route.fullPath.indexOf(item.path) >= 0
        ? ((item.icon) + "-active") :item.icon) + ".svg"))}})]),_c(VListItemContent,[((_vm.customer || _vm.agency_customer) && item.title === 'Brand Kit'
            && _vm.businessInfoLoaded && _vm.showBrandProfileBadge)?_c(VBadge,{staticClass:"brand-badge",attrs:{"overlap":"","color":"#F29F33","content":"!"}},[_c(VListItemTitle,[_vm._v(_vm._s(item.title))])],1):_c(VListItemTitle,[_vm._v(_vm._s(item.title))])],1)],1)}),(_vm.$vuetify.breakpoint.sm || _vm.collapsed)?_vm._l((_vm.actionItems),function(item){return _c(VListItem,{key:item.title,on:{"click":item.action}},[_c(VListItemIcon,{staticClass:"d-flex align-center"},[_c('img',{attrs:{"src":item.icon}})]),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.title))])],1)],1)}):_vm._e(),(_vm.actionItems.length > 0)?_c('div',{staticClass:"massive-action mt-7"},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"quickActions ml-5"},[_vm._v(" Quick Actions ")])],1),_vm._l((_vm.actionItems),function(item){return _c(VListItem,{key:item.title,on:{"click":item.action}},[_c(VListItemIcon,{staticClass:"d-flex align-center"},[_c('img',{attrs:{"src":item.icon}})]),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.title))])],1)],1)})],2):_vm._e()],2),(_vm.showLogoutConfirm)?_c(VContainer,{attrs:{"fluid":""}},[_c('Modal',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.xsOnly,"modal":_vm.showLogoutConfirm,"width":"650","persistent":""}},[_c(VCard,{staticClass:"pa-5 confirm-popup"},[_c(VRow,{staticClass:"pa-0"},[_c(VCol,[_vm._v(" Logout may cancel upload/download process in progress. Are you sure you want to continue? ")])],1),_c(VRow,[_c(VCol,{staticClass:"d-flex justify-end py-0",attrs:{"cols":"12"}},[_c(VBtn,{staticClass:"py-0",attrs:{"text":"","color":"primaryGray1"},on:{"click":function($event){_vm.showLogoutConfirm = false}}},[_vm._v(" Cancel ")]),_c(VBtn,{staticClass:"ml-2 btn-purple",attrs:{"text":"","type":"submit"},on:{"click":function($event){return _vm.logout()}}},[_vm._v(" Yes, Logout. ")])],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
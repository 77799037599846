<template>
  <div class="w-100 d-flex justify-space-between align-center">
    <v-list-item-avatar class="file-image mr-1" width="20" height="20" min-width="25">
      <template v-if="item.error">
        <v-tooltip
          top
        >
          <template v-slot:activator="{ on }">
            <!-- <v-icon v-on="on" size="16" color="red"
              @click="removeItem()"
            > mdi-close-circle </v-icon> -->
            <span v-on="on" class="d-flex align-center">
              <Info  color="red" height="16" width="16" />
            </span>
          </template>
          <span>
            {{item.error}}
          </span>
        </v-tooltip>
      </template>
      <template v-else>
        <img :src="require('@/assets/svg/theme/image.svg')">
      </template>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        <div class="d-flex align-center">
        <div class="file-name" :class="{'truncate-name': showProgress}"> {{item.file.name}} </div>
        <div class="upload-size ml-3" v-if="showProgress">
          {{uploadedSize}} of {{totalSize}}
        </div>
        </div>
      </v-list-item-title>
      <span class="pt-1" v-if="showProgress">
      <v-progress-linear
        :value="uploadPercentage"
      ></v-progress-linear>
      </span>

    </v-list-item-content>
    <v-list-item-action>
      <template v-if="item.error">
        <v-icon size="16" color="red" @click="removeItem()"> mdi-close-circle </v-icon>
      </template>
      <template v-else-if="item.completed">
        <v-icon  size="16" color="#02e648" class="icon">
          mdi-check-circle
        </v-icon>
      </template>
      <div v-else @click="cancelItem()">
        <v-icon size="16" class="icon close-fade"> mdi-close-circle </v-icon>
      </div>
    </v-list-item-action>
  </div>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';
import _ from 'lodash';
import store from '@/store';
import { getUser, convertBytesToSize } from '@/helpers';
import Info from '@/components/common/svg/Info';

export default {
  components: {
    Info,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return ({
      uploadPercentage: 10,
      showProgress: true,
      totalSize: 0,
      uploadedSize: 0,
    });
  },
  methods: {
    ...mapActions(['removeUploadItemByIndex']),
    removeItem() {
      this.removeUploadItemByIndex(this.index);
    },
    cancelItem() {
      this.item.file.cancelSource.cancel('Upload cancelled');
    },
  },
  mounted() {
    if (!this.item.completed) {
      const baseURL = process.env.VUE_APP_BASE_API_URL || 'http://localhost:3000';
      const user = getUser();
      const formData = new FormData();
      formData.append('file', this.item.file);
      const endpoint = this.item.url || `content_kits/${this.item.contentKitId}/content_kit_file_upload`;
      axios.post(`${baseURL}/${endpoint}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${user.token}`,
            Accept: 'application/json',
          },
          onUploadProgress: (progressEvent) => {
          // showing 90% of the progress, will update to 100% once the success response from API
            this.uploadPercentage = Math.round((progressEvent.loaded / progressEvent.total) * 90);
            this.totalSize = convertBytesToSize(progressEvent.total);
            const uploadedSize = (90 / 100) * progressEvent.loaded;
            this.uploadedSize = convertBytesToSize(uploadedSize);
          },
          cancelToken: this.item.file.cancelSource.token,
        }).then((result) => {
        const mutation = this.item.mutation || 'contentKit/PUSH_CONTENT_KIT_FILE';
        store.commit(mutation, result.data);
        this.item.completed = true;
        this.showProgress = false;
      }).catch((e) => {
        if (axios.isCancel(e)) {
          this.item.error = _.get(e.response, 'data.error', e.message);
        } else {
          this.item.error = _.get(e.response, 'data.error', 'Could not upload file. Make sure you\'ve active internet connection.');
        }
        // TODO: need to update this error message more dynamic
        this.item.completed = true;
        this.showProgress = false;
      });
    } else {
      this.showProgress = false;
    }
  },
};
</script>

<style scoped lang="scss">
  .file-image {
    border-radius: 0 !important;
  }
  .file-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.truncate-name {
      width: 50%;
    }
  }
  .upload-size {
    font-size: 12px;
    letter-spacing: -0.09px;
    color: #bababa;
    width: 45%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .close-fade {
    cursor: pointer;
  }
</style>

import axios from 'axios';
import { getUser, authenticate } from '.';

export default (context) => {
  const baseURL = process.env.VUE_APP_BASE_API_URL || 'http://localhost:3000'
  const API = axios.create({
    baseURL,
    headers: {
      Accept: 'application/json',
      'client-id': process.env.VUE_APP_CLIENT_ID,
      'client-secret': process.env.VUE_APP_CLIENT_SECRET,
      'Access-Control-Allow-Origin': '*',
    },
  });

  API.interceptors.request.use((config) => {
    const user = getUser();
    const configuration = config;
    if (user) {
      configuration.headers.Authorization = `Bearer ${user.token}`;
    }
    return configuration;
  });
  API.interceptors.response.use(
    (response) => {
      if (response.data) {
        if (response.data.message) {
          context.dispatch('setNotification', {
            message: response.data.message,
            type: 'success',
          }, { root: true });
        }
        return Object.assign({ success: true }, response.data);
      }
      return response;
    },
    (error) => {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message || error.response.data.error;
        if (errorMessage) {
          context.dispatch('setNotification', {
            message: errorMessage,
            type: 'error',
          }, { root: true });
        }
      } else if (error.response && error.response.status === 401) {
        const initUrl = window.location.pathname;
        authenticate(initUrl);
      } else if (error.message === 'Network Error') {
        // adblocker errors can cause these, and they are _frequent_ if a user
        // has an adblocker installed. not sure if we need to log any at all.
        return error;
      }
      // babel got angry about consistent return when this was in the if / else
      // tree above
      return error.response ? error.response : error;
    },
  );
  return API;
};

import API from '@/helpers/api';

export default {
  async getAgencies(context, status) {
    const type = status || 1;
    const result = await API(context).get(`/admin/agency?status=${type}`);
    if (result.success) {
      context.commit('SET_AGENCIES', result.agencies);
    }
    return result;
  },
  async addAgency(context, payload) {
    const result = await API(context).post('/admin/agency', payload.organization);
    if (result.success) {
      context.dispatch('getAgencies', payload.status);
    }
    return result;
  },
  async updateAgency(context, { id, organization, status }) {
    const result = await API(context).put(`/admin/agency/${id}`, organization);
    if (result.success) {
      context.dispatch('getAgencies', status);
    }
    return result;
  },
  async deleteAgency(context, { status, id }) {
    const result = await API(context).delete(`/admin/agency/${id}`);
    if (result.success) {
      context.dispatch('getAgencies', status);
    }
    return result;
  },
  async getAgencyBusinesses(context, { businessId, status }) {
    const type = status || 1;
    const result = await API(context).get(`/agency/businesses?businessId=${businessId}&status=${type}`);
    if (result.success) {
      context.commit('SET_AGENCY_BUSINESS', result.businesses);
    }
    return result;
  },
  async getBusinessAgencies(context, { businessId }) {
    const result = await API(context).get(`/agency/businessAgencies?businessId=${businessId}`);
    if (result.success) {
      context.commit('SET_AGENCY_BUSINESS', result.businesses);
    }
    return result;
  },
  async addBusiness(context, payload) {
    const result = await API(context).post('/admin/agency/business', payload.business);
    if (result.success) {
      context.dispatch('getAgencyBusinesses', {
        businessId: payload.agencyId, status: payload.status,
      });
    }
    return result;
  },
  async updateBusiness(context, payload) {
    const result = await API(context).put(`/admin/agency/business/${payload.businessId}`, payload.business);
    if (result.success) {
      context.dispatch('getAgencyBusinesses', { businessId: payload.agencyId, status: payload.status });
    }
    return result;
  },
  async removeAffiliation(context, payload) {
    const result = await API(context).delete('/admin/agency/business/unaffiliate', { data: payload });
    if (result.success) {
      if (payload.reference === 'organization') {
        context.dispatch('getAgencyBusinesses', {
          businessId: payload.businessId,
          status: payload.status,
        });
      } else {
        context.dispatch('getBusinessAgencies', {
          businessId: payload.businessId,
          status: payload.status,
        });
      }
    }
    return result;
  },
  async getAgencyUsers(context, agencyId) {
    const result = await API(context).get(`/agency/users?businessId=${agencyId}`);
    if (result.success) {
      context.commit('SET_AGENCY_USERS', result.users);
    }
    return result;
  },
  async addAgencyMember(context, payload) {
    const result = await API(context).post('/admin/agency/add_member', payload);
    if (result.success) {
      context.dispatch('getAgencyUsers', payload.businessId);
    }
    return result;
  },
  async updateAgencyMember(context, payload) {
    const result = await API(context).put('/admin/agency/update_member', payload);
    if (result.success) {
      context.dispatch('getAgencyUsers', payload.businessId);
      // payload.setRole();
    } else {
      payload.unSetRole();
    }
  },
  async deleteAgencyMember(context, payload) {
    const result = await API(context).delete('/admin/agency/delete_member', { data: payload });
    if (result.success) {
      context.dispatch('getAgencyUsers', payload.businessId);
    }
    return result;
  },
  async getSingleAgency(context, id) {
    const result = await API(context).get(`/admin/agency/${id}`);
    if (result.success) {
      context.commit('SET_AGENCY', result.agency);
    }
    return result;
  },
  async updateAgencySettings(context, payload) {
    const result = await API(context).post('/agency/settings', payload);
    if (result.success) {
      context.dispatch('getSingleAgency', result.agency.id);
      context.dispatch('brandProfile/getBrandProfile', { agencyId: result.agency.id, reference: 'agency' }, { root: true });
    }
    return result;
  },
  async updateBusinessSettings(context, payload) {
    const result = await API(context).post('/business/settings', payload);
    if (result.success) {
      context.dispatch('business/getBusiness', result.business.id, { root: true });
      context.dispatch('brandProfile/getBrandProfile', { businessId: result.business.id, reference: 'business' }, { root: true });
    }
    return result;
  },
  async importBusinesses(context, payload) {
    const result = await API(context).post('/agency/import-businesses', payload);
    if (result.success) {
      return result;
    }
    return result;
  },
  async getBusinessWorkSpaces(context, { businessId }) {
    const result = await API(context).get(`/business/workspaces?businessId=${businessId}`);
    return result;
  },
};

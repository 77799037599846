import qs from 'qs';
import _ from 'lodash';
import API from '@/helpers/api';

export default {
  async getUserContentKits(context, { projectId, status, isPlans }) {
    const kitStatus = status || 1;
    let url = `/content_kits?projectId=${projectId}&status=${kitStatus}`;
    if (isPlans) {
      url += '&isPlans=true';
    }
    const result = await API(context).get(url);
    if (result.success) {
      context.commit('SET_USER_CONTENT_KITS', result.contentKit);
      context.commit('SET_USER_SUBSCRIPTION_BLENDS', result.projectSnapshot);
    }
    return result;
  },

  async addContentKit(context, contentKit) {
    const result = await API(context).post('/content_kits', contentKit);
    if (result.success) {
      context.dispatch('getUserContentKits', { projectId: result.contentKit.projectId });
    }
    return result;
  },

  async getContentKit(context, contentKitId) {
    const result = await API(context).get(`/content_kits/${contentKitId}`);
    if (result.success) {
      const contentKitDetails = _.omit(result.contentKit, 'planStatus');
      context.commit('GET_CONTENT_KIT', contentKitDetails);
    }
    return result;
  },

  async updateContentKit(context, { id, payload }) {
    const result = await API(context).put(`/content_kits/${id}`, payload);
    if (result.success) {
      context.commit('UPDATE_CONTENT_KIT', result.contentKit);
    }
    return result;
  },

  async addSocialTags(context, { projectDeliverableId, socialTagId }) {
    const obj = { projectDeliverableId, socialTagId };
    const result = await API(context).post('/content_kits/project_deliverable/add_social_tag', obj);
    return result;
  },

  async addDimension(context, { projectDeliverableId, dimensionId }) {
    const obj = { projectDeliverableId, dimensionId };
    const result = await API(context).post('/content_kits/project_deliverable/add_dimension', obj);
    return result;
  },

  async removeDimension(context, { projectDeliverableId, dimensionId }) {
    const data = { projectDeliverableId, dimensionId };
    const result = await API(context).delete('/content_kits/project_deliverable/remove_dimension', { data });
    return result;
  },

  async deliverableStatus(context, { projectDeliverableId, status }) {
    const obj = { projectDeliverableId, status };
    const result = await API(context).post('/content_kits/project_deliverable/update_status', obj);
    return result;
  },
  async removeSocialTags(context, { projectDeliverableId, socialTagId }) {
    const data = { projectDeliverableId, socialTagId };
    const result = await API(context).delete('/content_kits/project_deliverable/remove_social_tag', { data });
    return result;
  },

  async getCurrentKit({ commit, state }, contentKitId) {
    // eslint-disable-next-line eqeqeq
    commit('SET_CURRENT_CONTENT_KIT', _.find(state.contentKits, (c) => c.id == contentKitId));
    return _.find(state.contentKits, (c) => c.id === contentKitId);
  },

  async getDeliverablesByContentKitId(context, id) {
    const result = await API(context).get(`/content_kits/${id}`);
    if (result.success) {
      context.commit('SET_CURRENT_KIT_PROJECTDELIVERABLES', result.contentKit.projectDeliverables);
    }
    return result;
  },

  async updateContentKitStatus(context, { id, status }) {
    const result = await API(context).put(`/content_kits/${id}/content_kit_status_update`, { status });
    if (result.success) {
      context.commit('UPDATE_CONTENT_KIT', result.contentKit);
    }
    return result;
  },

  async getDimensions(context) {
    const result = await API(context).get('/dimensions/list');
    if (result.success) {
      context.commit('SET_DIMENSIONS', result.dimensions);
    }
    return result;
  },

  async getSocialTags(context) {
    const result = await API(context).get('/social_tags/list');
    if (result.success) {
      context.commit('SET_SOCIAL_TAGS', result.socialTags);
    }
    return result;
  },
  // eslint-disable-next-line
  async getContentKitFiles(context, { workspaceId, utilityId, showShared, page, skip, limit, searchText, socialTags, fileTypes, favorite, sortBy, append = false }) {
    if (workspaceId) {
      const params = new URLSearchParams();
      params.append('workspaceId', workspaceId);
      params.append('utilityId', utilityId);
      params.append('showShared', showShared);
      params.append('page', page);
      if (skip) params.append('skip', skip);
      if (limit) params.append('limit', limit);
      if (searchText) params.append('searchText', searchText);
      if (socialTags && Array.isArray(socialTags)) params.append('socialTags', socialTags.join(','));
      if (fileTypes && Array.isArray(fileTypes)) params.append('fileTypes', fileTypes.join(','));
      if (favorite) params.append('favorite', favorite);
      if (sortBy) params.append('sortBy', sortBy);
      const result = await API(context).get(`/content_kits/filesByUtilitiy?${params.toString()}`);
      if (result.success) {
        if (append) {
          context.commit('APPEND_CONTENT_KIT_FILES', result.files);
        } else {
          context.commit('SET_CONTENT_KIT_FILES', result.files);
        }
      }
      return result;
    }
    return false;
  },

  async updateContentKitFile(context, { contentKitId, payload }) {
    const result = await API(context).put(`/content_kits/${contentKitId}/content_kit_file_update`, payload);
    if (result.success) {
      context.commit('UPDATE_CONTENT_KIT_FILE', result.contentKitFile);
      context.commit('UPDATE_ASSET_TAGS',
        {
          socialTags: result.contentKitFile.socialTags,
          dimension: result.contentKitFile.dimension,
        });
    }
    return result;
  },

  async setContentKitFiles({ commit }, contentKitFiles) {
    commit('SET_CONTENT_KIT_FILES', contentKitFiles);
  },

  async archiveContentKitFiles(context, { contentKitId, payload, status }) {
    const result = await API(context).put(`/content_kits/${contentKitId}/content_kit_archive_files`, payload);
    if (result.success) {
      context.dispatch('getContentKitFiles', { contentKitId, status });
    }
    return result;
  },

  async assignContentKitFiles(context, { contentKitId, payload }) {
    const result = await API(context).put(`/content_kits/${contentKitId}/content_kit_file_assign`, payload);
    if (result.success) {
      context.dispatch('getContentKitFiles', { contentKitId });
    }
    return result;
  },

  async assignFilesToDeliverable(context, { contentKitId, payload }) {
    const result = await API(context).put(`/content_kits/${contentKitId}/assign_deliverables`, payload);
    if (result.success) {
      context.dispatch('getContentKitFiles', { contentKitId });
    }
    return result;
  },

  async bulkEditContentKitFiles(context, { payload }) {
    const result = await API(context).post('/content_kits/contentkit_file/bulk_edit', payload);
    return result;
  },

  async moveContentKitFiles(context, { contentKitId, status, payload }) {
    const result = await API(context).put(`/content_kits/${contentKitId}/content_kit_file_move`, payload);
    if (result.success) {
      context.dispatch('getContentKitFiles', { contentKitId, status });
    }
    return result;
  },

  async releaseContentKitFiles(context, { contentKitId, payload }) {
    const result = await API(context).put(`/content_kits/${contentKitId}/content_kit_file_release`, payload);
    if (result.success) {
      context.dispatch('getContentKitFiles', { contentKitId });
    }
    return result;
  },
  async contentKitFilesUpload(context, { contentKitId, files }) {
    const formData = new FormData();
    _.forEach(files, (file) => {
      formData.append('files', file);
    });
    const result = await API(context).post(`/content_kits/${contentKitId}/content_kit_file_upload`, formData);
    if (result.success) {
      context.dispatch('getContentKitFiles', { contentKitId });
    }
    return result;
  },

  async downloadContentKitFiles(context, {
    projectId, utilityId, contentKitId, fileIds,
  }) {
    let api;
    if (projectId) {
      api = `/projects/${projectId}/content_kit_files_download`;
    } else {
      api = `/content_kits/${contentKitId}/content_kit_files_download`;
    }
    const result = await API(context).get(api,
      {
        params: { contentKitFileIds: fileIds, utilityId },
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
      });
    return result;
  },

  async updateFavoriteContentKits(context, { id, workspaceId }) {
    const result = await API(context).post('/user_favorites/favorite',
      { contentKitFileId: id, workspaceId });
    if (result.success) {
      context.commit('UPDATE_CONTENT_KIT_FILE_ATTRS', { id, attrs: { favorite: true } });
    }
    return result;
  },

  async unfavoriteContentKits(context, { id, workspaceId }) {
    const result = await API(context).put('/user_favorites/unfavorite',
      { contentKitFileId: id, workspaceId });
    if (result.success) {
      context.commit('UPDATE_CONTENT_KIT_FILE_ATTRS', { id, attrs: { favorite: false } });
    }
    return result;
  },

  async getAllContentKitFiles(context, projectId) {
    const result = await API(context).get(`/projects/${projectId}/content_kit_files`);
    if (result.success) {
      context.commit('SET_CONTENT_KIT_FILES', result.files);
    }
  },

  async uploadPoster(context, { fileId, file }) {
    const formData = new FormData();
    formData.set('poster', file);
    const result = await API(context).put(`/content_kits/${fileId}/poster_upload`, formData);
    if (result.success) {
      context.commit('UPDATE_CONTENT_KIT_FILE_POSTER', { id: fileId, poster: result.contentKitFile.poster });
    }
    return result;
  },

  async getAllCreativeAssets(context) {
    const result = await API(context).get('/portfolio/creative_assets');
    if (result.success) {
      context.commit('SET_CONTENT_KIT_FILES', result.contentKitFiles);
    }
    return result;
  },

  async addMyPortfolio(context, payload) {
    const result = await API(context).post('/portfolio/upload', payload);
    if (result.success) {
      context.dispatch('getAllCreativeAssets');
      context.dispatch('profile/getPortfolioItems', payload.userId, { root: true });
    }
    return result;
  },
  async checkUploadContentKit(context, { projectId }) {
    const result = await API(context).get(`content_kits/check_accessibility_of_content_kit/${projectId}`);
    if (result.success) {
      return result.canUpload;
    }
    return false;
  },
  async canCreateContentKit(context, { projectId }) {
    const result = await API(context).get(`content_kits/can-create-content-kit/${projectId}`);
    if (result.success) {
      return result.canCreate;
    }
    return false;
  },
  resetContentKits({ commit }) {
    commit('RESET_CONTENT_KITS');
  },

  resetContentKitFiles({ commit }) {
    commit('RESET_CONTENT_KIT_FILES');
  },

  resetCurrentContentKit({ commit }) {
    commit('RESET_CURRENT_CONTENT_KIT');
  },
  setLoadingMore({ commit }, payload) {
    commit('SET_LOADING_MORE', payload);
  },
};

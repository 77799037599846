<template>
  <div></div>
</template>

<script>
import Vue from 'vue';
import languages from './languages.json';
import { setCookie, getCookie } from './utils';

const translatorScriptURL = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
export default {
  name: 'hyper-translate',
  data() {
    return ({
      scriptLoaded: false,
      currentLanguage: 'en',
      googleTranslateSelectObserver: null,
    });
  },
  methods: {
    loadScript() {
      window.googleTranslateElementInit = () => {
        window.translateInstance = new window.google.translate.TranslateElement(
          { pageLanguage: 'en', autoDisplay: false },
          'app',
        );
      };
      const script = document.createElement('script');
      script.setAttribute('type', 'text/javascript');
      script.setAttribute('src', translatorScriptURL);
      const documentBody = document.querySelector('body');
      documentBody.appendChild(script);
      // eslint-disable-next-line no-multi-assign
      script.onload = script.onreadystatechange = () => {
        if (
          !script.readyState
          || script.readyState === 'loaded'
          || script.readyState === 'complete'
        ) {
          // emit an event to let the app know the script is loaded
          if (process.env.NODE_ENV !== 'production') {
            // eslint-disable-next-line no-console
            console.log('script loaded');
          }
          const cookieLanguage = getCookie('googtrans');
          if (cookieLanguage.trim() === '') {
            localStorage.removeItem('_hyper_lang');
            localStorage.removeItem('app_language');
          }
          this.$emit('loaded');
        }
        // eslint-disable-next-line no-multi-assign
        script.onload = script.onreadystatechange = null;
      };
    },
    reboot() {
      this.translate(this.currentLanguage);
    },
    translate(code, callback) {
      try {
        // validate if code is in languages json
        // if current language is english and code is english then return
        if (this.currentLanguage === 'en' && code === 'en') {
          if (typeof callback === 'function') {
            callback();
          }
          return false;
        }
        const doesLanguageExist = Boolean(this.getLanguageInfoFromCode(code));
        if (!doesLanguageExist) {
          if (process.env.NODE_ENV !== 'production') {
            // eslint-disable-next-line no-console
            console.warn(`Language code ${code} does not exist in languages.json`);
          }
          return false;
        }
        // set current language
        this.currentLanguage = code;
        const select = document.querySelector('.goog-te-combo');
        const event = document.createEvent('HTMLEvents');
        event.initEvent('change', true, true);
        select.value = code;
        select.dispatchEvent(event);
        localStorage.setItem('_hyper_lang', code);
        setCookie('googtrans', `/en/${code}`, 365);
        if (typeof callback === 'function') {
          callback();
        }
        return true;
      } catch (error) {
        if (process.env.NODE_ENV !== 'production') {
          // eslint-disable-next-line no-console
          console.log('%c hyper-translate warning', 'background: tomato; color: white; padding: 2px; border-radius: 2px;', 'translate() failed');
        }
        return false;
      }
    },
    getLanguageInfoFromCode(code) {
      return languages.find((language) => language.code === code);
    },
  },
  mounted() {
    this.loadScript();
    // add mutation observer for the body element
    Vue.prototype.$hyper = {
      translate: this.translate,
      languages,
      getLanguageInfoFromCode: this.getLanguageInfoFromCode,
      reboot: this.reboot,
      getLanguageInfoFromCookie: () => {
        const cookieLanguage = getCookie('googtrans');
        if (cookieLanguage.trim() === '') {
          return null;
        }
        const cookieLanguageInfo = cookieLanguage.split('/');
        return this.getLanguageInfoFromCode(cookieLanguageInfo.pop());
      },
    };
    const observer = (target, optionName, callback) => {
      if (!target) return;
      const MutationObserver = window.MutationObserver
        || window.WebKitMutationObserver
        || window.MozMutationObserver;
      const optionsMap = {
        attribute: {
          attribute: true,
          attributeOldValue: true,
        },
        child: {
          childList: true,
          subtree: true,
        },
      };
      if (MutationObserver) {
        const Observer = new MutationObserver((records) => {
          records.map((record) => {
            if (callback) {
              callback(record);
            }
            return null;
          });
        });
        if (Observer.observe) {
          Observer.observe(target, optionsMap[optionName]);
        }
        // eslint-disable-next-line consistent-return
        return Observer;
      }
    };
    this.googleTranslateSelectObserver = observer(
      document.querySelector('.goog-te-combo'),
      'child',
      (record) => {
        if (record.addedNodes[0] && record.addedNodes[0].value) {
          if (this.currentLanguage === record.addedNodes[0].value) {
            this.translate(record.addedNodes[0].value);
          }
        }
      },
    );
  },
};
</script>

<style>
body {
  top: 0 !important;
}
.skiptranslate {
  display: none !important;
}
</style>

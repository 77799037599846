import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        primary: '#8066FF',
        secondary: '#02E648',
        accent: '#FED4CC',
        error: '#FF371B',
        info: '#8066FF',
        success: '#00C48C',
        warning: '#f2c94c',
        primaryBtn: '00b2d5',
        primaryGray1: '#555555',
        charcoal: '#262626',
        secondaryGray1: '#DEDEDE',
        grey1: '#929292',
        primaryBackground: '#f4f5ff',
      },
    },
  },
});

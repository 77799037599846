export default {
  sweepBlocks(state) {
    return state.sweepBlocks;
  },
  equipmentsList(state) {
    return state.equipments.reverse();
  },
  shotSettingsList(state) {
    return state.shotSettings.reverse();
  },
  getTimezones(state) {
    return state.timezones;
  },
};

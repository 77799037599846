import Vue from 'vue';
import Vuex from 'vuex';
import { Ability } from '@casl/ability';
import API from '@/helpers/api';
import vuexLocal from './plugins/persist';
import genesis from './plugins/genesis';
import user from './user';
import onboarding from './onboarding';
import profile from './profile';
import dashboard from './dashboard';
import project from './project';
import organizations from './organizations';
import contentKit from './contentKit';
import messaging from './messaging';
import admin from './admin';
import business from './business';
import brandProfile from './brandProfile';
import orders from './orders';
import sweep from './sweep';
import sweepBlocks from './sweepBlocks';
import abilityPlugin from './ability';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    notification: null,
    modal: null,
    uploadingItems: [],
    downloadItems: [],
    rules: [],
    sweepInvitationReasons: [],
    contentKitFilters: {
      search: '',
      selectedTypes: [],
      selectedDimensions: [],
      selectedPlatforms: [],
      favoriteItems: false,
      gridView: true,
      sortBy: 'recent',
      filterBy: 'active',
    },
  },
  getters: {
    notification(state) {
      return state.notification;
    },
    modal(state) {
      return state.modal;
    },
    uploadingItems(state) {
      return state.uploadingItems;
    },
    downloadItems(state) {
      return state.downloadItems;
    },
    ability() {
      return new Ability([], {
        subjectName(subject) {
          return !subject || typeof subject === 'string'
            ? subject
            : subject[Symbol('key')];
        },
      });
    },
    sweepInvitationReasons(state) {
      return state.sweepInvitationReasons;
    },
    contentKitFilters(state) {
      return state.contentKitFilters;
    },
  },
  mutations: {
    SET_NOTIFICATION(state, notification) {
      state.notification = notification;
    },
    SET_MODAL(state, modal) {
      state.modal = modal;
    },
    SET_UPLOAD_ITEMS(state, items) {
      state.uploadingItems.push(items);
    },
    REMOVE_UPLOADING_ITEM(state, index) {
      state.uploadingItems.splice(index, 1);
    },
    RESET_UPLOAD_ITEMS(state) {
      state.uploadingItems = [];
    },
    SET_DOWNLOAD_ITEMS(state, items) {
      state.downloadItems.push(items);
    },
    RESET_DOWNLOAD_ITEMS(state) {
      state.downloadItems = [];
    },
    SET_INVITATION_REASONS(state, reasons) {
      state.sweepInvitationReasons = reasons;
    },
    SET_CONTENT_KIT_FILTERS(state, filters) {
      state.contentKitFilters = filters;
    },
    RESET_CONTENT_KIT_FILTERS(state, options) {
      state.contentKitFilters = {
        ...{
          search: '',
          selectedTypes: [],
          selectedDimensions: [],
          selectedPlatforms: [],
          favoriteItems: false,
          gridView: true,
          sortBy: 'recent',
          filterBy: 'active',
        },
        ...options,
      };
    },
  },
  actions: {
    setNotification({ commit }, payload) {
      commit('SET_NOTIFICATION', payload);
    },
    setModal({ commit }, payload) {
      commit('SET_MODAL', payload);
    },
    async getSweepTeamReasons(context) {
      const result = await API(context).get('/sweep_invitation_reasons');
      if (result.success) {
        context.commit('SET_INVITATION_REASONS', result.reasons);
      }
    },
    setUploadingItems({ commit }, payload) {
      commit('SET_UPLOAD_ITEMS', payload);
    },
    setDownloadItems({ commit }, payload) {
      commit('SET_DOWNLOAD_ITEMS', payload);
    },
    removeUploadItemByIndex({ commit }, index) {
      commit('REMOVE_UPLOADING_ITEM', index);
    },
    resetUploadItems({ commit }) {
      commit('RESET_UPLOAD_ITEMS');
    },
    resetDownloadItems({ commit }) {
      commit('RESET_DOWNLOAD_ITEMS');
    },
    setFilters({ commit }, filters) {
      commit('SET_CONTENT_KIT_FILTERS', filters);
    },
    resetFilters({ commit }, options) {
      commit('RESET_CONTENT_KIT_FILTERS', options);
    },
  },
  modules: {
    user,
    onboarding,
    profile,
    dashboard,
    project,
    contentKit,
    messaging,
    admin,
    business,
    sweep,
    brandProfile,
    organizations,
    orders,
    sweepBlocks,
  },
  plugins: [vuexLocal.plugin, genesis, abilityPlugin],
});

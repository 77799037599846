export default {
  userToken(state) {
    return state.token;
  },
  userDetails(state) {
    return state.user;
  },
  creativeList(state) {
    return state.creativeList;
  },
  creativesReports(state) {
    return state.creativesReports;
  },
  events(state) {
    return state.events;
  },
};

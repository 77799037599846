import _ from 'lodash';

export default {
  business(state) {
    return state.business;
  },
  brandKits(state) {
    return state.brandKits;
  },

  businessDescription(state) {
    return state.businessDescription;
  },

  businessName(state) {
    return state.businessName;
  },

  brandStyleSelected(state) {
    return state.brandStyle;
  },

  brandMusicSelected(state) {
    return state.brandMusic;
  },

  brandColors(state) {
    return state.brandColors;
  },
  brandSources(state) {
    return state.brandSources;
  },
  brandMusic(state) {
    return state.brandMusic;
  },
  brandStyle(state) {
    return state.brandStyle;
  },
  brandFonts(state) {
    return state.brandFonts;
  },
  brandKitInspirations(state) {
    return state.brandKitInspirations;
  },

  brandKitLinks(state) {
    return state.brandKitLinks;
  },
  businessUsers(state) {
    return state.businessUsers;
  },
  workspaces(state) {
    return state.workspaces;
  },
  reasons(state) {
    return state.reasons;
  },
  goals(state) {
    return state.goals;
  },
  contentPlan(state) {
    return state.contentPlan;
  },
  creditsHistory(state) {
    // sort by createdAt
    return _.sortBy(state.creditsHistory, 'createdAt').reverse();
  },
  categories(state) {
    // sort by id in ascending order
    return _.sortBy(state.categories, 'id');
  },
};

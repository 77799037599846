import _ from 'lodash';

export const getInitialState = () => ({
  contentKits: [],
  projectSubscriptionBlend: {},
  currentContentKit: {},
  currentKitProjectDeliverables: {},
  contentKitFiles: [],
  dimensions: [],
  socialTags: [],
  contentPlans: [],
  loadingMore: false,
  hasMore: true,
});

export default {
  SET_USER_CONTENT_KITS(state, contentKits) {
    state.contentKits = contentKits;
  },

  SET_USER_SUBSCRIPTION_BLENDS(state, subscriptionBlend) {
    state.projectSubscriptionBlend = subscriptionBlend;
  },

  SET_CURRENT_CONTENT_KIT(state, contentKit) {
    state.currentContentKit = contentKit;
  },

  SET_CURRENT_KIT_PROJECTDELIVERABLES(state, projectDeliverables) {
    state.currentKitProjectDeliverables = projectDeliverables;
  },

  SET_DIMENSIONS(state, dimensions) {
    state.dimensions = dimensions;
  },

  SET_SOCIAL_TAGS(state, tags) {
    state.socialTags = tags;
  },

  GET_CONTENT_KIT(state, contentPlan) {
    state.contentPlans = contentPlan;
  },
  UPDATE_ASSET_TAGS(state, tags) {
    state.socialTags = _.unionBy(tags.socialTags, state.socialTags, 'name');
    state.dimensions = _.unionBy([tags.dimension], state.dimensions, 'name');
  },

  UPDATE_CONTENT_KIT(state, contentKit) {
    const index = state.contentKits.findIndex((item) => item.id === contentKit.id);
    if (index !== -1) state.contentKits.splice(index, 1, contentKit);
  },

  SET_CONTENT_KIT_FILES(state, contentKitFiles) {
    state.hasMore = contentKitFiles.length >= 20;
    state.contentKitFiles = contentKitFiles;
  },
  APPEND_CONTENT_KIT_FILES(state, contentKitFiles) {
    state.contentKitFiles = [...state.contentKitFiles, ...contentKitFiles];
    state.hasMore = contentKitFiles.length >= 20;
  },
  UPDATE_CONTENT_KIT_FILE(state, contentKitFile) {
    const index = state.contentKitFiles.findIndex((item) => item.id === contentKitFile.id);
    if (index !== -1) state.contentKitFiles.splice(index, 1, contentKitFile);
  },

  UPDATE_CONTENT_KIT_FILE_POSTER(state, { id, poster }) {
    const index = state.contentKitFiles.findIndex((item) => item.id === id);
    if (index !== -1) {
      const contentKitFile = { ...state.contentKitFiles[index], poster };
      state.contentKitFiles.splice(index, 1, contentKitFile);
    }
  },

  UPDATE_CONTENT_KIT_FILE_ATTRS(state, { id, attrs }) {
    const index = state.contentKitFiles.findIndex((item) => item.id === id);
    if (index !== -1) {
      const contentKitFile = { ...state.contentKitFiles[index], ...attrs };
      state.contentKitFiles.splice(index, 1, contentKitFile);
    }
  },

  PUSH_CONTENT_KIT_FILE(state, result) {
    state.contentKitFiles.push(result.contentKitFile);
  },

  RESET_CONTENT_KITS(state) {
    Object.assign(state, { contentKits: [] });
  },

  RESET_CURRENT_CONTENT_KIT(state) {
    Object.assign(state, { currentContentKit: {} });
  },

  RESET_CONTENT_KIT_FILES(state) {
    Object.assign(state, { contentKitFiles: [] });
  },

  RESET(state) {
    Object.assign(state, getInitialState());
  },
  SET_LOADING_MORE(state, loading) {
    state.loadingMore = loading;
  },
};

export const getInitialState = () => ({
  agencies: [],
  agency: {},
  agencyBusinesses: [],
  agencyUsers: [],
});

export default {
  SET_AGENCIES(state, agencies) {
    state.agencies = agencies;
  },
  SET_AGENCY(state, agency) {
    state.agency = agency;
  },
  SET_AGENCY_BUSINESS(state, agencyBusinesses) {
    state.agencyBusinesses = agencyBusinesses;
  },
  SET_AGENCY_USERS(state, agencyUsers) {
    state.agencyUsers = agencyUsers;
  },
};

export const getInitialState = () => ({
  sweepBlocks: [],
  equipments: [],
  shotSettings: [],
  timezones: [],
});

export default {
  SET_SWEEP_BLOCKS(state, result) {
    state.sweepBlocks = result;
  },
  ADD_SWEEP_BLOCKS(state, result) {
    state.sweepBlocks.push(result);
  },
  SET_TIMEZONE(state, result) {
    state.timezones = result;
  },
  UPDATE_SWEEP_BLOCKS(state, result) {
    const data = state.sweepBlocks;
    data.map((item, i) => {
      if (item.id === result.id) {
        data[i] = result;
      }
      return true;
    });
    state.sweepBlocks = [...data];
    return state.sweepBlocks;
  },
  REMOVE_SWEEP_BLOCK(state, id) {
    const data = state.sweepBlocks.filter((item) => item.id !== id);
    state.sweepBlocks = data;
  },
  SET_EQUIPMENTS(state, result) {
    state.equipments = result;
  },
  SAVE_EQUIPMENTS(state, result) {
    const data = state.equipments.filter((item) => item.id === result.id);
    if (data.length === 0) {
      state.equipments.push(result);
    }
  },
  SET_SHOT_SETTINGS(state, result) {
    state.shotSettings = [...result];
  },
  SAVE_SHOT_SETTINGS(state, result) {
    const data = state.shotSettings.filter((item) => item.id === result.id);
    if (data.length === 0) {
      state.shotSettings.push(result);
    }
  },
};

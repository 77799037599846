import Vue from 'vue';
import Router from 'vue-router';
import _ from 'lodash';
import { getUser, authenticate } from '@/helpers';
import { getNextOnboardingStepInfo } from './helpers/onboarding';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import(/* webpackChunkName: "login" */ './views/Login'),
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: () => import(/* webpackChunkName: "dashboard" */ './views/Dashboard'),
      meta: {
        requiresAuth: true,
        navPanel: true,
      },
    },
    {
      path: '/profile',
      name: 'Profile',
      component: () => import(/* webpackChunkName: "profile" */ './views/Profile'),
      meta: {
        requiresAuth: true,
        navPanel: true,
      },
    },
    {
      path: '/settings',
      name: 'Settings',
      component: () => import(/* webpackChunkName: "settings" */ './views/Settings'),
      meta: {
        requiresAuth: true,
        navPanel: true,
      },
    },
    {
      path: '/billing',
      name: 'Billing',
      component: () => import(/* webpackChunkName: "billing" */ './views/Billing'),
      meta: {
        requiresAuth: true,
        navPanel: true,
      },
    },
    {
      path: '/recover',
      name: 'ForgotPassword',
      component: () => import(/* webpackChunkName: "forgot-password" */ './views/ForgotPassword'),
    },
    {
      path: '/set_password/:confirmToken',
      name: 'SetPassword',
      component: () => import(/* webpackChunkName: "set-password" */ './views/SetPassword'),
    },
    {
      path: '/business_invitation/:confirmToken',
      name: 'BusinessInvitation',
      component: () => import(/* webpackChunkName: "business-invitation" */ '@/views/BusinessInvitation'),
    },
    {
      path: '/reset_password/:resetToken',
      name: 'ResetPassword',
      component: () => import(/* webpackChunkName: "set-password" */ './views/SetPassword'),
    },
    {
      path: '/invitation/:invitationToken',
      name: 'SweepInvitation',
      component: () => import(/* webpackChunkName: "sweep-invitation" */ './views/SweepInvitation'),
    },
    {
      path: '/email_confirmation/:confirmToken',
      name: 'EmailSuccess',
      component: () => import(/* webpackChunkName: "email-success" */ './views/EmailSuccess'),
    },
    {
      path: '/success',
      name: 'Success',
      component: () => import(/* webpackChunkName: "success" */ './views/Success'),
    },
    {
      path: '/register',
      name: 'Register',
      component: () => import(/* webpackChunkName: "register" */ './views/Register'),
    },
    {
      path: '/auth/signin',
      name: 'SigningIn',
      component: () => import('./views/SigningIn'),
    },
    {
      path: '/onboarding/info',
      name: 'CustomerInfo',
      component: () => import(/* webpackChunkName: "register" */ './components/onBoard/ContactInfo'),
    },
    {
      path: '/onboarding/client/get-started',
      name: 'ClientGetStarted',
      component: () => import(/* webpackChunkName: "onboard-creative-getstarted" */ './components/onBoard/client/GetStarted'),
    },
    {
      path: '/onboarding/client/about-business',
      name: 'ClientBusinessForm',
      component: () => import(/* webpackChunkName: "onboard-creative-getstarted" */ './components/onBoard/client/Business'),
    },
    {
      path: '/onboarding/client/customer',
      name: 'ClientCustomerForm',
      component: () => import(/* webpackChunkName: "onboard-creative-getstarted" */ './components/onBoard/client/Customer'),
    },
    {
      path: '/onboarding/client/finish',
      name: 'ClientFinishForm',
      component: () => import(/* webpackChunkName: "onboard-creative-getstarted" */ './components/onBoard/client/Finish'),
    },
    {
      path: '/onboarding/client/content-preferences',
      name: 'ClientContent',
      component: () => import(/* webpackChunkName: "onboard-creative-getstarted" */ './components/onBoard/client/Content'),
    },
    {
      path: '/onboarding/creative/get-started',
      name: 'CreativeGetStarted',
      component: () => import(/* webpackChunkName: "onboard-creative-getstarted" */ './components/onBoard/creative/GetStarted'),
    },
    {
      path: '/onboarding/creative/pending',
      name: 'Pending',
      component: () => import(/* webpackChunkName: "onboard-creative-getstarted" */ './components/onBoard/creative/PendingDashboard'),
    },
    {
      path: '/onboarding/creative/rejected',
      name: 'Rejected',
      component: () => import(/* webpackChunkName: "onboard-creative-getstarted" */ './components/onBoard/creative/RejectedDashboard'),
    },
    {
      path: '/onboarding/creative/next-steps',
      name: 'CreativeNextSteps',
      component: () => import(/* webpackChunkName: "onboard-creative-getstarted" */ './components/onBoard/creative/NextSteps'),
    },
    {
      path: '/onboarding/creative/about-you',
      name: 'CreativeAboutYou',
      component: () => import(/* webpackChunkName: "onboard-creative-about-you" */ './components/onBoard/creative/AboutSelf'),
    },
    {
      path: '/onboarding/creative/language-preference',
      name: 'CreativeAboutYou',
      component: () => import(/* webpackChunkName: "creative-onboard-language-preference" */ './components/onBoard/creative/LanguagePreference'),
    },
    {
      path: '/onboarding/creative/sweep-details',
      name: 'CreativeAboutYou',
      component: () => import(/* webpackChunkName: "onboard-creative-about-you" */ './components/onBoard/creative/SweepDetails'),
    },
    {
      path: '/onboarding/creative/talent-details',
      name: 'CreativeAboutYou',
      component: () => import(/* webpackChunkName: "onboard-creative-talent-details" */ './components/onBoard/creative/TalentDetails'),
    },
    {
      path: '/onboarding/creative/field-producer-details',
      name: 'CreativeAboutYou',
      component: () => import(/* webpackChunkName: "onboard-creative-field-producer-details" */ './components/onBoard/creative/FieldProducerDetails'),
    },
    {
      path: '/onboarding/creative/sweep-preferences',
      name: 'CreativeSweepPreferences',
      component: () => import(/* webpackChunkName: "onboard-creative-about-you" */ './components/onBoard/creative/SweepPreferences'),
    },
    {
      path: '/onboarding/role',
      name: 'CustomerType',
      component: () => import(/* webpackChunkName: "register" */ './components/onBoard/Role'),
    },
    {
      path: '/onboarding/business',
      name: 'Business Vertical',
      component: () => import(/* webpackChunkName: "register" */ './components/onBoard/Content'),
    },
    {
      path: '/onboarding/setup',
      name: 'Onboarding Loader',
      component: () => import(/* webpackChunkName: "register" */ './components/onBoard/Loader'),
    },
    {
      path: '/onboarding/business/:businessVertical',
      name: 'Business Form',
      component: () => import(/* webpackChunkName: "register" */ './components/onBoard/BusinessForm'),
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import(/* webpackChunkName: "login" */ './views/Login'),
    },
    {
      path: '/creative/public/:id',
      name: 'Public Profile',
      component: () => import(/* webpackChunkName: "creative-public-profile" */ './views/CreativePublicProfile'),
    },
    {
      path: '/about',
      name: 'About',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/About'),
    }, {
      path: '/notifications',
      name: 'Notifications',
      component: () => import(/* webpackChunkName: "all-notifications" */ './components/common/AllNotifications'),
      meta: {
        navPanel: true,
      },
    },
    {
      path: '/projects',
      name: 'Businesses',
      component: () => import(/* webpackChunkName: "projects" */ './views/Projects'),
      meta: {
        requiresAuth: true,
        navPanel: true,
        accessibleRoles: ['admin', 'executive', 'creative', 'editor', 'producer', 'community_manager'],
      },
    },
    {
      path: '/project_overview/:projectId',
      name: 'Project',
      component: () => import(/* webpackChunkName: "project-overview" */ './views/BusinessOverview'),
      meta: {
        requiresAuth: true,
        navPanel: true,
        accessibleRoles: ['admin', 'executive', 'creative', 'editor', 'producer', 'community_manager'],
        items: [
          {
            text: 'Businesses',
            disabled: false,
            to: '/projects',
          },
        ],
      },
    },
    {
      path: '/orders',
      name: 'Orders',
      component: () => import(/* webpackChunkName: "single-order" */ './views/Orders'),
      meta: {
        requiresAuth: true,
        navPanel: true,
        accessibleRoles: ['client', 'member', 'agency_owner', 'agency_member'],
      },
    },
    // {
    //   path: '/orders/:businessId/:orderId',
    //   name: 'Order',
    //   component: () =>
    //    import(/* webpackChunkName: "single-order" */
    //     './components/orders/CreateOrder'),
    //   meta: {
    //     requiresAuth: true,
    //     navPanel: true,
    //     accessibleRoles: ['client', 'member', 'agency_owner', 'agency_member'],
    //   },
    // },
    {
      path: '/orders/:orderId',
      name: 'Order',
      component: () => import(/* webpackChunkName: "single-order" */ './components/orders/SingleOrderInfo'),
      meta: {
        requiresAuth: true,
        navPanel: true,
        accessibleRoles: ['admin', 'executive', 'producer', 'client', 'member', 'agency_owner', 'agency_member'],
      },
    },
    {
      path: '/content_plan_history/:projectId',
      name: 'Content Plan History',
      component: () => import(/* webpackChunkName: "content-plan-history" */ './views/ContentPlanHistory'),
      meta: {
        requiresAuth: true,
        navPanel: true,
        accessibleRoles: ['client', 'member'],
      },
    },
    {
      path: '/project_overview/:projectId/content_plan_history',
      name: 'Content History',
      component: () => import(/* webpackChunkName: "content-plan-history" */ './views/ContentPlanHistory'),
      meta: {
        requiresAuth: true,
        navPanel: true,
        accessibleRoles: ['executive', 'creative', 'producer', 'editor', 'admin', 'community_manager'],
        showBreadcrumbs: true,
        items: [
          {
            text: 'Businesses',
            disabled: false,
            to: '/projects',
          },
        ],
      },
    },
    {
      path: '/project_overview/:projectId/content_plan/:contentKitId',
      name: 'My Content Plan',
      component: () => import(/* webpackChunkName: "content-plan" */ './views/ContentPlan'),
      meta: {
        requiresAuth: true,
        navPanel: true,
        accessibleRoles: ['executive', 'creative', 'producer', 'editor', 'admin', 'community_manager'],
        showBreadcrumbs: true,
        items: [
          {
            text: 'Businesses',
            disabled: false,
            to: '/projects',
          },
        ],
      },
    },
    {
      path: '/project_overview/:projectId/content_plan',
      name: 'New Content Plan',
      component: () => import(/* webpackChunkName: "content-plan" */ './views/ContentPlan'),
      meta: {
        requiresAuth: true,
        navPanel: true,
        accessibleRoles: ['executive', 'creative', 'producer', 'editor', 'community_manager'],
        showBreadcrumbs: true,
        items: [
          {
            text: 'Businesses',
            disabled: false,
            to: '/projects',
          },
        ],
      },
    },
    {
      path: '/content_plan_history/:projectId/content_plan/:contentKitId',
      name: 'Content Plan',
      component: () => import(/* webpackChunkName: "content-plan" */ './views/ContentPlan'),
      meta: {
        requiresAuth: true,
        navPanel: true,
        accessibleRoles: ['client', 'member'],
      },
    },
    {
      path: '/projects/:projectId/content_kits',
      name: 'Content Kits',
      component: () => import(/* webpackChunkName: "content-kits" */ './views/ContentKits'),
      meta: {
        requiresAuth: true,
        navPanel: true,
        accessibleRoles: ['admin', 'executive', 'creative', 'editor', 'producer', 'community_manager'],
        showBreadcrumbs: true,
        items: [
          {
            text: 'Businesses',
            disabled: false,
            to: '/projects',
          },
        ],
      },
    },
    {
      path: '/projects/:businessId/brand_profile',
      name: 'Brand Kit',
      component: () => import(/* webpackChunkName: "brand-profile" */ './views/BusinessBrandProfile'),
      meta: {
        requiresAuth: true,
        navPanel: true,
        accessibleRoles: ['admin', 'executive', 'creative', 'editor', 'producer', 'community_manager'],
        showBreadcrumbs: true,
        items: [
          {
            text: 'Businesses',
            disabled: false,
            to: '/projects',
          },
        ],
      },
    },
    {
      path: '/library/:projectId/content_kits',
      name: 'Asset Library',
      component: () => import(/* webpackChunkName: "library" */ './components/AssetLibrary/AssetLibrary'),
      meta: {
        requiresAuth: true,
        navPanel: true,
        accessibleRoles: ['client', 'member'],
        showBreadcrumbs: true,
        items: [
          {
            text: 'Dashboard',
            disabled: false,
            to: '/dashboard',
          },
        ],
      },
    },
    {
      path: '/library/:projectId/all_assets',
      name: 'Assets',
      component: () => import(/* webpackChunkName: "assets" */ './views/Assets'),
      meta: {
        requiresAuth: true,
        navPanel: true,
        accessibleRoles: ['client', 'member', 'creative', 'producer', 'executive', 'editor', 'community_manager'],
        showBreadcrumbs: true,
        items: [
          {
            text: 'Dashboard',
            disabled: false,
            to: '/dashboard',
          },
        ],
      },
    },
    {
      path: '/brand_profile',
      name: 'Customer Brand Kit',
      component: () => import(/* webpackChunkName: "brand-profile" */ './views/BusinessBrandProfile'),
      meta: {
        requiresAuth: true,
        navPanel: true,
        accessibleRoles: ['client', 'member'],
      },
    },
    {
      path: '/user_management',
      name: 'User Management',
      component: () => import(/* webpackChunkName: "user-management" */ './views/UserManagement'),
      meta: {
        requiresAuth: true,
        navPanel: true,
        accessibleRoles: ['admin', 'executive', 'sales', 'producer', 'community_manager'],
      },
    },
    {
      path: '/organizations',
      name: 'Organizations',
      component: () => import(/* webpackChunkName: "organizations" */ './views/Organizations'),
      meta: {
        requiresAuth: true,
        navPanel: true,
        accessibleRoles: ['admin', 'executive'],
      },
    },
    {
      path: '/organization/:organizationId/library',
      name: 'Asset Library',
      component: () => import(/* webpackChunkName: "OrganizationLibrary" */ './components/AssetLibrary/AssetLibrary'),
      props: { page: 'org_library' },
      meta: {
        requiresAuth: true,
        navPanel: true,
        accessibleRoles: ['admin', 'executive', 'agency_owner', 'agency_member'],
      },
    },
    {
      path: '/organization/:organizationId/brandKit',
      name: 'Brand Kit',
      component: () => import(/* webpackChunkName: "organizations-brand-kit" */ './views/OrganizationBrandKit'),
      meta: {
        requiresAuth: true,
        navPanel: true,
        accessibleRoles: ['admin', 'executive', 'agency_owner', 'agency_member'],
      },
    },
    {
      path: '/organizations/:organizationId',
      name: 'Organization',
      component: () => import(/* webpackChunkName: "organization" */ './views/Organization'),
      meta: {
        requiresAuth: true,
        navPanel: true,
        accessibleRoles: ['admin', 'executive'],
      },
    },
    {
      path: '/organizations/:organizationId/business/:businessId',
      name: 'Business',
      component: () => import(/* webpackChunkName: "organization" */ './views/Business'),
      meta: {
        requiresAuth: true,
        navPanel: true,
        accessibleRoles: ['admin', 'executive'],
      },
    },
    {
      path: '/subscriptions',
      name: 'Subscriptions',
      component: () => import(/* webpackChunkName: "subscriptions" */ './views/Subscriptions'),
      meta: {
        requiresAuth: true,
        navPanel: true,
        accessibleRoles: ['admin'],
      },
    },
    {
      path: '/pay_center',
      name: 'Pay Center',
      component: () => import(/* webpackChunkName: "pay-center" */ './views/PayCenter'),
      meta: {
        requiresAuth: true,
        navPanel: true,
        accessibleRoles: ['admin'],
      },
    },
    {
      path: '/coupons',
      name: 'Promo Codes',
      component: () => import(/* webpackChunkName: "production-menu" */ './views/CouponsMenu'),
      meta: {
        requiresAuth: true,
        navPanel: true,
        accessibleRoles: ['admin'],
      },
    },
    {
      path: '/sweep_blocks',
      name: 'Sweep Blocks',
      component: () => import(/* webpackChunkName: "pay-center" */ './views/SweepBlocks'),
      meta: {
        requiresAuth: true,
        navPanel: true,
        accessibleRoles: ['admin'],
      },
    },
    {
      path: '/production_menu',
      name: 'Production Menu',
      component: () => import(/* webpackChunkName: "production-menu" */ './views/ProductionMenu'),
      meta: {
        requiresAuth: true,
        navPanel: true,
        accessibleRoles: ['admin'],
      },
    },
    {
      path: '/app_preferences',
      name: 'APP Preferences',
      component: () => import(/* webpackChunkName: "production-menu" */ './views/AppPreferences'),
      meta: {
        requiresAuth: true,
        navPanel: true,
        accessibleRoles: ['admin'],
      },
    },
    {
      path: '/project_management',
      name: 'Project Management',
      component: () => import(/* webpackChunkName: "project-management" */ './views/ProjectManagement'),
      meta: {
        requiresAuth: true,
        navPanel: true,
        accessibleRoles: ['admin', 'executive', 'sales'],
      },
    },
    {
      path: '/project_overview/:projectId/project_team',
      name: 'Project Team',
      component: () => import(/* webpackChunkName: "project-team" */ './views/ProjectTeam'),
      meta: {
        requiresAuth: true,
        navPanel: true,
        accessibleRoles: ['admin', 'executive', 'producer'],
        showBreadcrumbs: true,
        items: [
          {
            text: 'Businesses',
            disabled: false,
            to: '/projects',
          },
        ],
      },
    },
    {
      path: '/project_overview/:projectId/sweeps',
      name: 'Sweeps',
      component: () => import(/* webpackChunkName: "sweep-management" */ './views/SweepManagement'),
      meta: {
        requiresAuth: true,
        navPanel: true,
        accessibleRoles: ['admin', 'executive', 'editor', 'producer', 'creative', 'community_manager'],
        showBreadcrumbs: true,
        items: [
          {
            text: 'Businesses',
            disabled: false,
            to: '/projects',
          },
        ],
      },
    },
    {
      path: '/project_overview/:projectId/sweeps/:sweepId/edit',
      name: 'Sweep Edit',
      component: () => import(/* webpackChunkName: "sweep-edit" */ './components/projects/sweep/SweepEdit'),
      meta: {
        requiresAuth: true,
        navPanel: true,
        accessibleRoles: ['executive', 'producer'],
        showBreadcrumbs: true,
        items: [
          {
            text: 'Businesses',
            disabled: false,
            to: '/projects',
          },
        ],
      },
    },
    {
      path: '/customer_management',
      name: 'Customer Management',
      component: () => import(/* webpackChunkName: "customer-management" */ './views/CustomerManagement'),
      meta: {
        requiresAuth: true,
        navPanel: true,
        accessibleRoles: ['client'],
        showBreadcrumbs: false,
      },
    },
    {
      path: '/projects/:projectId/content_kits/:contentKitId',
      name: 'Content Kit',
      component: () => import(/* webpackChunkName: "content-kit-assets" */ './views/ContentKitAssets'),
      meta: {
        requiresAuth: true,
        navPanel: true,
        accessibleRoles: ['admin', 'executive', 'creative', 'editor', 'producer', 'community_manager'],
        showBreadcrumbs: true,
        items: [
          {
            text: 'Businesses',
            disabled: false,
            to: '/projects',
          },
        ],
      },
    },
    {
      path: '/library/:projectId/content_kits/:contentKitId',
      name: 'Client Content Kit',
      component: () => import(/* webpackChunkName: "content-kit-assets" */ './views/ContentKitAssets'),
      meta: {
        requiresAuth: true,
        navPanel: true,
        accessibleRoles: ['client', 'member'],
        showBreadcrumbs: true,
        items: [
          {
            text: 'Dashboard',
            disabled: false,
            to: '/Dashboard',
          },
        ],
      },
    },
    {
      path: '/sweeps',
      name: 'Sweeps',
      component: () => import(/* webpackChunkName: "sweep-management" */ './views/SweepManagement'),
      meta: {
        requiresAuth: true,
        navPanel: true,
        accessibleRoles: ['creative', 'executive', 'producer', 'client', 'member', 'community_manager'],
        showBreadcrumbs: false,
      },
    },
    {
      path: '/sweeps/:sweepId',
      name: 'Sweep Details',
      component: () => import(/* webpackChunkName: "sweep-details" */ './views/SweepDetails'),
      meta: {
        requiresAuth: true,
        navPanel: true,
        accessibleRoles: ['client', 'member', 'creative', 'executive', 'producer', 'editor', 'community_manager'],
        showBreadcrumbs: false,
      },
    },
    {
      path: '/order_create/:businessId/:orderId',
      name: 'Order Details',
      component: () => import(/* webpackChunkName: "sweep-details" */ './components/orders/CreateOrder'),
      meta: {
        requiresAuth: true,
        navPanel: true,
        accessibleRoles:
          ['client', 'member', 'creative', 'executive', 'producer', 'editor', 'community_manager'],
        showBreadcrumbs: false,
      },
    },
    {
      path: '/sweep_details/:businessId/:sweepId',
      name: 'Business Sweep Details',
      component: () => import(/* webpackChunkName: "business-sweep-details" */ './components/sweeps/Details'),
      meta: {
        requiresAuth: true,
        navPanel: true,
        accessibleRoles: ['executive', 'producer', 'admin', 'client', 'agency_owner', 'agency_member', 'member'],
        showBreadcrumbs: false,
      },
    },
    {
      path: '/sweep_details/:sweepId',
      name: 'Business Sweep Details2',
      component: () => import(/* webpackChunkName: "business-sweep-details" */ './components/sweeps/Details'),
      meta: {
        requiresAuth: true,
        navPanel: true,
        accessibleRoles: ['creative'],
        showBreadcrumbs: false,
      },
    },
    {
      path: '/order_assets',
      name: 'Order Assets',
      component: () => import(/* webpackChunkName: "business-sweep-details" */ './components/dashboard/screens/client/OrderAssets'),
      meta: {
        requiresAuth: true,
        accessibleRoles: ['client', 'agency_owner', 'agency_member', 'member'],
        showBreadcrumbs: false,
      },
    },
    // {
    //   path: '/messages',
    //   name: 'Messaging',
    //   component: Messages,
    //   meta: {
    //     requiresAuth: true,
    //     navPanel: true,
    //     accessibleRoles: ['executive', 'creative', 'client'],
    //   },
    // },
  ],
});

const canAccess = (to, user) => to.matched.some((record) => !record.meta.accessibleRoles
  || record.meta.accessibleRoles.includes(user.role.name));


const handleUserOnboarding = (user, next) => {
  let skills = [];
  if (user.role.name === 'creative') {
    skills = _.get(user, 'profile.skillsets', []).map((o) => o.name);
  }
  if (!user.onboardingState) {
    return next('/dashboard');
  }
  const nextStep = getNextOnboardingStepInfo({
    onboardingState: Number(user.onboardingState) + 1,
    onboardingStepCompleted: user.onboardingStepCompleted,
    role: user.role.name,
    skills,
  });
  return next(nextStep.url);
};
// eslint-disable-next-line
router.beforeEach(async (to, from, next) => {
  const user = getUser();
  const creativRoutes = ['/onboarding/creative/about-you', '/onboarding/creative/field-producer-details', '/onboarding/creative/sweep-preferences', '/onboarding/creative/language-preference', '/onboarding/creative/sweep-details', '/onboarding/creative/talent-details'];
  const creativactiveRoutes = ['/onboarding/creative/pending', '/onboarding/creative/rejected'];
  // eslint-disable-next-line
  // const clientActive = ['/onboarding/client/content-preferences', '/onboarding/client/business', '/onboarding/client/customer'];
  // else if (user !== null && clientActive.includes(to.path)) {
  //   console.log('step2');
  //   const userInfo = user.userDetails;
  //   const userRole = userInfo.role.name;
  //   if (['client'].includes(userRole) && userInfo.onboardingState <= '5') {
  //     const storeContext = router.app.$store;
  //     const responseAsUser = await storeContext.dispatch('profile/getProfile');
  //     return handleUserOnboarding(responseAsUser, next);
  //   }
  //   return next();
  // }
  if (user === null && to.matched.some((record) => record.meta.requiresAuth)) {
    authenticate(to.path, router);
  } else if (user !== null && creativactiveRoutes.includes(to.path)) {
    const userInfo = user.userDetails;
    const userRole = userInfo.role.name;
    if (['creative'].includes(userRole) && userInfo.onboardingState <= '10') {
      return next();
    }
    const storeContext = router.app.$store;
    const responseAsUser = await storeContext.dispatch('profile/getProfile');
    return handleUserOnboarding(responseAsUser, next);
  } else if (user !== null && creativRoutes.includes(to.path)) {
    const userInfo = user.userDetails;
    const userRole = userInfo.role.name;
    const { onboardingState } = userInfo;
    if (['creative'].includes(userRole) && Number(onboardingState) >= 2 && Number(onboardingState) < 9) {
      // TODO - check if onboarding step is completed
      // find next route to navigate to
      return next();
    }
    const storeContext = router.app.$store;
    const responseAsUser = await storeContext.dispatch('profile/getProfile');
    return handleUserOnboarding(responseAsUser, next);
  } else if (user !== null && (to.path === '/dashboard')) {
    const userInfo = user.userDetails;
    const userRole = userInfo.role?.name;
    const { onboardingState } = userInfo;
    // eslint-disable-next-line eqeqeq
    if (['creative'].includes(userRole) && onboardingState == 10 && userInfo.status === '0') {
      return next('/onboarding/creative/pending');
    }
    // eslint-disable-next-line eqeqeq
    if (['creative'].includes(userRole) && onboardingState == 10 && userInfo.status === '4') {
      return next('/onboarding/creative/rejected');
    }
    if (['creative'].includes(userRole) && onboardingState && onboardingState < 10) {
      const storeContext = router.app.$store;
      const responseAsUser = await storeContext.dispatch('profile/getProfile');
      return handleUserOnboarding(responseAsUser, next);
    }
    if (['client'].includes(userRole) && onboardingState && onboardingState < 7) {
      // TODO - check if onboarding step is completed
      // find next route to navigate to
      const storeContext = router.app.$store;
      const responseAsUser = await storeContext.dispatch('profile/getProfile');
      return handleUserOnboarding(responseAsUser, next);
    }
    return next();
  } else if (user !== null && (to.path === '/' || to.path === '/register' || to.path === '/login' || to.path === '/onboarding/role' || !canAccess(to, user.userDetails))) {
    const userInfo = user.userDetails;
    const userRole = userInfo.role.name;
    if (['client', 'creative'].includes(userRole)) {
      // TODO - check if onboarding step is completed
      // find next route to navigate to
      const storeContext = router.app.$store;
      const responseAsUser = await storeContext.dispatch('profile/getProfile');
      return handleUserOnboarding(responseAsUser, next);
    } if (user !== null && ['/', '/register', '/login'].includes(to.path)) {
      return next('/dashboard');
    }
    return next();
  }
  return next();
});

export default router;

import _ from 'lodash';
import momentTimezone from 'moment-timezone';
import API from '@/helpers/api';

export default {
  async getUserProjects(context, status) {
    const projectType = status || 1;
    const result = await API(context).get(`/projects/?status=${projectType}`);
    if (result.success) {
      context.commit('SET_USER_PROJECTS', result.projects);
    }
    return result;
  },
  async getUserBusinesses(context, status) {
    const businessType = status || 1;
    const result = await API(context).get(`/projects/businesses/list?status=${businessType}`);
    if (result.success) {
      context.commit('SET_USER_BUSINESSES', result.businesses);
    }
    return result;
  },

  async getMyBusinesses(context) {
    const result = await API(context).get('projects/list/businesses');
    return result;
  },
  async getProjects(context) {
    const result = await API(context).get('/admin/projects/project_list');
    if (result.success) {
      context.commit('SET_PROJECTS', result.projects);
    }
    return result;
  },

  async assignProject(context, { id, status }) {
    const result = await API(context).post(`/projects/${id}/assign_to_my_projects`);
    if (result.success) {
      context.dispatch('getUserProjects', status);
    }
    return result;
  },

  async deleteProject(context, id) {
    const result = await API(context).delete(`/admin/projects/${id}`);
    if (result.success) {
      context.dispatch('getProjects');
    }
    return result;
  },

  async getProject(context, id) {
    if (id) {
      const result = await API(context).get(`/projects/${id}?timeZone=${momentTimezone.tz.guess()}`);
      if (result.success) {
        context.commit('SET_CURRENT_PROJECT', result.project);
      }
      return result;
    }
    return false;
  },
  async getProjectTeam(context, id) {
    const result = await API(context).get(`/projects/${id}/view_team`);
    if (result.success) {
      context.commit('SET_CURRENT_PROJECT_TEAM', result);
    }
    return result;
  },
  async addProject(context, payload) {
    let url;
    let action;
    if (payload.isAdmin) {
      url = '/admin/projects';
      action = 'getProjects';
    } else {
      url = '/projects';
      action = 'getUserProjects';
    }
    const result = await API(context).post(url, payload.project);
    if (result.success) {
      context.dispatch(action);
    }
    return result;
  },
  // eslint-disable-next-line
  async updateProject(context, { id, project, isAdmin, status }) {
    let url;
    let action;
    if (isAdmin) {
      url = '/admin/projects';
      action = 'getProjects';
    } else {
      url = '/projects';
      action = 'getUserProjects';
    }
    const result = await API(context).put(`${url}/${id}`, project);
    if (result.success) {
      context.dispatch(action, status);
    }
    return result;
  },
  async updateBusinessStatus(context, { business, status }) {
    const result = await API(context).put('/projects/businesses/status', business);
    if (result.success) {
      context.dispatch('getUserBusinesses', status);
    }
    return result;
  },
  async addBusiness(context, { business, status }) {
    const result = await API(context).post('/projects/businesses/add', business);
    if (result.success) {
      context.dispatch('getUserBusinesses', status);
    }
    return result;
  },
  async updateBusiness(context, { business }) {
    const result = await API(context).put('/projects/businesses/update', business);
    if (result.success) {
      context.dispatch('getUserBusinesses', business.status);
    }
    return result;
  },
  getSubscriptionBlends(context) {
    return API(context).get('/subscriptionBlends/list');
  },
  async addProjectMembers(context, obj) {
    const result = await API(context).post('/projects/assign_creatives_to_project', obj);
    if (result.success) {
      context.dispatch('getProjectTeam', obj.projectId);
    }
    return result;
  },
  async removeCreativeFromTeam(context, { creativeId, projectId }) {
    const result = await API(context).delete(`/projects/remove_creative_from_project/${projectId}/${creativeId}`);
    if (result.success) {
      context.dispatch('getProjectTeam', projectId);
    }
    return result;
  },
  async updateCreativeInvitationStatus(context, obj) {
    const result = await API(context).put('/projects/update_creative_invitation_status', obj);
    if (result.success) {
      context.dispatch('dashboard/getNotifications', null, { root: true });
    }
    return result;
  },

  async getBusiness(context, businessId) {
    const result = await API(context).get(`/business/${businessId}`);
    if (result.success) {
      context.commit('SET_BUSINESS', result.business);
      context.commit('SET_BRAND_KITS', _.get(result.business, 'brandKits', []));
      context.commit('SET_BRAND_COLORS', _.get(result.business, 'brandColors', []));
      context.commit('SET_BRAND_MUSIC', _.get(result.business, 'brandMusic[0].BrandMusic', {}));
      context.commit('SET_BRAND_STYLE', _.get(result.business, 'brandStyles[0].BrandStyle', {}));
      context.commit('SET_BRAND_FONTS', _.get(result.business, 'brandFont', []));
      context.commit('SET_BRAND_KIT_INSPIRATION', _.get(result.business, 'brandKitInspirations', []));
      context.commit('SET_BRAND_KIT_LINKS', _.get(result.business, 'brandKitLinks', []));
    }
  },

  async uploadBusinessBanner(context, { file, businessId }) {
    const formData = new FormData();
    formData.set('businessBanner', file);
    const result = await API(context).post(`/business/business_banner_upload/${businessId}`, formData);
    if (result.success) {
      context.dispatch('getBusiness', result.business.id);
    }
    return result;
  },

  async updateBusienss(context, payload) {
    const result = await API(context).put(`/business/${payload.id}`, payload);
    if (result.success) {
      context.commit('SET_BUSINESS', result.business);
    }
    return result;
  },

  async getSweeps(context, projectId) {
    let api = '/sweep/list';
    if (projectId) {
      api += `?projectId=${projectId}`;
    }
    const result = await API(context).get(api);
    if (result.success) {
      context.commit('SET_PROJECT_SWEEPS', result.sweepList);
    }
    return result;
  },
};

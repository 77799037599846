<template>
    <v-dialog
        v-model="popup"
        v-bind="$attrs"
        v-on="$listeners"
    >
        <slot />
    </v-dialog>
</template>

<script>

export default {
  name: 'Modal',
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return ({
      popup: this.modal,
    });
  },
  mounted() {
    this.$emit('vDialogMount', true);
  },
  beforeDestroy() {
    this.$emit('vDialogUnmount');
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 960px) {
  ::v-deep .v-dialog:not(.full-screen) {
    margin: auto 135px;
  }
}
// ::v-deep ::-webkit-scrollbar {
//   display: none;
// }
</style>

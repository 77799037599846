export const getInitialState = () => ({
  userProjects: [],
  projects: [],
  businesses: [],
  currentProject: {},
  currentProjectTeam: {
    producersTeam: [],
    creativeTeam: [],
    executiveTeam: [],
  },
  sweeps: [],
});

export default {
  SET_USER_PROJECTS(state, projects) {
    state.userProjects = projects;
  },
  SET_USER_BUSINESSES(state, businesses) {
    state.businesses = businesses;
  },

  SET_PROJECTS(state, projects) {
    state.projects = projects;
  },

  SET_CURRENT_PROJECT(state, project) {
    state.currentProject = project;
  },

  SET_CURRENT_PROJECT_TEAM(state, projectTeam) {
    state.currentProjectTeam = { ...state.currentProjectTeam, ...projectTeam };
  },

  UPDATE_USER_PROJECT(state, project) {
    const index = state.userProjects.findIndex((item) => item.id === project.id);
    if (index !== -1) state.userProjects.splice(index, 1, project);
    state.currentProject = project;
  },

  UPDATE_PROJECT(state, project) {
    const index = state.projects.findIndex((item) => item.id === project.id);
    if (index !== -1) state.projects.splice(index, 1, project);
    state.currentProject = project;
  },

  SET_PROJECT_SWEEPS(state, sweeps) {
    state.sweeps = sweeps;
  },

  RESET(state) {
    Object.assign(state, getInitialState());
  },
};

export default {
  project(state) {
    return state.client.project;
  },
  recentContentKits(state) {
    return state.client.recentContentKits;
  },
  producersTeam(state) {
    return state.client.projectTeam.producersTeam;
  },
  creativeTeam(state) {
    return state.client.projectTeam.creativeTeam;
  },
  executiveTeam(state) {
    return state.client.projectTeam.executiveTeam;
  },
  projectTeam(state) {
    return state.client.projectTeam;
  },
  notifications(state) {
    return state.notifications;
  },
};

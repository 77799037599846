<!-- eslint-disable max-len -->
<template>
  <svg :width="width" :height="height" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_5374_49045)">
      <path
        d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87897 15.1541 3.84547 13.6543 2.34568C12.1545 0.845885 10.121 0.00229405 8 0V0ZM8 14.6667C6.68146 14.6667 5.39253 14.2757 4.2962 13.5431C3.19987 12.8106 2.34539 11.7694 1.84081 10.5512C1.33622 9.33305 1.2042 7.9926 1.46143 6.6994C1.71867 5.40619 2.35361 4.2183 3.28596 3.28595C4.21831 2.3536 5.40619 1.71867 6.6994 1.46143C7.99261 1.2042 9.33305 1.33622 10.5512 1.8408C11.7694 2.34539 12.8106 3.19987 13.5431 4.2962C14.2757 5.39253 14.6667 6.68146 14.6667 8C14.6647 9.76751 13.9617 11.4621 12.7119 12.7119C11.4621 13.9617 9.76752 14.6647 8 14.6667Z"
        :fill="color"
      />
      <path
        d="M7.99935 6.6665H7.33268C7.15587 6.6665 6.9863 6.73674 6.86128 6.86177C6.73625 6.98679 6.66602 7.15636 6.66602 7.33317C6.66602 7.50998 6.73625 7.67955 6.86128 7.80458C6.9863 7.9296 7.15587 7.99984 7.33268 7.99984H7.99935V11.9998C7.99935 12.1767 8.06959 12.3462 8.19461 12.4712C8.31964 12.5963 8.48921 12.6665 8.66602 12.6665C8.84283 12.6665 9.0124 12.5963 9.13742 12.4712C9.26245 12.3462 9.33268 12.1767 9.33268 11.9998V7.99984C9.33268 7.64622 9.19221 7.30708 8.94216 7.05703C8.69211 6.80698 8.35297 6.6665 7.99935 6.6665Z"
        :fill="color"
      />
      <path
        d="M8 5.3335C8.55228 5.3335 9 4.88578 9 4.3335C9 3.78121 8.55228 3.3335 8 3.3335C7.44772 3.3335 7 3.78121 7 4.3335C7 4.88578 7.44772 5.3335 8 5.3335Z"
        :fill="color"
      />
    </g>
    <defs>
      <clipPath id="clip0_5374_49045">
        <rect :width="width" :height="height" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'Info',
  props: {
    color: {
      type: String,
      default: 'black',
    },
    width: {
      type: String,
      default: '16',
    },
    height: {
      type: String,
      default: '16',
    },
  },
};
</script>

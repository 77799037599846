export const getInitialState = () => ({
  notifications: [],
  dashboardData: [],
  client: {
    project: {},
    recentContentKits: [],
    projectTeam: {
      creativeTeam: [],
      producersTeam: [],
      executiveTeam: [],
    },
  },
});

export default {
  UPDATE_PROJECT(state, project) {
    state.client.project = project;
  },
  UPDATE_PROJECT_TEAM(state, team) {
    state.client.projectTeam = team;
  },
  UPDATE_RECENT_CONTENT_KITS(state, recentContentKits) {
    state.client.recentContentKits = recentContentKits;
  },
  UPDATE_NOTIFICATIONS(state, list) {
    state.notifications = list;
  },
  UPDATE_DASHBOARD_DATA(state, list) {
    state.dashboardData = list;
  },
  RESET(state) {
    Object.assign(state, getInitialState());
  },
};

// eslint-disable-next-line import/prefer-default-export
export const getNextOnboardingStepInfo = ({
  onboardingState,
  role,
}) => {
  if (!['creative', 'client'].includes(role)) throw new Error("can't handle");
  if (!onboardingState) {
    return {
      name: 'dashboard',
      url: '/dashboard',
    };
  }
  if (role === 'client') {
    const steps = [
      {
        id: '1',
        name: 'signup',
        url: '/register?onboarding_role=client',
      },
      {
        id: '2',
        name: 'signup',
        url: '/register?onboarding_role=client',
      },
      {
        id: '3',
        name: 'client/get-started',
        url: '/onboarding/client/get-started',
      },
      {
        id: '4',
        name: 'client/content-preferences',
        url: '/onboarding/client/content-preferences',
      },
      {
        id: '5',
        name: 'client/about-business',
        url: '/onboarding/client/business',
      },
      {
        id: '6',
        name: 'client/about-customers',
        url: '/onboarding/client/customer',
      },
      {
        id: '7',
        name: 'client/next-steps',
        url: '/onboarding/client/finish',
      },
      {
        id: '8',
        name: 'completed',
        url: '/dashboard',
      },
    ];
    // eslint-disable-next-line
    const completedStepIndex = steps.findIndex((step) => step.id == onboardingState);
    const nextStep = steps[completedStepIndex];
    return nextStep;
  }
  if (role === 'creative') {
    const steps = [
      {
        id: '1',
        name: 'signup',
        url: '/register?onboarding_role=creative',
      },
      {
        id: '2',
        name: 'signup',
        url: '/register?onboarding_role=creative',
      },
      {
        id: '3',
        name: 'creative/get-started',
        url: '/onboarding/creative/get-started',
      },
      {
        id: '4',
        name: 'creative/about-you',
        url: '/onboarding/creative/about-you',
      },
      {
        id: '5',
        name: 'creative/language-preference',
        url: '/onboarding/creative/language-preference',
      },
      {
        id: '6',
        name: 'creative/sweep-details',
        url: '/onboarding/creative/sweep-details',
      },
      {
        id: '7',
        name: 'creative/talent-details',
        url: '/onboarding/creative/talent-details',
      },
      {
        id: '8',
        name: 'creative/field-producer-details',
        url: '/onboarding/creative/field-producer-details',
      },
      {
        id: '9',
        name: 'creative/sweep-preferences',
        url: '/onboarding/creative/sweep-preferences',
      },
      {
        id: '10',
        name: 'creative/next-steps',
        url: '/onboarding/creative/next-steps',
      },
      {
        id: '11',
        name: 'creative/pending',
        url: '/onboarding/creative/pending',
      },
    ];
    // eslint-disable-next-line
    const completedStepIndex = steps.findIndex((step) => step.id == onboardingState);
    const nextStep = steps[completedStepIndex];
    return nextStep;
  }
  return {
    name: 'login',
    url: '/',
  };
};

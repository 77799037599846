import moment from 'moment';
import _ from 'lodash';
/* eslint-disable  */
export const getInitialState = () => ({
  orders: [],
  completedOrders: [],
  singleOrder: {},
  pendingOrders: [],
});

export default {
  SET_ORDERS(state, orders) {
    state.orders = orders;
    state.orders.forEach((order, index) => {
      // order.showDeliverables = order.status === 'ACTIVE';
      order.key = `${Date.now().toString()}-${index}`;
    });
  },
  SET_SINGLE_ORDER(state, order) {
    state.singleOrder = order;
  },
  UPDATE_OR_INSERT_ORDER(state, order) {
    const index = state.orders.findIndex(o => o.id === order.id);
    order.key = Date.now().toString();
    if (index > -1) {
      state.orders[index] = order;
    } else {
      state.orders.push(order);
    }
    // sort by createdAt using moment
    state.orders.sort((a, b) => moment(a.createdAt).isBefore(b.createdAt) ? 1 : -1);
  },
  REMOVE_ORDER(state, orderId) {
    state.orders = state.orders.filter(o => o.id !== orderId);
  },
  SET_COMPLETED_ORDERS(state, completedOrders) {
    state.completedOrders = completedOrders;
  },
  SET_PENDING_ORDERS(state, pendingOrders) {
    state.pendingOrders = pendingOrders;
  },
  UPDATE_OR_INSERT_INTO_COMPLETED_ORDERS(state, order) {
    const index = state.completedOrders.findIndex(o => o.id === order.id);
    order.key = Date.now().toString();
    if (index > -1) {
      state.completedOrders[index] = order;
    } else {
      state.completedOrders.push(order);
    }
  },
  SET_ORDER_FILES(state, { orderId, files }) {
    // group the files by deliverableId
    const groupedFiles = _.groupBy(files, 'orderDeliverableId');
    const index = state.orders.findIndex(o => o.id === orderId);
    if (index > -1) {
      const order = state.orders[index];
      order.orderDeliverables.forEach((deliverable) => {
        deliverable.files = groupedFiles[deliverable.id] || [];
      });
      order.key = Date.now().toString();
    }
  },
  REMOVE_ORDER_FILE(state, { orderId, fileId, orderDeliverableId }) {
    const index = state.orders.findIndex(o => o.id === orderId);
    if (index > -1) {
      const order = state.orders[index];
      const deliverable = order.orderDeliverables.find(
        d => d.id === orderDeliverableId
      );
      deliverable.files = [...deliverable.files.filter(f => f.id !== fileId)];
      order.key = Date.now().toString();
    }
  },
};

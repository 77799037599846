// import _ from 'lodash';
import momentTimezone from 'moment-timezone';
import API from '@/helpers/api';

export default {
  async getSweeps(context, { projectId, status }) {
    let api = `/sweep/list?status=${status}&timeZone=${momentTimezone.tz.guess()}`;
    if (projectId) {
      api += `&projectId=${projectId}`;
    }
    const result = await API(context).get(api);
    if (result.success) {
      context.commit('SET_SWEEPS', { sweeps: result.sweepList, type: status });
    }
    return result;
  },
  async getBusinessSweeps(context, { businessId }) {
    const api = `/sweep/list/${businessId}?timeZone=${momentTimezone.tz.guess()}`;
    const result = await API(context).get(api);
    if (result.success) {
      context.commit('SET_SWEEPS_LIST', { sweepsList: result.sweeps, type: 'ALL' });
    }
    return result;
  },
  async CreativeSweepsList(context) {
    const result = await API(context).get(`/sweep/list?timeZone=${momentTimezone.tz.guess()}`);
    return result;
  },
  async sweepCloseOut(context) {
    const result = await API(context).get('/sweep/to_close');
    return result;
  },
  async addSweepTimeline(context, payload) {
    const result = await API(context).post('/sweep_timeline', payload);
    context.commit('ADD_SWEEP_BLOCKS', result.data);
    return result;
  },

  async updateSweepTimeline(context, payload) {
    const result = await API(context).put(`/sweep_timeline/${payload.id}`, payload);
    context.commit('UPDATE_SWEEP_BLOCKS', result.data);
    return result;
  },
  async removeSweepTimeline(context, id) {
    const result = await API(context).delete(`/sweep_timeline/${id}`);
    return result;
  },
  async reScheduleSweep(context, payload) {
    const result = await API(context).put('/sweep/reschedule_Sweep', payload);
    return result;
  },
  async reOrderSweepTimeline(context, payload) {
    const result = await API(context).put('/sweep_timeline/update/reorder', payload);
    return result;
  },
  async closeOutForm(context, payload) {
    const result = await API(context).put('/sweep/closeout_sweep', payload);
    return result;
  },
  async getSweep(context, id) {
    const result = await API(context).get(`/sweep/${id}`);
    return result.sweep;
  },

  async addSweep(context, payload) {
    const result = await API(context).post('/sweep/', payload);
    return result;
  },

  async updateSweep(context, payload) {
    const result = await API(context).put(`/sweep/${payload.id}`, payload);
    return result;
  },

  async removeSweep(context, sweepId) {
    const result = await API(context).delete(`/sweep/${sweepId}`);
    if (result.success) {
      context.commit('REMOVE_SWEEP', { sweepId, type: 'upcoming' });
    }
    return result;
  },

  async assignCreatives(context, payload) {
    const result = await API(context).post('/sweep/assign_creatives_to_sweep', payload);
    return result;
  },

  async removeSweepMember(context, payload) {
    const result = await API(context).put('/sweep/remove_creative_from_sweep', payload);
    return result;
  },

  async acceptInvitation(context, payload) {
    const result = await API(context).put('/sweep/update_sweep_invitation_status', payload);
    return result;
  },

  async getInvitationByToken(context, invitationToken) {
    const result = await API(context).get(`/get_invitaion_token_details/${invitationToken}`);
    return result;
  },

  async updateInvitationByToken(context, payload) {
    const result = await API(context).post('/creative_invitaion_token_confirmation', payload);
    return result;
  },

  async updateInvitationById(context, payload) {
    const result = await API(context).put('/sweep/update_creative_sweep_invitation', payload);
    return result;
  },

  async getSweepInvitiesCount(context, projectId = null) {
    let api = '/sweep/get_invites_count';
    if (projectId) {
      api += `?projectId=${projectId}`;
    }
    const result = await API(context).get(api);
    return result;
  },

  async getPreferredCreatives(context, payload) {
    const result = await API(context).get(`/sweep/prefered_creatives?projectId=${payload.projectId}&sweepId=${payload.sweepId}`);
    if (result.success) {
      context.commit('SET_PREFERRED_CREATIVES', result.team);
    }
    return result;
  },
  async getRecentSweepCreatives(context, payload) {
    const result = await API(context).get(`/sweep/recent_sweep_creatives?businessId=${payload.businessId}`);
    if (result.success) {
      context.commit('SET_RECENT_SWEEP_CREATIVES', result.team);
    }
    return result;
  },

  resetState({ commit }) {
    commit('RESET');
  },

  async getCancelReasons(context) {
    const result = await API(context).get('/cancel_reasons');
    if (result.success) {
      context.commit('SET_CANCEL_REASONS', result.reasons);
    }
    return result;
  },
  async cancelSweep(context, payload) {
    const result = await API(context).post('/sweep/cancel', payload);
    return result;
  },
  async editSweepKeyInfo(context, payload) {
    const result = await API(context).put('/sweep/update_sweep_key_info', payload);
    return result;
  },
  async getSweepTimeLine(context, sweepId) {
    const result = await API(context).get(`/sweep_timeline/${sweepId}`);
    if (result.success) {
      context.commit('TIMELINE', result.sweep);
    }
    return result;
  },
  async removeSweepTimeLineBlock(context, sweepId) {
    const result = await API(context).delete(`/sweep_timeline/${sweepId}`);
    return result;
  },
  async getSweepsByDate(context, { from, to }) {
    const api = `/sweep/sweeps_by_date?timeZone=${momentTimezone.tz.guess()}&startDate=${from}&endDate=${to}`;
    const result = await API(context).get(api);
    if (result.success) {
      context.commit('SET_SWEEPS_LIST', { sweepsList: result.sweeps, type: 'ALL' });
    }
    return result;
  },
  async addConversation(context, payload) {
    const api = '/sweep/conversation';
    const result = await API(context).post(api, payload);
    if (result.success) {
      context.commit('SET_SWEEPS_LIST', { sweepsList: result.sweeps, type: 'ALL' });
    }
    return result;
  },
  async getConversation(context, id) {
    const api = `/sweep/conversation/${id}`;
    const result = await API(context).get(api);
    return result;
  },
  async updateConversation(context, payload) {
    // const api = `/sweep/updateconversation/${id}`;
    const result = await API(context).put('/sweep/update_conversation', payload);
    // const result = await API(context).put(api);
    return result;
  },
};

import _ from 'lodash';
import API from '@/helpers/api';

export default {
  async clientOnboarding(context, onboardingObj) {
    const result = await API(context).post('/users/client_onboarding', onboardingObj);
    if (result.success) {
      const userDetails = _.omit(result.user, 'profile');
      context.commit('profile/SET_USER_PROFILE', result.user, { root: true });
      context.commit('user/SET_USER', userDetails, { root: true });
    }
    return result;
  },
  async creativeOnboarding(context, onboardingObj) {
    const result = await API(context).post('/users/creative_onboarding', onboardingObj);
    if (result.success) {
      const userDetails = _.omit(result.user, 'profile');
      context.commit('profile/SET_USER_PROFILE', result.user, { root: true });
      context.commit('user/SET_USER', userDetails, { root: true });
    }
    return result;
  },
  async getOnboardingUserProfile(context, email) {
    const result = await API(context).get(`/onboarding/user/profile?email=${encodeURIComponent(email)}`);
    if (result.success) {
      return result.data;
    }
    return null;
  },
  async setOnboardingUserProfile(context, data) {
    const result = await API(context).post('/onboarding/user/profile', data);
    if (result.success) {
      return result.data;
    }
    return null;
  },

  async setOnboardingBusinessProfile(context, data) {
    const result = await API(context).post('/onboarding/user/business', data);
    if (result.success) {
      return result.data;
    }
    return null;
  },
  async setUserAccountType(context, data) {
    const result = await API(context).post('/onboarding/user/profile/role', data);
    if (result.success) {
      return result;
    }
    return null;
  },
  async creativeSweepPreferences(context, data) {
    const result = await API(context).post('/creative_availability', data);
    if (result.success) {
      return result;
    }
    return null;
  },
  async saveCreativeAbout(context, data) {
    const result = await API(context).post('/onboarding/creative/about-self', data);
    if (result.success) {
      return result;
    }
    return null;
  },
  async saveCreativeLanguages(context, data) {
    const result = await API(context).post('/onboarding/creative/language-preferences', data);
    if (result.success) {
      return result;
    }
    return null;
  },
  async saveCameraPreferences(context, data) {
    const result = await API(context).post('/onboarding/creative/camera-preferences', data);
    if (result.success) {
      return result;
    }
    return null;
  },
  async saveTalentPreferences(context, data) {
    const result = await API(context).post('/onboarding/creative/talent-preferences', data);
    if (result.success) {
      return result;
    }
    return null;
  },
  async saveFieldPreferences(context, data) {
    const result = await API(context).post('/onboarding/creative/field-preferences', data);
    if (result.success) {
      return result;
    }
    return null;
  },
  async saveFinish(context, data) {
    const result = await API(context).post('/onboarding/creative/finish', data);
    if (result.success) {
      return result;
    }
    return null;
  },
  async saveCustomerAges(context, data) {
    const result = await API(context).post('/onboarding/client/customer', data);
    if (result.success) {
      return result;
    }
    return null;
  },
  async finishCustomerSteps(context, data) {
    const result = await API(context).post('/onboarding/client/finish', data);
    if (result.success) {
      return result;
    }
    return null;
  },

};

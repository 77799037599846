export default {
  agencies(state) {
    return state.agencies;
  },
  agency(state) {
    return state.agency;
  },
  agencyBusinesses(state) {
    return state.agencyBusinesses;
  },
  agencyUsers(state) {
    return state.agencyUsers;
  },
};

import _ from 'lodash';

import API from '@/helpers/api';

export default {
  async getBusiness(context, businessId) {
    const result = await API(context).get(`/business/${businessId}`);
    if (result.success) {
      context.commit('SET_BUSINESS', result.business);
    }
  },

  async getBrandSources(context, { businessId, reference = 'business' }) {
    let params = '';
    if (businessId) params += `businessId=${businessId}`;
    params += `&reference=${reference}`;
    const result = await API(context).get(`/brandings/brand_sources?${params}`);
    if (result.success) {
      const {
        brandStyles,
        brandMusic,
        brandFonts,
      } = result;
      context.commit('SET_BRAND_SOURCES', { brandFonts, brandMusic, brandStyles });
      return result;
    }
    return null;
  },

  async uploadBusinessBanner(context, { file, businessId }) {
    const formData = new FormData();
    formData.set('businessBanner', file);
    const result = await API(context).post(`/business/business_banner_upload/${businessId}`, formData);
    if (result.success) {
      context.dispatch('getBusiness', result.business.id);
    }
    return result;
  },

  async updateBusienss(context, payload) {
    const result = await API(context).put(`/business/${payload.id}`, payload);
    if (result.success) {
      context.commit('SET_BUSINESS', result.business);
    }
    return result;
  },

  // async brandKitUpload(context, { files, projectId, businessId }) {
  //   const formData = new FormData();
  //   if (!Array.isArray(files)) {
  //     throw new Error('files must be an array');
  //   }
  //   if (files.length === 0) {
  //     throw new Error('files must be an array with at least one file');
  //   }
  //   formData.append('projectId', projectId);
  //   formData.append('businessId', businessId);
  //   _.forEach(files, (file) => {
  //     formData.append('brandKitFiles', file);
  //   });
  //   const result = await API(context).post('/brandings/brand_kit', formData);
  //   if (result.success) {
  //     context.commit('SET_BRAND_KITS', result.brandKits);
  //   }
  //   return result;
  // },
  async brandKitDelete(context, id) {
    const result = await API(context).delete(`/brandings/brand_kit/${id}`);
    if (result.success) {
      context.commit('DELETE_BRAND_KIT_DOCUMENT', id);
    }
    return result;
  },
  async brandInspirationUpload(context, { files, projectId, businessId }) {
    if (!Array.isArray(files)) {
      throw new Error('files must be an array');
    }
    if (files.length === 0) {
      throw new Error('files must be an array with at least one file');
    }
    const formData = new FormData();
    formData.append('projectId', projectId);
    formData.append('businessId', businessId);
    _.forEach(files, (file) => {
      formData.append('brandKitInspirations', file);
    });
    const result = await API(context).post('/brandings/brand_inspiration', formData);
    if (result.success) {
      context.commit('SET_BRAND_KIT_INSPIRATION', result.brandKitInspirations);
    }
    return result;
  },
  async brandInspirationDelete(context, { id, brandProfileId }) {
    const result = await API(context).delete(`/brandings/brand_inspiration/${id}`, { data: { brandProfileId } });
    if (result.success) {
      context.commit('SET_BRAND_KIT_INSPIRATION', result.brandKitInspirations);
    }
    return result;
  },
  async brainStormAddLink(context, payload) {
    const result = await API(context).post('/brandings/brand_link', payload);
    if (result.success) {
      context.commit('SET_BRAND_KIT_LINKS', result.links);
    }
    return result;
  },
  async brainStormLinkDelete(context, { id, brandProfileId }) {
    const result = await API(context).delete(`/brandings/brand_link/${id}`, { data: { brandProfileId } });
    if (result.success) {
      context.commit('SET_BRAND_KIT_LINKS', result.brandKitLinks);
    }
    return result;
  },
  async addBrandColor(context, payload) {
    const result = await API(context).post('/brandings/brand_color', payload);
    if (result.success) {
      context.commit('SET_BRAND_COLORS', result.brandColors);
    }
    return result;
  },
  async updateBrandColor(context, color) {
    const { id } = color;
    const result = await API(context).put(`/brandings/brand_color/${id}`, color);
    if (result.success) {
      context.commit('SET_BRAND_COLORS', result.brandColors);
    }
    return result;
  },
  async deleteBrandColor(context, payload) {
    const { id, projectId, businessId } = payload;
    const result = await API(context).delete(`/brandings/brand_color/${id}`, { data: { projectId, businessId } });
    if (result.success) {
      context.commit('SET_BRAND_COLORS', result.brandColors);
    }
    return result;
  },
  async downloadLink(context, { id, projectId }) {
    const result = await API(context).get(`brandings/${id}/brand_kit_download?projectId=${projectId}`);
    return result;
  },

  async downloadInspirationLink(context, { id, projectId }) {
    const result = await API(context).get(`brandings/${id}/brand_inspiration_download?projectId=${projectId}`);
    return result;
  },

  async getBusinessUsers(context, payload) {
    const result = await API(context).get(`business/users?businessId=${payload.businessId}`);
    if (result.success) {
      context.commit('SET_BUSINESS_USERS', result.users);
    }
    return result;
  },
  async updateBrandMusic(context, payload) {
    const result = await API(context).post('/brandings/brand_music', payload);
    if (result.success) {
      context.commit('SET_BRAND_MUSIC', result.brandMusic);
    }
  },
  async updateBrandStyle(context, payload) {
    const result = await API(context).post('/brandings/brand_style', payload);
    if (result.success) {
      context.commit('SET_BRAND_STYLE', result.brandStyle);
    }
  },
  async updateBrandFont(context, payload) {
    const result = await API(context).post('/brandings/brand_font', payload);
    if (result.success) {
      const data = {
        ...result.brandKitFonts,
        ...result.brandFont,
        fontId: result.brandKitFonts.id,
        custom: Boolean(result.brandFont.businessId),
      };
      context.commit('SET_BRAND_FONT', data);
      return data;
    }
    return null;
  },
  async deleteBrandFont(context, payload) {
    const result = await API(context).delete(`/brandings/brand_font/${payload.fontId}`);
    if (result.success) {
      context.commit('DELETE_BRAND_FONT', payload.fontId);
      return result.brandFonts;
    }
    return null;
  },
  async brandKitUserFontDelete(context, payload) {
    const result = await API(context).delete(`/brandings/brand_font/user/${payload.fontId}`);
    if (result.success) {
      context.commit('SET_BRAND_FONTS', _.get(result, 'brandKitFonts', []));
      return result.brandKitFonts;
    }
    return null;
  },
  async updateBrandQuizVisit(context) {
    const result = await API(context).post('users/update_brand_visit', {});
    if (result.success) {
      return true;
    }
    return false;
  },

  async downloadFont(context, { id }) {
    const result = await API(context).get(`/brandings/${id}/brand_font_download`);
    return result;
  },

  async updateCustomerDetails(context, { businessId, data }) {
    const result = await API(context).put(`/business/onboarding-info/${businessId}`, data);
    if (result.success) {
      context.dispatch('getBusiness', result.data.id);
      return result.data;
    }
    return null;
  },
  async loadBusinessWorkspaces(context, { businessId }) {
    const result = await API(context).get(`/business/workspaces?businessId=${businessId}`);
    if (result.success) {
      context.commit('SET_BUSINESS_WORKSPACES', result.projects);
    }
    return result;
  },
  async checkBusinessHasContentKits(context, { businessId }) {
    const result = await API(context).get(`content_kits/has_content_plans/${businessId}`);
    if (result.success) {
      return result.hasContentPlans;
    }
    return false;
  },
  async getMassivePortalInfo(context, { businessId }) {
    const result = await API(context).get(`/business/massive-portal/${businessId}`);
    if (result.success) {
      return result.portal;
    }
    return null;
  },
  async getReasons(context) {
    const result = await API(context).get('reasons');
    if (result.success) {
      context.commit('SET_REASONS', result.reasons);
    }
    return result;
  },
  async getGoals(context) {
    const result = await API(context).get('goals');
    if (result.success) {
      context.commit('SET_GOALS', result.goals);
    }
    return result;
  },
  async updateCredits(context, payload) {
    const result = await API(context).post('/business/update_credits', payload);
    if (result.success) {
      await context.dispatch('getCreditsHistory', { businessId: payload.businessId });
      await context.dispatch('getBusiness', payload.businessId);
    }
    return result;
  },
  async getCreditsHistory(context, { businessId }) {
    const result = await API(context).get(`/business/credit_history/${businessId}`);
    if (result.success) {
      context.commit('SET_CREDITS_HISTORY', result.history);
    }
    return result;
  },
  async getDeliverables(context) {
    const result = await API(context).get('/production_menu');
    if (result.success) {
      // return deliverables which has category
      return result.deliverables.filter((deliverable) => deliverable.category);
    }
    return [];
  },
  async getContentPlan(context, { businessId }) {
    const result = await API(context).get(`/content_plan?businessId=${businessId}`);
    context.commit('SET_CONTENT_PLAN', result.contentPlan);
    return result;
  },
  async addContentPlan(context, payload) {
    const result = await API(context).post('/content_plan', payload);
    if (result.success) {
      context.dispatch('getContentPlan', { businessId: payload.businessId });
    }
    return result;
  },
  async getWorkspaceContentKits(context, { workspaceId }) {
    const result = await API(context).get(`content_kits/workspaceContentKits/${workspaceId}`);
    if (result.success) {
      return result.contentKitFiles;
    }
    return false;
  },
  async getFileCategories(context) {
    const result = await API(context).get('/orders/file-categories');
    if (result.success) {
      context.commit('SET_FILE_CATEGORIES', result.categories);
    }
    return result;
  },
};

export const setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
};

export const getCookie = (cname) => {
  const decodedCookie = decodeURIComponent(document.cookie);
  const splits = decodedCookie.split(';');
  const store = {};
  splits.forEach((item) => {
    const split = item.split('=');
    // eslint-disable-next-line prefer-destructuring
    store[split[0].trim()] = split[1];
  });
  return store[cname] || '';
};

import VuexPersistence from 'vuex-persist';

export const modulesToClearOnLogout = [
  'user',
  'onboarding',
  'project',
  'contentKit',
  'profile',
  'messaging',
  'dashboard',
  'business',
  'brandProfile',
];

export default new VuexPersistence({
  storage: window.localStorage,
  modules: ['user', 'project'],
});

import LogRocket from 'logrocket'

export const tokenExpiryLimit = process.env.VUE_APP_TOKEN_EXPIRY_LIMIT || (7 * 24 * 3600);
export const tokenShortExpiryLimit = process.env.VUE_APP_TOKEN_SHORT_EXPIRY_LIMIT || 3600;
export const clientCalendlyUrl = process.env.VUE_APP_CALENDLY_CLIENT_URL;
export const creativeCalendlyUrl = process.env.VUE_APP_CALENDLY_CREATIVE_URL;

export const convertArrayToObject = (array, key, assignObj) => {
  const initialValue = {};
  return array.reduce((obj, item) => ({
    ...obj,
    [item[key]]: assignObj || item,
  }), initialValue);
};

export function url(value) {
  if (value) {
    // eslint-disable-next-line no-useless-escape
    return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(value);
  }
  return true;
}
export function getValidJSON(param) {
  try {
    return JSON.parse(param);
  } catch (error) {
    return false;
  }
}

let userIdentifiedToLogRocket = false

// if you don't create some kind of global variable, this identification
// happens > 10x on the dashboard alone, legacy code is sloppy
export function identifyUserForLogRocket(email) {
  if (!userIdentifiedToLogRocket) {
    LogRocket.identify(email)
    userIdentifiedToLogRocket = true
  }
}

export function getUser() {
  const userObj = localStorage.getItem('__bs_valid');
  const user = getValidJSON(userObj);
  identifyUserForLogRocket(user?.userDetails?.email);
  return user || null;
}

export function authenticate(initUrl, router) {
  localStorage.setItem('__bs_valid', null);
  sessionStorage.setItem('__bs_valid', null);
  localStorage.setItem('initUrl', initUrl);
  if (router) {
    if (initUrl !== '/login') {
      router.push('/login');
      return;
    }
  }
  if (initUrl === '/login' || initUrl === '/') { return; }
  window.location.href = '/login';
}

export function convertBytesToSize(bytes) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  return `${(bytes / (1000 ** i)).toFixed(1)} ${sizes[i]}`;
}

export function truncateText(str, length, ellipsis = '...') {
  if (str && str.length > length) {
    return str.substring(0, length) + ellipsis;
  }
  return str;
}

export function generateUrl(link) {
  try {
    return link ? new URL(link) : '';
  } catch {
    let validUrl = link;
    const prefix = 'http://';
    if (link.substr(0, prefix.length) !== prefix) {
      validUrl = prefix + validUrl;
    }
    return validUrl;
  }
}

/*
  parse original url to use serverless image handler stack
  @returns cloudfront url
*/
export const getOptimizedS3ImageURL = (objectURL, options = {}) => {
  try {
    if (typeof objectURL !== 'string') {
      return objectURL;
    }
    // check if url is s3 object url or not
    if (!/s3.amazonaws/.test(objectURL)) {
      console.warn(`getOptimizedS3ImageUrl: ${objectURL} is not s3 bucket url`);
    }
    const excludedImageExtensions = ['svg', 'gif'];
    const fileExtension = objectURL.split('.').pop();
    if (excludedImageExtensions.includes(fileExtension)) {
      return objectURL;
    }
    const urlInfo = new URL(objectURL);
    let cloudFrontURL = `${process.env.VUE_APP_CLOUD_FRONT_ENDPOINT}`;
    const { width, height } = options;
    if (width || height) {
      cloudFrontURL += `/fit-in/${width || height}x${width || height}`;
    }
    cloudFrontURL += `${urlInfo.pathname}`;
    return cloudFrontURL;
  } catch (error) {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.log('%c logging in dev mode, dont worry!!',
        'color: green; font-weight: bold; background:white;');
      // eslint-disable-next-line no-console
      console.log('getOptimizedS3ImageURL: failed to parse url', error.message, objectURL);
    }
    return objectURL;
  }
};

export const isValidEmail = (email) => {
  // eslint-disable-next-line
  const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
};

// eslint-disable-next-line
export const isValidURL = (url) => {
  // eslint-disable-next-line
  const urlRegex = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
  return urlRegex.test(url);
};

import _ from 'lodash';
import API from '@/helpers/api';

export default {
  registerUser(context, user) {
    const formData = new FormData();
    _.map(user, (val, key) => {
      if (key === 'agreements') {
        _.forEach(val, (file) => {
          formData.append('agreementFiles', file);
        });
      } else if (val !== null) {
        formData.append(key, _.isObject(val) ? JSON.stringify(val) : val);
      }
    });
    const result = API(context).post('/admin/users/user_register', formData);
    return result;
  },

  async updateUser(context, user) {
    const formData = new FormData();
    _.map(user, (val, key) => {
      if (key === 'agreements') {
        _.forEach(val, (file) => {
          formData.append('agreementFiles', file);
        });
      } else if (val !== null) {
        formData.append(key, _.isObject(val) ? JSON.stringify(val) : val);
      }
    });
    const result = await API(context).put(`/admin/users/${user.id}/user_update`, formData);
    return result;
  },

  async registerStaff(context, user) {
    const result = await API(context).post('/user_creation', user);
    return result;
  },

  async updateStaff(context, user) {
    const result = await API(context).put(`admin/users/${user.id}/profile_update`, user);
    return result;
  },

  setUserRole({ commit }, role) {
    commit('SET_USER_ROLE', role);
  },

  async getUserDetails(context, id) {
    const result = await API(context).get(`/admin/users/${id}`);
    return result.user;
  },

  async deleteUser(context, id) {
    const result = await API(context).post('admin/users/deactivate_user_account', { userId: id });
    return result;
  },

  async reactivateUser(context, id) {
    const result = await API(context).post('/admin/users/activate_user_account', { userId: id });
    return result;
  },

  async getExportUsers(context, payload) {
    return API(context).post('/admin/users/export_users', payload);
  },

  async getGeneralAnalytics(context, payload) {
    const result = await API(context).get(`/analytics?fromDate=${payload.fromDate}&toDate=${payload.toDate}&all=false`);
    if (result.success) {
      context.commit('SET_ANALYTICS', result.data);
    }
  },
  async getSweepReportList(context, payload) {
    const result = await API(context).get(`/admin/users/sweep_report?fromDate=${payload.fromDate}&toDate=${payload.toDate}&all=false`);
    return result;
  },
  async getCreditReportList(context) {
    const result = await API(context).get('/admin/users/credit_report');
    return result;
  },
  async updateBusinessCredits(context, payload) {
    const result = await API(context).post('/admin/users/update_credits', payload);
    return result;
  },
  async exportSweepReports(context, payload) {
    const result = await API(context).post('/admin/users/export_sweepReports', payload);
    return result;
  },
  async editCreativePayout(context, payload) {
    const result = await API(context).put('admin/users/edit_payout', payload);
    return result;
  },
  async customerTypes(context) {
    return API(context).get('/types-customers');
  },
  async bundleTypes(context) {
    return API(context).get('/types_bundles');
  },
  async creditsOperateType(context) {
    return API(context).get('/types_affectives');
  },
  async promoCodesList(context) {
    const result = await API(context).get('admin/stripe/coupons_list');
    return result;
  },
  async updatePromocode(context, payload) {
    const result = await API(context).put('admin/stripe/update_coupon', payload);
    return result;
  },
  async getContentSweepAnalytics(context, payload) {
    const result = await API(context).get(`/sweep_analytics/projects_sweep_details?fromDate=${payload.fromDate}&toDate=${payload.toDate}&all=false`);
    if (result.success) {
      context.commit('SET_CONTENT_SWEEPS', result);
    }
  },

  async getPayTiers(context) {
    const result = await API(context).get('/creative_pay_rates');
    if (result.success) {
      context.commit('SET_PAY_TIERS', result.creativePayRates);
    }
  },

  async updatePayTier(context, payload) {
    const result = await API(context).put(`creative_pay_rates/${payload.id}`, payload);
    return result;
  },

  async getDeliverables(context) {
    const result = await API(context).get('/deliverables/list');
    if (result.success) {
      context.commit('SET_DELIVERABLES', result.deliverables);
    }
  },

  async updateProjectDeliverables(context, { projectId, payload }) {
    const result = await API(context).put(`/projects/${projectId}/update_project_deliverables`, payload);
    return result;
  },

  async updateDeliverables(context, payload) {
    const result = await API(context).put(`/deliverables/${payload.id}`, payload);
    return result;
  },

  async addDeliverables(context, payload) {
    const result = await API(context).post('/deliverables/', payload);
    return result;
  },

  async getCreativeTypes(context) {
    const result = await API(context).get('/creative_types');
    if (result.success) {
      context.commit('SET_CREATIVE_TYPES', result.creativeTypes);
    }
  },

  async getCategories(context) {
    const result = await API(context).get('/categorys');
    if (result.success) {
      context.commit('SET_CATEGORIES', result.categorys);
    }
  },

  async getSubscriptionBlends(context) {
    const result = await API(context).get('/admin/subscription_blends/list');
    if (result.success) {
      context.commit('SET_SUBSCRIPTIONS', result.subscriptionBlends);
    }
    return result;
  },

  async updateSubscriptionBlend(context, payload) {
    const result = await API(context).put(`/admin/subscription_blends/${payload.id}`, payload);
    if (result.success) context.dispatch('getSubscriptionBlends');
    return result;
  },

  async addSubscriptionBlend(context, payload) {
    const result = await API(context).post('/admin/subscription_blends', payload);
    if (result.success) context.dispatch('getSubscriptionBlends');
    return result;
  },

  async getAppPreferences(context) {
    const result = await API(context).get('admin/app_preferences');
    return result.appPreferences;
  },
  async updateSweepExpiryTime(context, payload) {
    const result = await API(context).post('admin/app_preferences/sweep_invitation_expiry_time', payload);
    if (result.success) context.dispatch('getAppPreferences');
    return result;
  },
};

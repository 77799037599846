import _ from 'lodash';

export const getInitialState = () => ({
  token: '',
  user: {
    onboardingStatus: {
    },
    activityAlerts: [],
    availability: {},
    rules: [],
  },
  creativeList: [],
  creativesReports: [],
  events: [],
});

export default {
  SET_USER_TOKEN(state, token) {
    state.token = token;
  },
  REMOVE_USER_TOKEN(state) {
    state.token = '';
  },
  SET_USER(state, user) {
    state.user = Object.assign({}, state.user, user);
  },
  REMOVE_USER(state) {
    state.user = '';
  },
  UPDATE_USER(state, user) {
    state.user = _.merge({}, state.user, user);
  },
  SET_USER_PROFILE_PIC(state, user) {
    state.user.profilePic = user.profilePic;
    state.user.thumbnail = user.thumbnail;
    state.user.smallImage = user.smallImage;
  },
  CREATIVE_AVAILABILITY(state, availability) {
    state.user = Object.assign({},
      state.user,
      { availability: availability.availability, days: availability });
  },
  SET_ACTIVITY_ALERTS(state, alerts) {
    state.user.activityAlerts = alerts;
  },
  SET_CREATIVES_REPORTS(state, reports) {
    state.creativesReports = reports;
  },
  SET_CREATIVE_LIST(state, creatives) {
    state.creativeList = creatives;
  },
  RESET(state) {
    Object.assign(state, getInitialState());
  },
  SET_EVENTS(state, events) {
    state.events = events;
  },
  ADD_EVENT(state, event) {
    state.events = [...state.events, event];
  },
};

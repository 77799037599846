<template>
  <div class="file-progress-notifier d-flex align-end">
    <div class="upload-container" v-if="uploadingItems.length">
      <div class="header pa-4">
        <div v-if="uploadingItemsCount > 0"> Uploading {{uploadingItemsCount}} item(s) </div>
        <v-row class="ma-0 pa-0" v-else>
          <v-col cols="10" class="pa-0"> {{uploadedItemsCount}} item(s) uploaded </v-col>
          <v-col cols="2" class="pa-0 d-flex justify-end" >
            <v-icon color="white" @click="resetUploadProgress()">mdi-close</v-icon>
          </v-col>
        </v-row>
      </div>
      <div>
        <v-list class="item-list">
          <v-list-item
            v-for="(item, i) in uploadingItems"
            :key="`${item.file.name}-${i}`"
            class="item-content"
          >
            <upload-file-progress
              v-show="!item.removed"
              :item="item"
              :index="i"
              :key="`${item.file.name}-${i}`"
            />
          </v-list-item>
        </v-list>
      </div>
    </div>
    <div class="download-container ml-2" v-if="downloadItems.length">
      <div class="header pa-4">
          <span v-if="downloadingItemsCount > 0"> Downloading Files </span>
          <v-row class="ma-0 pa-0" v-else>
            <v-col cols="10" class="pa-0"> Download Ready</v-col>
            <v-col cols="2" class="pa-0 d-flex justify-end" >
            <v-icon color="white"
              @click="resetDownloadProgress()"
               v-if="downloadingItemsCount === 0"
            >
              mdi-close
            </v-icon>
            </v-col>
          </v-row>
      </div>
      <div>
        <v-list class="item-list">
          <v-list-item
            v-for="(item, i) in downloadItems"
            :key="`${item.folderName}-${i}`"
            class="item-content"
          >
            <download-file-progress :item="item" :index="i"> </download-file-progress>
          </v-list-item>
        </v-list>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import UploadFileProgress from './UploadFileProgress';
import DownloadFileProgress from './DownloadFileProgress';

export default {
  components: {
    DownloadFileProgress,
    UploadFileProgress,
  },
  computed: {
    ...mapGetters(['uploadingItems', 'downloadItems']),
    uploadingItemsCount() {
      return _.filter(this.uploadingItems, { completed: false }).length;
    },
    uploadedItemsCount() {
      return _.filter(this.uploadingItems, { completed: true, error: null }).length;
    },
    downloadingItemsCount() {
      return _.filter(this.downloadItems, { completed: false }).length;
    },
  },
  watch: {
    uploadingItemsCount(newValue) {
      const pendingItemCount = this.uploadingItems.length - this.uploadedItemsCount;
      if (pendingItemCount) {
        if (this.uploadTimer) {
          clearTimeout(this.uploadTimer);
        }
        return;
      }
      if (newValue === 0) {
        if (this.uploadTimer) {
          clearTimeout(this.uploadTimer);
        }
        this.uploadTimer = setTimeout(() => {
          this.resetUploadProgress();
        }, 5000);
      }
    },
    downloadingItemsCount(newVal) {
      if (newVal === 0) {
        if (this.downloadTimer) {
          clearTimeout(this.downloadTimer);
        }
        this.downloadTimer = setTimeout(() => {
          this.resetDownloadProgress();
        }, 5000);
      }
    },
  },
  data() {
    return ({
      uploadTimer: null,
      downloadTimer: null,
    });
  },
  methods: {
    ...mapActions(['resetUploadItems', 'resetDownloadItems']),
    resetUploadProgress() {
      this.resetUploadItems();
    },
    resetDownloadProgress() {
      this.resetDownloadItems();
    },
    handler: function handler(event) {
      if (this.uploadingItemsCount === 0 && this.downloadingItemsCount === 0) {
        return;
      }
      event.preventDefault();
      // eslint-disable-next-line
      event.returnValue = `Are you sure you want to leave?`;
    },
  },
  created() {
    window.addEventListener('beforeunload', this.handler);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handler);
  },
};
</script>

<style scoped lang="scss">
  .file-progress-notifier {
    bottom: 1px;
    font-family: $fontFamily1 !important;
    position: fixed;
    right: 20px;
    z-index: 5;
    .upload-container, .download-container {
      border: solid 1px $secondary1;
    }
    .item-list {
      border-radius: 0 !important;
      max-height: 230px;
      overflow-y: auto;
    }
    .header {
      background-color: $secondary1;
      color: white;
      font-size: 16px;
    }
    .item-content {
      width: 320px;
      padding-left: 12px;
      padding-right: 0 !important;
      border-bottom: solid 1px #dbdbdb;
    }
  }
</style>

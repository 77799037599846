
// eslint-disable-next-line import/extensions
import HyperTranslate from './Index.vue';

const install = (Vue) => {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.log('HyperTranslate plugin is installed');
  }
  Vue.component(HyperTranslate.name, HyperTranslate);
};

if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue);
}

export default {
  install,
};

import _ from 'lodash';

import API from '@/helpers/api';

export default {
  async getBrandProfile(context, { businessId, reference = 'business', agencyId }) {
    let params = '';
    if (businessId) params += `businessId=${businessId}`;
    params += `&reference=${reference}`;
    if (agencyId) params += `&agencyId=${agencyId}`;
    const result = await API(context).get(`/brandings/brand_profile?${params}`);
    if (result.success) {
      result.brandProfile.reference = reference;
      context.commit('SET_BRAND_PROFILE', result.brandProfile);
      return result;
    }
    return null;
  },

  async getBrandSources(context, { businessId, reference = 'business', agencyId }) {
    let params = '';
    if (businessId) params += `businessId=${businessId}`;
    if (agencyId) params += `&agencyId=${agencyId}`;
    params += `&reference=${reference}`;
    const result = await API(context).get(`/brandings/brand_sources?${params}`);
    if (result.success) {
      const {
        brandStyles,
        brandMusic,
        brandFonts,
      } = result;
      context.commit('SET_BRAND_SOURCES', { brandFonts, brandMusic, brandStyles });
      return result;
    }
    return null;
  },

  async uploadBusinessBanner(context, { file, brandProfileId, reference = 'business' }) {
    const formData = new FormData();
    formData.set('brandLogo', file);
    formData.set('reference', reference);
    formData.set('brandProfileId', brandProfileId);
    const result = await API(context).post(`/brandings/brand-logo/${brandProfileId}`, formData);
    if (result.success) {
      context.commit('SET_BRAND_PROFILE', result.brandProfile);
    }
    return result;
  },
  async updateGeneralInfo(context, payload) {
    const result = await API(context).put(`/brandings/general-info/${payload.id}`, payload);
    if (result.success) {
      context.commit('SET_BRAND_PROFILE', result.brandProfile);
    }
    return result;
  },

  async brandKitUpload(context, { files, brandProfileId, reference = 'business' }) {
    const formData = new FormData();
    if (!Array.isArray(files)) {
      throw new Error('files must be an array');
    }
    if (files.length === 0) {
      throw new Error('files must be an array with at least one file');
    }
    formData.append('brandProfileId', brandProfileId);
    formData.append('reference', reference);
    _.forEach(files, (file) => {
      formData.append('brandKitFiles', file);
    });
    const result = await API(context).post('/brandings/brand_kit', formData);
    if (result.success) {
      context.commit('SET_BRAND_KITS', result.brandKits);
    }
    return result;
  },
  async brandKitDelete(context, { id, brandProfileId }) {
    const result = await API(context).delete(`/brandings/brand_kit/${id}`, { data: { brandProfileId } });
    if (result.success) {
      context.commit('DELETE_BRAND_KIT_DOCUMENT', id);
    }
    return result;
  },
  async brandInspirationUpload(context, { files, brandProfileId, reference = 'business' }) {
    if (!Array.isArray(files)) {
      throw new Error('files must be an array');
    }
    if (files.length === 0) {
      throw new Error('files must be an array with at least one file');
    }
    const formData = new FormData();
    formData.append('brandProfileId', brandProfileId);
    formData.append('reference', reference);
    _.forEach(files, (file) => {
      formData.append('brandKitInspirations', file);
    });
    const result = await API(context).post('/brandings/brand_inspiration', formData);
    if (result.success) {
      context.commit('SET_BRAND_KIT_INSPIRATION', result.brandKitInspirations);
    }
    return result;
  },
  async brandInspirationDelete(context, { id, brandProfileId }) {
    const result = await API(context).delete(`/brandings/brand_inspiration/${id}`, { data: { brandProfileId } });
    if (result.success) {
      context.commit('SET_BRAND_KIT_INSPIRATION', result.brandKitInspirations);
    }
    return result;
  },
  async brainStormAddLink(context, payload) {
    const result = await API(context).post('/brandings/brand_link', payload);
    if (result.success) {
      context.commit('SET_BRAND_KIT_LINKS', result.links);
    }
    return result;
  },
  async brainStormLinkDelete(context, { id, brandProfileId }) {
    const result = await API(context).delete(`/brandings/brand_link/${id}`, { data: { brandProfileId } });
    if (result.success) {
      context.commit('SET_BRAND_KIT_LINKS', result.brandKitLinks);
    }
    return result;
  },
  async addBrandColor(context, payload) {
    const result = await API(context).post('/brandings/brand_color', payload);
    if (result.success) {
      context.commit('SET_BRAND_COLORS', result.brandColors);
    }
    return result;
  },
  async updateBrandColor(context, color) {
    const { id } = color;
    const result = await API(context).put(`/brandings/brand_color/${id}`, color);
    if (result.success) {
      context.commit('SET_BRAND_COLORS', result.brandColors);
    }
    return result;
  },
  async deleteBrandColor(context, payload) {
    const result = await API(context).delete(`/brandings/brand_color/${payload.id}`, { data: payload });
    if (result.success) {
      context.commit('SET_BRAND_COLORS', result.brandColors);
    }
    return result;
  },
  async downloadLink(context, { id, projectId }) {
    const result = await API(context).get(`brandings/${id}/brand_kit_download?projectId=${projectId}`);
    return result;
  },

  async downloadInspirationLink(context, { id, projectId }) {
    const result = await API(context).get(`brandings/${id}/brand_inspiration_download?projectId=${projectId}`);
    return result;
  },

  async getBusinessUsers(context) {
    const result = await API(context).get('business/users');
    return result.users;
  },
  async updateBrandMusic(context, payload) {
    const result = await API(context).post('/brandings/brand_music', payload);
    if (result.success) {
      context.commit('SET_BRAND_MUSIC', result.brandMusic);
    }
  },
  async updateBrandStyle(context, payload) {
    const result = await API(context).post('/brandings/brand_style', payload);
    if (result.success) {
      context.commit('SET_BRAND_STYLE', result.brandStyle);
    }
  },
  async updateBrandFont(context, payload) {
    const result = await API(context).post('/brandings/brand_font', payload);
    if (result.success) {
      const data = {
        ...result.brandKitFonts,
        ...result.brandFont,
        fontId: result.brandKitFonts.id,
        custom: Boolean(result.brandFont.businessId),
      };
      context.commit('SET_BRAND_FONT', data);
      return data;
    }
    return null;
  },
  async deleteBrandFont(context, payload) {
    const result = await API(context).delete(`/brandings/brand_font/${payload.fontId}`, { data: payload });
    if (result.success) {
      context.commit('DELETE_BRAND_FONT', payload.fontId);
      return result.brandFonts;
    }
    return null;
  },
  async brandKitUserFontDelete(context, payload) {
    const result = await API(context).delete(`/brandings/brand_font/user/${payload.fontId}`, {
      data: payload,
    });
    if (result.success) {
      context.commit('SET_BRAND_FONTS', _.get(result, 'brandKitFonts', []));
      return result.brandKitFonts;
    }
    return null;
  },
  async updateBrandQuizVisit(context) {
    const result = await API(context).post('users/update_brand_visit', {});
    if (result.success) {
      return true;
    }
    return false;
  },

  async downloadFont(context, { id }) {
    const result = await API(context).get(`/brandings/${id}/brand_font_download`);
    return result;
  },
  async updateCustomerDetails(context, payload) {
    const result = await API(context).put(`/brandings/onboarding-info/${payload.brandProfileId}`, payload);
    if (result.success) {
      context.commit('SET_BRAND_PROFILE', result.brandProfile);
      return result.brandProfile;
    }
    return null;
  },
  async checkBrandProfileEditAccess(context, payload) {
    const page = payload.page || '';
    const params = `?page=${page}`;
    const result = await API(context)
      .get(`/brandings/brand-profile-access/${payload.brandProfileId}${params}`);
    if (result.success) {
      return result.accessability;
    }
    return false;
  },
  async getBrandProfilesListForBusiness(context, payload) {
    const params = `?reference=${payload.reference}`;
    const result = await API(context)
      .get(`/brandings/associated-brand-profiles/${payload.businessId}${params}`);
    if (result.success) {
      return result.brandProfileList;
    }
    return [];
  },
};

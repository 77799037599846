import _ from 'lodash';

export const getInitialState = () => ({
  profile: {
    client: {
      firstName: '',
      lastName: '',
      email: '',
      businessName: '',
      number: null,
      website: null,
      businessTypeId: null,
      contentTypeIds: [],
      timeRangeId: null,
      priceRangeId: null,
      instagramUrl: '',
      facebookUrl: '',
      brandKits: [],
      brandColors: [],
      agreements: [],
      defaultContact: {},
    },
    creative: {
      firstName: '',
      lastName: '',
      email: '',
      number: null,
      bio: '',
      instagramUrl: '',
      portfolio: null,
      zipcode: null,
      radius: null,
      skillsetIds: [],
      cameraEquipments: [],
      lensEquipments: [],
      accessories: [],
      interestIds: [],
      styleIds: [],
      writerInterestIds: [],
      subjectIds: [],
      facebookUrl: '',
      slackId: '',
      agreements: [],
      isPrivateAccount: false,
      userPronouns: [],
      onboardingStatus: {},
      payTiers: [],
    },
    executive: {
      firstName: '',
      lastName: '',
      email: '',
      number: null,
      website: null,
      bio: '',
      instagramUrl: '',
      facebookUrl: '',
      slackId: '',
      zipcode: null,
      agreements: [],
      businessTypeIds: [],
      calendly: '',
      isTrainee: false,
      isDefaultContact: '',
    },
    producer: {
      firstName: '',
      lastName: '',
      email: '',
      number: null,
      website: null,
      bio: '',
      instagramUrl: '',
      facebookUrl: '',
      slackId: '',
      zipcode: null,
      agreements: [],
      businessTypeIds: [],
      calendly: '',
      isTrainee: false,

    },
    editor: {
      firstName: '',
      lastName: '',
      email: '',
      bio: '',
      number: null,
      profilePic: '',
      instagramUrl: '',
      facebookUrl: '',
      slackId: '',
    },
    community_manager: {
      firstName: '',
      lastName: '',
      email: '',
      bio: '',
      number: null,
      profilePic: '',
      instagramUrl: '',
      facebookUrl: '',
      slackId: '',
    },
    agency_customer: {
      firstName: '',
      lastName: '',
      email: '',
      bio: '',
      number: null,
      profilePic: '',
      instagramUrl: '',
      facebookUrl: '',
      slackId: '',
    },
    portfolioItems: [],
  },
  client: {
    businessTypes: [],
    contentTypes: [],
    timeRanges: [],
    priceRanges: [],
  },
  creative: {
    skillsets: [],
    interests: [],
    accessories: [],
    cameraEquipments: [],
    writerInterests: [],
    lensEquipments: [],
    styles: [],
    subjects: [],
    payTiers: [],
  },
  executive: {
    businessTypes: [],
  },
});
export default {
  SET_BUSINESS_TYPES(state, businessTypes) {
    state.client.businessTypes = businessTypes;
  },
  SET_CONTENT_TYPES(state, contentTypes) {
    state.client.contentTypes = contentTypes;
  },
  SET_PRICE_RANGES(state, priceRanges) {
    state.client.priceRanges = priceRanges;
  },
  SET_TIME_RANGES(state, timeRanges) {
    state.client.timeRanges = timeRanges;
  },
  SET_CLIENT_OPTIONS(state, options) {
    state.client = options;
  },
  SET_CREATIVE_OPTIONS(state, options) {
    state.creative = options;
  },
  SET_EXECUTIVE_OPTIONS(state, options) {
    state.executive = options;
  },
  SET_USER_PROFILE(state, user) {
    const role = _.get(user, 'role.name');
    const roles = ['executive', 'editor', 'producer', 'community_manager', 'agency_owner', 'agency_member'];
    if (roles.includes(role)) {
      const profile = ['agency_owner', 'agency_member'].includes(role) ? 'agency_customer' : role;
      const {
        email, firstName, lastName, bio, isDefaultContact, isTrainee,
      } = user;
      state.profile[profile] = {
        ...state.profile[profile],
        ...user.profile,
        firstName,
        lastName,
        email,
        bio,
        isDefaultContact,
        isTrainee,
      };
      // agency can act as customer so save the same info in the client profile
      if (role === 'agency_owner' || role === 'agency_member') {
        state.profile.client = {
          ...state.profile[profile],
          ...user.profile,
          ...{
            firstName, lastName, email, defaultContact: user.defaultContact,
          },
        };
      }
    } else if (role === 'creative') {
      const profile = role;
      const {
        email,
        firstName,
        lastName,
        userPronouns,
        isPrivateAccount,
        onboardingStatus,
        address,
        profilePic,
        radius,
        sweepPreferences,
      } = user;
      state.profile[profile] = Object.assign(state.profile[profile],
        user.profile,
        {
          email,
          firstName,
          lastName,
          userPronouns,
          isPrivateAccount,
          onboardingStatus,
          address,
          profilePic,
          radius,
          sweepPreferences,
        });
    } else {
      const profile = ['member', 'client'].includes(role) ? 'client' : role;
      const {
        email, firstName, lastName,
      } = user;
      state.profile[profile] = {
        ...state.profile[profile],
        ...user.profile,
        ...{
          firstName, lastName, email, defaultContact: user.defaultContact,
        },
      };
    }
  },
  UPDATE_PROFILE(state, options) {
    const type = _.get(options, 'type');
    const property = _.get(options, 'property');
    const value = _.get(options, 'value');
    state.profile[type][property] = value;
  },
  SET_BRAND_KITS(state, brandKits) {
    state.profile.client.brandKits = brandKits;
  },
  UPDATE_BRAND_COLOR(state, brandColors) {
    state.profile.client.brandColors = brandColors;
  },
  SET_PORTFOLIO_ITEMS(state, portfolioItems) {
    state.profile.portfolioItems = portfolioItems;
  },
  PUSH_PORTFOLIO_ITEM(state, data) {
    state.profile.portfolioItems.push(data.portfolioItem);
  },
  REMOVE_PORTFOLIO_ITEM(state, itemId) {
    const index = state.profile.portfolioItems.findIndex((item) => item.id === itemId);
    if (index !== -1) state.profile.portfolioItems.splice(index, 1);
  },
  RESET(state) {
    Object.assign(state, getInitialState());
  },
};

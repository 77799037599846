import momentTimezone from 'moment-timezone';
import API from '@/helpers/api';

export default {
  async getNotifications(context, perPageNumber) {
    const perPage = perPageNumber || 10;
    const result = await API(context).get(`/notifications/list?perPage=${perPage}`);
    if (result.success) {
      context.commit('UPDATE_NOTIFICATIONS', result.notifications);
    }
    return result;
  },
  async getDashboardDetails(context) {
    const result = await API(context).get(`/users/dashboard?timeZone=${momentTimezone.tz.guess()}`);
    if (result.success) {
      context.commit('UPDATE_DASHBOARD_DATA', result);
      if (result.project && result.recentContentKits && result.users) {
        context.commit('UPDATE_PROJECT', result.project);
        context.commit('UPDATE_RECENT_CONTENT_KITS', result.recentContentKits);
        context.commit('UPDATE_PROJECT_TEAM', result.users);
      }
    }
    return result;
  },
};

// import _ from 'lodash';
import API from '@/helpers/api';

export default {
  async getSweepBlocks(context) {
    const result = await API(context).get('/sweepblocks/list');
    context.commit('SET_SWEEP_BLOCKS', result.data);
    return result.data;
  },

  async addSweepBlocks(context, payload) {
    const result = await API(context).post('/sweepblocks/create', payload);
    context.commit('ADD_SWEEP_BLOCKS', result.data);
    return result;
  },

  async addSweepCreate(context, payload) {
    const result = await API(context).post('/sweep', payload);
    return result;
  },

  async setTimeZone(context) {
    const result = await API(context).get('/sweep/timezones');
    context.commit('SET_TIMEZONE', result.timeZones);
    return result.timeZones;
  },

  async autoTimeZone(context, payload) {
    const result = await API(context).post('/auto_timezones', payload);
    return result;
  },

  async updateSweepBlock(context, payload) {
    const result = await API(context).post(`/sweepblocks/update/${payload.id}`, payload);
    context.commit('UPDATE_SWEEP_BLOCKS', result.data);
    return result;
  },

  async removeSweepBlock(context, id) {
    const result = await API(context).delete(`/sweepblocks/delete/${id}`);
    if (result.success) {
      context.commit('REMOVE_SWEEP_BLOCK', id);
    }
    return result;
  },

  async getEquipmentsList(context) {
    const result = await API(context).get('/sweepblocks/equipment');
    context.commit('SET_EQUIPMENTS', result.data);
    return result.data;
  },

  async saveEquipment(context, payload) {
    const result = await API(context).post('/sweepblocks/equipment/save', payload);
    if (result.success) {
      context.commit('SAVE_EQUIPMENTS', result.data);
    }
    return result;
  },

  async getShotSettings(context) {
    const result = await API(context).get('/sweepblocks/shot-settings');
    context.commit('SET_SHOT_SETTINGS', result.data);
    return result.data;
  },

  async saveShotSettings(context, payload) {
    const result = await API(context).post('/sweepblocks/shot-settings/save', payload);
    if (result.success) {
      context.commit('SAVE_SHOT_SETTINGS', result.data);
    }
    return result;
  },
};

import API from '@/helpers/api';

export default {
  async getOrders(context, { businessId }) {
    const result = await API(context).get(`/orders/list/${businessId}`);
    if (result.success) {
      context.commit('SET_ORDERS', result.orders);
      return result;
    }
    return null;
  },
  async getOrderInfo(context, { orderId }) {
    const result = await API(context).get(`/orders/info/${orderId}`);
    if (result.success) {
      context.commit('SET_SINGLE_ORDER', result.order);
      return result.order;
    }
    return null;
  },
  async getCompletedOrders(context, businessId) {
    const result = await API(context).get(`/orders/completed/${businessId}`);
    if (result.success) {
      context.commit('SET_COMPLETED_ORDERS', result.orders);
      return result.orders;
    }
    return null;
  },
  async createOrder(context, { businessId }) {
    const result = await API(context).post('/orders/create', { businessId });
    if (result.success) {
      return result.order;
    }
    return null;
  },
  async updateOrder(context, { order }) {
    const result = await API(context).put('/orders/update', order);
    if (result.success) {
      context.commit('UPDATE_OR_INSERT_ORDER', result.order);
      // refresh business info
      context.dispatch('business/getBusiness', result.order.businessId, { root: true });
      return result.order;
    }
    return null;
  },
  async deleteOrder(context, { orderId }) {
    const result = await API(context).delete(`/orders/delete/${orderId}`);
    if (result.success) {
      context.commit('REMOVE_ORDER', orderId);
    }
  },
  async requestChanges(context, payload) {
    const result = await API(context).put(`/orders/request-changes/${payload.orderId}`, payload);
    if (result.success) {
      context.commit('SET_SINGLE_ORDER', result.order);
      return result;
    }
    return null;
  },
  async confirmOrder(context, orderId) {
    const result = await API(context).put(`/orders/approve/${orderId}`);
    if (result.success) {
      context.commit('SET_SINGLE_ORDER', result.order);
      return result;
    }
    return null;
  },
  async completeOrder(context, { orderId, sendNotification }) {
    const result = await API(context).put(`/orders/complete-order/${orderId}`, { sendNotification });
    if (result.success) {
      context.commit('REMOVE_ORDER', orderId);
      context.commit('UPDATE_OR_INSERT_INTO_COMPLETED_ORDERS', result.order);
      return result.order;
    }
    return null;
  },
  async getPendingOrders(context, { businessId }) {
    const result = await API(context).get(`/orders/pending-approval/${businessId}`);
    if (result.success) {
      context.commit('SET_PENDING_ORDERS', result.orders);
      return result.orders;
    }
    return null;
  },
  async uploadAssets(context, formData) {
    const result = await API(context).post('/orders/upload/', formData);
    if (result.success) {
      return result;
    }
    return null;
  },
  async uploadAssetLibrary(context, formData) {
    const result = await API(context).post('/orders/uploadAssets/', formData);
    if (result.success) {
      return result;
    }
    return null;
  },
  async getOrderFiles(context, { orderId }) {
    const result = await API(context).get(`/orders/files/${orderId}`);
    if (result.success) {
      context.commit('SET_ORDER_FILES', {
        orderId,
        files: result.files,
      });
      return result.files;
    }
    return null;
  },
  async removeAsset(context, { orderId, fileId, orderDeliverableId }) {
    const result = await API(context).delete(`/orders/delete-file/${fileId}`);
    if (result.success) {
      context.commit('REMOVE_ORDER_FILE', {
        orderId,
        fileId,
        orderDeliverableId,
      });
      return result;
    }
    return null;
  },
};

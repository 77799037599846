export default {
  sweeps(state) {
    return state.sweeps;
  },
  sweepsList(state) {
    return state.sweepsList;
  },
  currentSweep(state) {
    return state.currentSweep;
  },

  preferredCreatives(state) {
    return state.preferredCreatives;
  },
  recentSweepCreatives(state) {
    return state.recentSweepCreatives;
  },
  cancelReasons(state) {
    return state.cancelReasons;
  },
  timeLine(state) {
    return state.timeLine;
  },
};

import _ from 'lodash';
import { getUser } from '@/helpers';

export const initialActionsToCall = [{
  namespace: 'profile',
  action: 'getClientOptions',
  roles: ['client', 'member'],
},
{
  namespace: 'project',
  action: 'getUserProjects',
  roles: ['client', 'member'],
},
{
  namespace: 'profile',
  action: 'getCreativeOptions',
  roles: ['creative'],
}, {
  namespace: 'profile',
  action: 'getExecutiveOptions',
  roles: ['producer'],
}];

export const initializeStoreWithInfo = (store) => {
  const { dispatch } = store;
  dispatch('getSweepTeamReasons');
  const user = getUser();
  if (user !== null) {
    //   Actions to be called on genesis
    const currentRole = _.get(user, 'userDetails.role.name');
    _.forEach(initialActionsToCall, ({ namespace, action, roles }) => {
      if (_.includes(roles, currentRole)) {
        dispatch(`${namespace}/${action}`);
      }
    });
  }
};

export default (store) => {
  initializeStoreWithInfo(store);
  // eslint-disable-next-line no-unused-vars
  store.subscribe((mutation, state) => {
    if (mutation.type === 'user/SET_USER_TOKEN') {
      initializeStoreWithInfo(store);
    }
  });
};

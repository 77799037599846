import API from '@/helpers/api';

export default {
  async getChatToken(context) {
    const result = await API(context).post('/chats/token');
    if (result.success) {
      context.commit('SET_CHAT_TOKEN', result.token);
    }
    return result;
  },

  async getUsers(context) {
    const result = await API(context).get('/chats/user_list');
    if (result.success) {
      context.commit('SET_USERS', result.users);
    }
    return result;
  },

  async getChannelMessages(context, id) {
    const result = await API(context).get(`/chats/${id}/messages`);
    if (result.success) {
      context.commit('SET_CHANNEL_MESSAGES', result.messages);
    }
    return result;
  },

  async getMediaMessage(context, mediaSid) {
    const result = await API(context).get(`/chats/${mediaSid}/messages_media`);
    // if (result.success) {
    //   context.commit('SET_CHANNEL_MESSAGES', result.messages);
    // }
    return result;
  },

  setMessages({ commit }, messages) {
    commit('SET_CHANNEL_MESSAGES', messages);
  },

  setChannels({ commit }, channels) {
    commit('SET_CHANNELS', channels);
  },

  addChannel({ commit }, channel) {
    commit('ADD_CHANNEL', channel);
  },

  async addUserToTwilioCloud(context, userId) {
    const result = await API(context).post('/chats/add_user', { userId });
    return result;
  },

  resetState({ commit }) {
    commit('RESET');
  },
};

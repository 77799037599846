import _ from 'lodash';

export default {
  userContentKits(state) {
    return state.contentKits;
  },

  currentContentKit(state) {
    return state.currentContentKit;
  },

  contentKits(state) {
    return state.contentPlans;
  },

  projectSubscriptionBlend(state) {
    return state.projectSubscriptionBlend;
  },

  currentKitProjectDeliverables(state) {
    return state.currentKitProjectDeliverables;
  },

  contentKitFiles(state) {
    // const contentKitFiles =
    // _.sortBy(state.contentKitFiles, [(file) => file.name.toLowerCase()], ['asc']);
    return _.sortBy(state.contentKitFiles, [(file) => file.name], ['asc']);
  },

  dimensions(state) {
    return state.dimensions;
  },

  socialTags(state) {
    return state.socialTags;
  },
  loadingMore(state) {
    return state.loadingMore;
  },
  hasMore(state) {
    return state.hasMore;
  },
};

export default {
  chatToken(state) {
    return state.chatToken;
  },

  users(state) {
    return state.users;
  },

  messages(state) {
    return state.channelMessages;
  },

  channels(state) {
    return state.channels;
  },
};

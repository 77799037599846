<template>
    <v-snackbar
    v-if="canShow"
      v-model="snackbar"
      :color="type"
      multi-line
      top
      right
      :timeout="timeout"
    >
      {{ message }}
      <v-icon
        dark
        right
        @click="snackbar = false"
      >
        mdi-close-circle
      </v-icon>
    </v-snackbar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  props: {
    message: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return ({
      snackbar: true,
      timeout: 6000,
    });
  },
  updated() {
    if (this.notification) {
      this.setNotification(null);
    }
  },
  computed: {
    ...mapGetters(['notification']),
    canShow() {
      const paths = ['/onboarding/setup'];
      if (paths.indexOf(this.$route.path) >= 0) {
        return false;
      }
      if (this.$route.path === '/order_assets') {
        return false;
      }
      if (this.$route.query.method === 'payment') {
        return false;
      }
      return true;
    },
  },
  methods: {
    ...mapActions(['setNotification']),
  },
};
</script>

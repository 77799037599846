export const getInitialState = () => ({
  sweeps: {
    upcoming: [],
    elapsed: [],
    pending: [],
  },
  currentSweep: {},
  preferredCreatives: [],
  recentSweepCreatives: [],
  cancelReasons: [],
  timeLine: [],
  sweepsList: {
    active: [],
    upcoming: [],
    history: [],
  },
});

export default {
  SET_SWEEPS(state, result) {
    state.sweeps[result.type] = result.sweeps;
  },
  SET_SWEEPS_LIST(state, result) {
    state.sweepsList = result.sweepsList;
  },

  SET_CURRENT_SWEEP(state, sweep) {
    state.currentSweep = sweep;
  },

  SET_PREFERRED_CREATIVES(state, creatives) {
    state.preferredCreatives = creatives;
  },
  SET_RECENT_SWEEP_CREATIVES(state, creatives) {
    state.recentSweepCreatives = creatives;
  },

  REMOVE_SWEEP(state, sweep) {
    const index = state.sweeps[sweep.type].findIndex((item) => item.id === sweep.sweepId);
    if (index !== -1) state.sweeps[sweep.type].splice(index, 1);
  },

  RESET(state) {
    Object.assign(state, getInitialState());
  },

  SET_CANCEL_REASONS(state, reasons) {
    state.cancelReasons = reasons;
  },
  TIMELINE(state, timeLine) {
    state.timeLine = timeLine;
  },
};

export default {
  selectedUserRole(state) {
    return state.selectedUserRole;
  },

  analytics(state) {
    return state.analytics;
  },
  contentSweeps(state) {
    return state.contentSweeps;
  },
  creativePayTiers(state) {
    return state.creativePayTiers;
  },
  deliverables(state) {
    return state.deliverables;
  },
  categories(state) {
    return state.categories;
  },
  creativeTypes(state) {
    return state.creativeTypes;
  },
  subscriptionBlends(state) {
    return state.subscriptionBlends;
  },
  appPreferences(state) {
    return state.appPreferences;
  },
};

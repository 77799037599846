import _ from 'lodash';
import { getUser } from '@/helpers';

export const initializeAbility = (store) => {
  const { ability } = store.getters;
  const user = getUser();
  if (user) {
    const rules = _.get(user, 'userDetails.features', []);
    ability.update(rules);
  }
};

export default (store) => {
  initializeAbility(store);
  const { ability } = store.getters;

  return store.subscribe((mutation) => {
    switch (mutation.type) {
      case 'user/SET_USER_TOKEN':
        initializeAbility(store);
        break;
      case 'user/REMOVE_USER':
        ability.update([]);
        break;
      default:
    }
  });
};

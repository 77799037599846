import _ from 'lodash';

export const getInitialState = () => ({
  chatToken: null,
  users: [],
  channels: [],
  channelMessages: [],
  projects: [],
});

export default {
  SET_CHAT_TOKEN(state, token) {
    state.chatToken = token;
  },

  RESET(state) {
    Object.assign(state, getInitialState());
  },

  SET_USERS(state, users) {
    state.users = users;
  },

  SET_CHANNELS(state, channels) {
    state.channels = channels;
  },

  ADD_CHANNEL(state, channel) {
    if (!_.find(state.channels, ['sid', channel.sid])) {
      state.channels = [...state.channels, channel];
    }
  },

  SET_CHANNEL_MESSAGES(state, messages) {
    state.channelMessages = messages;
  },

  SET_PROJECTS(state, projects) {
    state.projects = projects;
  },
};

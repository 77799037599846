export const getInitialState = () => ({
  selectedUserRole: '',
  clients: [],
  creatives: [],
  admins: [],
  executives: [],
  analytics: {},
  contentSweeps: {},
  creativePayTiers: [],
  deliverables: [],
  creativeTypes: [],
  categories: [],
  subscriptionBlends: [],
  appPreferences: {},
});

export default {
  SET_CLIENTS(state, users) {
    state.clients = users;
  },

  SET_CREATIVES(state, users) {
    state.creatives = users;
  },

  SET_ADMINS(state, users) {
    state.admins = users;
  },

  SET_EXECUTIVES(state, users) {
    state.executives = users;
  },

  SET_USER_ROLE(state, role) {
    state.selectedUserRole = role;
  },

  SET_ANALYTICS(state, analytics) {
    state.analytics = analytics;
  },

  SET_CONTENT_SWEEPS(state, contentSweeps) {
    state.contentSweeps = contentSweeps;
  },

  SET_PAY_TIERS(state, payTiers) {
    state.creativePayTiers = payTiers;
  },

  SET_DELIVERABLES(state, deliverables) {
    state.deliverables = deliverables;
  },

  SET_CREATIVE_TYPES(state, creativeTypes) {
    state.creativeTypes = creativeTypes;
  },

  SET_CATEGORIES(state, categories) {
    state.categories = categories;
  },

  SET_SUBSCRIPTIONS(state, subscriptionBlends) {
    state.subscriptionBlends = subscriptionBlends;
  },
  SET_APP_PREFERENCES(state, appPreferences) {
    state.appPreferences = appPreferences;
  },

  RESET(state) {
    Object.assign(state, getInitialState());
  },
};

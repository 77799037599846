import _ from 'lodash';

export default {
  orders(state) {
    // sort by createdAt
    return _.sortBy(state.orders, 'createdAt').reverse();
  },
  completedOrders(state) {
    // sort by createdAt
    return _.sortBy(state.completedOrders, 'createdAt').reverse();
  },
  pendingOrders(state) {
    // sort by createdAt
    return _.sortBy(state.pendingOrders, 'createdAt').reverse();
  },
  singleOrder(state) {
    return state.singleOrder;
  },
};

import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100 d-flex justify-space-between align-center"},[_c(VListItemAvatar,{staticClass:"file-image mr-1",attrs:{"width":"20","height":"20","min-width":"25"}},[(_vm.item.error)?[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"d-flex align-center"},on),[_c('Info',{attrs:{"color":"red","height":"16","width":"16"}})],1)]}}],null,false,648272150)},[_c('span',[_vm._v(" "+_vm._s(_vm.item.error)+" ")])])]:[_c('img',{attrs:{"src":require('@/assets/svg/theme/image.svg')}})]],2),_c(VListItemContent,[_c(VListItemTitle,[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"file-name",class:{'truncate-name': _vm.showProgress}},[_vm._v(" "+_vm._s(_vm.item.file.name)+" ")]),(_vm.showProgress)?_c('div',{staticClass:"upload-size ml-3"},[_vm._v(" "+_vm._s(_vm.uploadedSize)+" of "+_vm._s(_vm.totalSize)+" ")]):_vm._e()])]),(_vm.showProgress)?_c('span',{staticClass:"pt-1"},[_c(VProgressLinear,{attrs:{"value":_vm.uploadPercentage}})],1):_vm._e()],1),_c(VListItemAction,[(_vm.item.error)?[_c(VIcon,{attrs:{"size":"16","color":"red"},on:{"click":function($event){return _vm.removeItem()}}},[_vm._v(" mdi-close-circle ")])]:(_vm.item.completed)?[_c(VIcon,{staticClass:"icon",attrs:{"size":"16","color":"#02e648"}},[_vm._v(" mdi-check-circle ")])]:_c('div',{on:{"click":function($event){return _vm.cancelItem()}}},[_c(VIcon,{staticClass:"icon close-fade",attrs:{"size":"16"}},[_vm._v(" mdi-close-circle ")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
export default {
  projects(state) {
    return state.projects;
  },
  businesses(state) {
    return state.businesses;
  },

  userProjects(state) {
    return state.userProjects;
  },

  currentProject(state) {
    return state.currentProject;
  },

  creativeTeam(state) {
    return state.currentProjectTeam.creativeTeam;
  },

  producersTeam(state) {
    return state.currentProjectTeam.producersTeam;
  },

  executiveTeam(state) {
    return state.currentProjectTeam.executiveTeam;
  },
  allCreatives(state) {
    return state.currentProjectTeam.allCreatives;
  },
  editorTeam(state) {
    return state.currentProjectTeam.editorTeam;
  },
  projectTeam(state) {
    return state.currentProjectTeam;
  },

  sweeps(state) {
    return state.sweeps;
  },
};

export const getInitialState = () => ({
  business: {},
  brandColors: [],
  brandKits: [],
  brandSources: {},
  brandMusic: {},
  brandStyle: {},
  brandFonts: {},
  brandKitInspirations: [],
  brandKitLinks: [],
  businessDescription: '',
  businessName: '',
  businessUsers: [],
  reasons: [],
  creditsHistory: [],
  goals: [],
  contentPlan: {},
  categories: [],
});

export default {
  SET_BUSINESS(state, business) {
    state.business = business;
    state.businessDescription = business.description;
    state.businessName = business.name;
  },

  SET_BRAND_COLORS(state, colors) {
    state.brandColors = colors;
  },

  SET_BRAND_KIT_INSPIRATION(state, files) {
    state.brandKitInspirations = files;
  },

  SET_BRAND_KIT_LINKS(state, links) {
    state.brandKitLinks = links;
  },

  SET_BRAND_KITS(state, files) {
    state.brandKits = files;
  },
  SET_CONTENT_PLAN(state, contentPlan) {
    state.contentPlan = contentPlan;
  },
  SET_BRAND_MUSIC(state, music) {
    state.brandMusic = music;
  },
  SET_BRAND_STYLE(state, style) {
    state.brandStyle = style;
  },
  SET_BRAND_FONTS(state, fonts) {
    const primary = fonts.find((f) => f.fontType === '1') || {};
    const secondary = fonts.find((f) => f.fontType === '2') || {};
    state.brandFonts = {
      primary: {
        fontId: primary.id,
        ...primary,
        ...(primary.BrandFont || {}),
        custom: Boolean(primary.BrandFont && primary.BrandFont.businessId),
      },
      secondary: {
        fontId: secondary.id,
        ...secondary,
        ...(secondary.BrandFont || {}),
        custom: Boolean(primary.BrandFont && primary.BrandFont.businessId),
      },
    };
  },
  SET_BRAND_FONT(state, font) {
    if (font.fontType === '1') {
      state.brandFonts.primary = font;
    } else {
      state.brandFonts.secondary = font;
    }
    const isFontExists = state.brandSources.brandFonts.find((f) => f.id === font.id);
    if (!isFontExists) {
      state.brandSources.brandFonts = [...state.brandSources.brandFonts, font];
    }
  },
  DELETE_BRAND_KIT_DOCUMENT(state, id) {
    state.brandKits = state.brandKits.filter((x) => x.id !== id);
  },

  DELETE_BRAND_FONT(state, id) {
    state.brandSources.brandFonts = state.brandSources.brandFonts.filter((x) => x.id !== id);
    const primary = state.brandFonts.primary.id === id ? {} : state.brandFonts.primary;
    const secondary = state.brandFonts.secondary.id === id ? {} : state.brandFonts.secondary;
    state.brandFonts = {
      primary: {
        ...primary,
        ...(primary.BrandFont || {}),
        custom: Boolean(primary.BrandFont && primary.BrandFont.businessId),
      },
      secondary: {
        ...secondary,
        ...(secondary.BrandFont || {}),
        custom: Boolean(primary.BrandFont && primary.BrandFont.businessId),
      },
    };
  },

  RESET(state) {
    Object.assign(state, getInitialState());
  },
  SET_BRAND_SOURCES(state, { brandStyles, brandMusic, brandFonts }) {
    state.brandSources = { brandStyles, brandMusic, brandFonts };
  },
  SET_BUSINESS_USERS(state, businessUsers) {
    state.businessUsers = businessUsers;
  },
  SET_BUSINESS_WORKSPACES(state, workspaces) {
    state.workspaces = workspaces;
  },
  SET_REASONS(state, reasons) {
    state.reasons = reasons;
  },
  SET_GOALS(state, goals) {
    state.goals = goals;
  },
  SET_CREDITS_HISTORY(state, history) {
    state.creditsHistory = history;
  },
  SET_FILE_CATEGORIES(state, categories) {
    state.categories = categories;
  },
};

<template>
  <v-app>
    <hyper-translate @loaded="()=>$hyper.reboot()"></hyper-translate>
    <NavigationDrawer ref="drawer" v-if="isNeeded" />
    <AppBar @toggle-menu="$refs.drawer.drawer = !$refs.drawer.drawer" v-if="isNeeded" />
    <!-- <AdminAppBar v-else-if="isNeeded && isAdmin"/> -->
    <v-content :class="mainClass">
      <!-- <MobileBar v-if="isNeeded"/> -->
      <router-view></router-view>
    </v-content>
    <v-footer app v-if="isNeeded && isFooterNeeded"></v-footer>
    <Notification v-if="notification" :message="notification.message" :type="notification.type" />
    <ProgressNotifier v-if="uploadingItems.length || downloadItems.length"> </ProgressNotifier>
    <v-dialog
      v-model="massiveErrorDialog"
      width="500"
    >
      <v-card style="border-radius: unset !important">
        <v-card-title class="text-h5 grey lighten-2">
          Oops,
        </v-card-title>
        <v-card-text class="pt-2">
          Looks like MASV portal is not configured to your account yet,
          please contact our team.
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primaryGray1"
            text
            @click="massiveErrorDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="massivePortal"
      width="450"
    >
      <div style=" background: white; border-radius: unset !important;">
        <div class="d-flex px-2 pr-2 masv-card-header">
            <v-card-title class='pt-2 pb-0 pl-2 pr-0 modal-header-title text-capitalize'>
              Upload Content
            </v-card-title>
            <v-spacer></v-spacer>
            <v-icon
              class="pr-1 cursor-pointer"
              @click="()=>massivePortal=false">
                mdi-close
            </v-icon>
        </div>
        <v-card-text class="pt-2">
          <iframe
            :key="massivePortalURL"
            :src="massivePortalURL" style="width: 100%; min-height: 600px;"
            frameborder="0"
          ></iframe>
        </v-card-text>
      </div>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import NavigationDrawer from '@/components/common/NavigationDrawer';
import AppBar from '@/components/common/AppBar';
import Notification from '@/components/common/Notification';
import ProgressNotifier from '@/components/common/ProgressNotifier';
// import MobileBar from '@/components/common/MobileBar';
import EventBus from '@/helpers/event-bus';

// import AdminAppBar from '@/components/dashboard/screens/Admin/AdminAppBar';

export default {
  name: 'App',
  components: {
    NavigationDrawer,
    AppBar,
    Notification,
    ProgressNotifier,
    // MobileBar,
  },
  data() {
    return ({
      massiveErrorDialog: false,
      massivePortal: false,
      massivePortalURL: '',
    });
  },
  mounted() {
    EventBus.$on('openMassivePortal', this.openMassivePortal);
    // attach channel variable to vue instance
    // eslint-disable-next-line
    window.applicationBroadcastChannel = new BroadcastChannel('bs-broadcast-channel');
    window.applicationBroadcastChannel.onmessage = (event) => {
      if (event.data === 'LOGOUT') {
        // redirect to /
        this.$router.push('/');
      }
      if (event.data === 'LOGIN') {
        // reload page
        window.location.href = '/dashboard';
      }
    };
  },
  beforeDestroy() {
    EventBus.$off('openMassivePortal', this.openMassivePortal);
  },
  methods: {
    ...mapActions('business', ['getMassivePortalInfo']),
    async openMassivePortal() {
      // get portal info using portalId from business
      const portal = await this.getMassivePortalInfo({ businessId: this.business.id });
      if (!portal) {
        // show alert modal and return
        this.massiveErrorDialog = true;
        return;
      }
      // make embed url
      const userEmail = this.userDetails.email;
      // eslint-disable-next-line
      const embedURL = `${portal.portalURL}/embed?email=${userEmail}&hidename=true&now=${Date.now()}`;
      this.massivePortalURL = embedURL;
      // get portal url of portalId
      this.massivePortal = true;
    },
  },
  computed: {
    ...mapGetters(['notification', 'uploadingItems', 'downloadItems']),
    ...mapGetters('user', ['userDetails']),
    ...mapGetters('business', ['business']),
    mainClass() {
      if (this.isAdmin) {
        return 'admin-content-wrap';
      }
      if ((this.role === 'creative' && this.$route.name === 'Profile') || this.$route.name === 'Public Profile') {
        return 'creative-bg-white';
      }
      return '';
    },
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    isNeeded() {
      return this.$route.meta.navPanel;
    },
    isAdmin() {
      return this.role === 'admin';
    },
    isFooterNeeded() {
      return false;
    },
  },
};
</script>

<style src="./styles/overwrite.css"></style>
<style lang="scss">
  @import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
  @import "../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
  .v-application {
    font-size: 14px;
    font-family: $fontFamily1 !important;
    background: $neutral6 !important;
  }
  // .admin-content-wrap {
  //   background: white;
  // }
  .grey-text {
    color: #bdbdbd !important;
  }
  input:-webkit-autofill {
    -webkit-box-shadow:0 0 0 50px white inset;
  }
  .loading-template {
    border: solid 1px;
    border-color: #babfc7;
    background: #ececec;
    border-radius: 3px;
    box-shadow: 0 1px 4px 1px rgba(186, 191, 199, 0.4);
    color: black;
    padding: 6px;
  }
  .main-modal {
    overflow-y: hidden;
    background-color: $neutral6;
  }
  .onboarding-modal {
    background-color: $neutral6;
  }
  .onboarding-modal.v-dialog {
    height: 100vh;
  }
  .font-family-2 {
    font-family: $fontFamily1;
  }
  .select-dropdown, .sort-dropdown{
    margin-top:50px;
  }
  .select-dropdown>.v-list>.v-list-item:not(:last-child){
    border-bottom:1px solid $neutral3;
    color:$neutral6 !important;
  }
  .select-dropdown>.v-list>.v-list-item--active::after{
    content:url('../src/assets/svg/check.png');
  }
  .select-dropdown>.v-select-list>.v-list-item>.v-list-item__content>.v-list-item__title {
    font-size: 14px;
    font-family: $fontFamily1;
    font-weight:300 !important;
    color: $neutral1;
    line-height: normal;
    min-width: 70%;
  }

  .skillset-dropdown, .creatives-dropdown, .filter-dropdown, .talent-skills-dropdown {
    font-family: $fontFamily1;
    .v-list-item__action {
      margin-right: 5px !important;
    }
    .v-list-item__content .v-list-item__title {
      line-height: normal;
    }
  }

  .talent-skills-dropdown {
    .v-list-item__content .v-list-item__title {
      text-transform: capitalize;
    }
  }

  .filter-dropdown {
    border-radius: 0;
    box-shadow: none;
    .v-list {
      border-radius: 3px;
      border: solid 1px $border2;
    }
    &.analytics {
      .v-list-item {
        min-height: 30px;
      }
      .v-list-item__content {
        padding: 0;
      }
    }
  }
  .talent-type-dropdown {
    .v-list-item {
      text-transform: capitalize;
    }
  }

  .admin-dropdown {
    margin-top: 40px;
    border-radius: 0 !important;
    box-shadow: 0 2px 2px 0 $silver;
    .v-list {
      padding: 0;
    }
    .v-list-item {
      padding: 0;
      min-height: auto;
      .v-list-item__content {
        font-family: $fontFamily1;
        font-size: 14px !important;
        font-weight: 300;
        letter-spacing: -0.11px;
        padding: 6px 10px;
      }
    }
  }
  .admin-menu {
    border-radius: 0 !important;
    box-shadow: 0 2px 2px 0 $silver;
    margin-top: 5px;
    .v-list {
      padding: 0;
    }
    .v-list-item {
      padding: 4px 20px;
      padding-right: 30px;
      min-height: auto;
      .v-list-item__content {
        font-family: $fontFamily1;
        font-size: 14px !important;
        font-weight: 300;
        letter-spacing: -0.11px;
        padding: 6px 10px;
      }
      .v-list-item__title {
        line-height: 1.3;
      }
    }
  }
  .asset-preview-modal {
    box-shadow: none !important;
    max-height: 95% !important;
  }
  .asset-preview-modal::-webkit-scrollbar {
    width: 0 !important;
  }
  .preview-modal {
    background: $neutral3;
  }
  .preview-modal:not(.v-dialog--fullscreen) {
    max-height: 95%;
  }
  .new-user-form{
    background-color: $neutral6;
    width:618px;
  }

  .card-heading {
    font-weight: bold;
    color: $card-title;
  }
  .card-content {
    font-family: $fontFamily1;
  }

  .text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-height: 1.3em;
    &.two-line {
      -webkit-line-clamp: 2;
    }
  }
  .pac-container .pac-item {
    cursor: pointer;
  }
  .creative-bg-white {
    background-color: #ffff;
  }
  @media (max-width: 600px) {
    .admin-menu {
      .v-list-item {
        padding: 4px 10px;
        padding-right: 20px;
      }
    }
  }
  .goog-tooltip {
      display: none !important;
  }
  .goog-tooltip:hover {
      display: none !important;
  }
  .goog-text-highlight {
      background-color: transparent !important;
      border: none !important;
      box-shadow: none !important;
  }
  .masv-card-header {
    position: sticky;
    top: 0;
    background: white;
  }
</style>

export default {
  businessTypes(state) {
    return state.client.businessTypes;
  },
  executiveBusinessTypes(state) {
    return state.executive.businessTypes;
  },
  contentTypes(state) {
    return state.client.contentTypes;
  },
  timeRanges(state) {
    return state.client.timeRanges;
  },
  priceRanges(state) {
    return state.client.priceRanges;
  },
  creativePreferences(state) {
    return state.creative;
  },
  skillsets(state) {
    return state.creative.skillsets;
  },
  interests(state) {
    return state.creative.interests;
  },
  accessories(state) {
    return state.creative.accessories;
  },
  cameraEquipments(state) {
    return state.creative.cameraEquipments;
  },
  writerInterests(state) {
    return state.creative.writerInterests;
  },
  lensEquipments(state) {
    return state.creative.lensEquipments;
  },
  styles(state) {
    return state.creative.styles;
  },
  subjects(state) {
    return state.creative.subjects;
  },
  payTiers(state) {
    return state.creative.payTiers;
  },
  userProfile(state) {
    return state.profile;
  },
  clientProfile(state) {
    return state.profile.client;
  },
  creativeProfile(state) {
    return state.profile.creative;
  },
  executiveProfile(state) {
    return state.profile.executive;
  },
  producerProfile(state) {
    return state.profile.producer;
  },
  editorProfile(state) {
    return state.profile.editor;
  },
  communityManagerProfile(state) {
    return state.profile.community_manager;
  },
  agencyCustomer(state) {
    return state.profile.agency_customer;
  },
  brandKits(state) {
    return state.profile.client.brandKits;
  },
  brandColors(state) {
    return state.profile.client.brandColors;
  },
  agreements(state) {
    return (role) => state.profile[role].agreements;
  },
  portfolioItems(state) {
    return state.profile.portfolioItems;
  },
};

import _ from 'lodash';

export const getInitialState = () => ({
  brandProfile: {},
  brandSources: {},
});

const formatBrandFonts = (fonts) => {
  const primary = fonts.find((f) => f.fontType === '1') || {};
  const secondary = fonts.find((f) => f.fontType === '2') || {};
  const brandFonts = {
    primary: {
      fontId: primary.id,
      ...primary,
      ...(primary.BrandFont || {}),
      custom: Boolean(primary.BrandFont && primary.BrandFont.businessId),
    },
    secondary: {
      fontId: secondary.id,
      ...secondary,
      ...(secondary.BrandFont || {}),
      custom: Boolean(secondary.BrandFont && secondary.BrandFont.businessId),
    },
  };
  return brandFonts;
};

export default {
  SET_BRAND_PROFILE(state, brandProfile) {
    state.brandProfile = { ...brandProfile };
    state.brandProfile = {
      ...state.brandProfile,
      phoneNumber: _.get(brandProfile, 'mobile', ''),
      brandFonts: formatBrandFonts(_.get(brandProfile, 'brandFont', [])),
      brandMusic: _.get(brandProfile, 'brandMusic[0].BrandMusic', {}),
      brandStyle: _.get(brandProfile, 'brandStyles[0].BrandStyle', {}),
    };
  },
  SET_BRAND_COLORS(state, colors) {
    const brandProfile = { ...state.brandProfile };
    brandProfile.brandColors = colors;
    state.brandProfile = brandProfile;
  },
  SET_BRAND_KIT_INSPIRATION(state, files) {
    const brandProfile = { ...state.brandProfile };
    brandProfile.brandKitInspirations = files;
    state.brandProfile = brandProfile;
  },

  SET_BRAND_KIT_LINKS(state, links) {
    const brandProfile = { ...state.brandProfile };
    brandProfile.brandKitLinks = links;
    state.brandProfile = brandProfile;
  },

  SET_BRAND_KITS(state, files) {
    const brandProfile = { ...state.brandProfile };
    brandProfile.brandKits = files;
    state.brandProfile = brandProfile;
  },

  SET_BRAND_MUSIC(state, music) {
    const brandProfile = { ...state.brandProfile };
    brandProfile.brandMusic = music;
    state.brandProfile = brandProfile;
  },
  SET_BRAND_STYLE(state, style) {
    const brandProfile = { ...state.brandProfile };
    brandProfile.brandStyle = style;
    state.brandProfile = brandProfile;
  },
  SET_BRAND_FONTS(state, fonts) {
    const primary = fonts.find((f) => f.fontType === '1') || {};
    const secondary = fonts.find((f) => f.fontType === '2') || {};
    state.brandProfile.brandFonts = {
      primary: {
        ...primary,
        ...(primary.BrandFont || {}),
        fontId: primary.id,
        custom: Boolean(primary.BrandFont && primary.BrandFont.businessId),
      },
      secondary: {
        ...secondary,
        ...(secondary.BrandFont || {}),
        fontId: secondary.id,
        custom: Boolean(secondary.BrandFont && secondary.BrandFont.businessId),
      },
    };
  },
  SET_BRAND_FONT(state, font) {
    const brandProfile = { ...state.brandProfile };
    if (font.fontType === '1') {
      brandProfile.brandFonts.primary = font;
    } else {
      brandProfile.brandFonts.secondary = font;
    }
    const isFontExists = state.brandSources.brandFonts.find((f) => f.id === font.id);
    if (!isFontExists) {
      state.brandSources.brandFonts = [...state.brandSources.brandFonts, font];
    }
    state.brandProfile = brandProfile;
  },
  DELETE_BRAND_KIT_DOCUMENT(state, id) {
    const brandProfile = { ...state.brandProfile };
    brandProfile.brandKits = brandProfile.brandKits.filter((x) => x.id !== id);
    state.brandProfile = brandProfile;
  },

  DELETE_BRAND_FONT(state, id) {
    state.brandSources.brandFonts = state.brandSources.brandFonts.filter((x) => x.id !== id);
    const brandProfile = { ...state.brandProfile };
    // eslint-disable-next-line max-len
    const primary = brandProfile.brandFonts.primary.id === id ? {} : brandProfile.brandFonts.primary;
    // eslint-disable-next-line max-len
    const secondary = brandProfile.brandFonts.secondary.id === id ? {} : brandProfile.brandFonts.secondary;
    brandProfile.brandFonts = {
      primary: {
        ...primary,
        ...primary.BrandFont,
        custom: Boolean(primary.BrandFont && primary.BrandFont.businessId),
      },
      secondary: {
        ...secondary,
        ...secondary.BrandFont,
        custom: Boolean(secondary.BrandFont && secondary.BrandFont.businessId),
      },
    };
    state.brandProfile = brandProfile;
  },

  RESET(state) {
    Object.assign(state, getInitialState());
  },
  SET_BRAND_SOURCES(state, { brandStyles, brandMusic, brandFonts }) {
    state.brandSources = { brandStyles, brandMusic, brandFonts };
  },
};

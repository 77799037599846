import _ from 'lodash'
import API from '@/helpers/api'
import LogRocket from 'logrocket'
import * as Sentry from '@sentry/vue'
import { authenticate } from '@/helpers'

export default {
  async publicProfile(context, id) {
    const result = await API(context).get(`/profile/${id}`);
    if (result.success) {
      context.commit('SET_USER_PROFILE', result.user);
    }
  },
  async getBusinessTypes(context) {
    const result = await API(context).get('/business_types');
    if (result.success) {
      context.commit('SET_BUSINESS_TYPES', result.businessTypes);
    }
    return result.businessTypes;
  },
  async getContentTypes(context) {
    const result = await API(context).get('/content_types');
    if (result.success) {
      context.commit('SET_CONTENT_TYPES', result.contentTypes);
    }
  },
  async getPriceRanges(context) {
    const result = await API(context).get('/price_ranges');
    if (result.success) {
      context.commit('SET_PRICE_RANGES', result.priceRanges);
    }
  },
  async getTimeRanges(context) {
    const result = await API(context).get('/time_ranges');
    if (result.success) {
      context.commit('SET_TIME_RANGES', result.timeRanges);
    }
  },
  async getClientOptions(context) {
    const result = await API(context).get('/preferences/client_preferences');
    if (result.success) {
      const options = _.omit(result, 'success');
      context.commit('SET_CLIENT_OPTIONS', options);
    }
  },
  async getCreativeOptions(context) {
    const result = await API(context).get('/preferences/creative_preferences');
    if (result.success) {
      const options = _.omit(result, 'success');
      context.commit('SET_CREATIVE_OPTIONS', options);
    }
  },
  async getExecutiveOptions(context) {
    const result = await API(context).get('/preferences/executive_preferences');
    if (result.success) {
      const options = _.omit(result, 'success');
      context.commit('SET_EXECUTIVE_OPTIONS', options);
    }
  },
  async clientProfileUpdate(context, profileObj) {
    const result = await API(context).post('/users/client_profile_update', profileObj);
    if (result.success) {
      const userDetails = _.omit(result.user, 'profile');
      context.commit('SET_USER_PROFILE', result.user);
      context.commit('user/SET_USER', userDetails, { root: true });
    }
    return result;
  },
  async creativeProfileUpdate(context, profileObj) {
    const result = await API(context).post('/users/creative_profile_update', profileObj);
    if (result.success) {
      const userDetails = _.omit(result.user, 'profile');
      // context.commit('SET_USER_PROFILE', result.user);
      context.commit('user/SET_USER', userDetails, { root: true });
      context.dispatch('getProfile');
    }
    return result;
  },
  async producerProfileUpdate(context, profileObj) {
    const result = await API(context).post('/users/producer_profile_update', profileObj);
    if (result.success) {
      const userDetails = _.omit(result.user, 'profile');
      context.commit('SET_USER_PROFILE', result.user);
      context.commit('user/SET_USER', userDetails, { root: true });
    }
    return result;
  },
  async editorProfileUpdate(context, profileObj) {
    const result = await API(context).post('/users/editor_profile_update', profileObj);
    if (result.success) {
      const userDetails = _.omit(result.user, 'profile');
      context.commit('SET_USER_PROFILE', result.user);
      context.commit('user/SET_USER', userDetails, { root: true });
    }
    return result;
  },
  async userProfileUpdate(context, profileObj) {
    const result = await API(context).post('/users/user_profile_update', profileObj);
    if (result.success) {
      const userDetails = _.omit(result.user, 'profile');
      context.commit('SET_USER_PROFILE', result.user);
      context.commit('user/SET_USER', userDetails, { root: true });
    }
    return result;
  },
  async getSavedProfile(context) {
    const result = await API(context).get('/users/me');
    if (result.success) {
      const storedBSData = {
        // eslint-disable-next-line no-underscore-dangle
        ...JSON.parse(localStorage.__bs_valid),
        userDetails: result.user,
      };
      localStorage.setItem('__bs_valid', JSON.stringify(storedBSData));
      context.commit('user/SET_USER', storedBSData.userDetails, { root: true });
      context.commit('onboarding/SET_USER_PROFILE', result.user, { root: true });
    } else {
      Sentry.addBreadcrumb({ data: { context } })
      LogRocket.captureMessage("Failure at getSavedProfile() to get profile.")
      console.warn("Failure at getSavedProfile() to get profile.", result)
      await authenticate()
    }
  },
  async getProfile(context, callback) {
    const result = await API(context).get('/users/me');
    if (result.success) {
      const storedBSData = {
        // eslint-disable-next-line no-underscore-dangle
        ...JSON.parse(localStorage.__bs_valid),
        userDetails: result.user,
      };
      localStorage.setItem('__bs_valid', JSON.stringify(storedBSData));
      context.commit('SET_USER_PROFILE', result.user);
      context.commit('user/SET_USER', storedBSData.userDetails, { root: true });
      context.commit('onboarding/SET_USER_PROFILE', result.user, { root: true });
      if (typeof callback === 'function') {
        callback(result.user);
      }
      return result.user;
    // eslint-disable-next-line
    } else {
      Sentry.addBreadcrumb({ data: { context, callback } })
      LogRocket.captureMessage("Failure at getProfile() to get profile.")
      console.warn("Failure at getProfile() to get profile.", result)
      await authenticate()
    }

    return null;
  },
  async getPronouns(context) {
    const result = await API(context).get('/pronouns');
    return result.pronouns;
  },
  async updateProfilePic(context, file) {

    const formData = new FormData();
    formData.set('profilePic', file);
    const result = await API(context).post('/users/profile_pic_update', formData);
    if (result.success) {
      context.commit('user/SET_USER_PROFILE_PIC', result.user, { root: true });
    } else {
      console.info({ context, file })
      LogRocket.captureException("Failure updating profile pic for user.")
    }
    return result;
  },
  updateProfile({ commit }, options) {
    commit('UPDATE_PROFILE', options);
  },

  updateEmail(context, email) {
    return API(context).put('/users/email_update', { email });
  },

  async downloadAgreement(context, fileId) {
    return API(context).get(`users/${fileId}/agreement_download`);
  },

  async getPortfolioItems(context, userId) {
    const result = await API(context).get(`portfolio_items/list?creativeId=${userId}`);
    if (result.success) {
      context.commit('SET_PORTFOLIO_ITEMS', result.portfolioItems);
    }
    return result;
  },

  async getReimbursement(context) {
    return API(context).get('users/sweep_details');
  },

  async deletePortfolioItem(context, id) {
    const result = await API(context).delete(`portfolio/${id}`);
    if (result.success) {
      context.commit('REMOVE_PORTFOLIO_ITEM', id);
    }
    return result;
  },
};

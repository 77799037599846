<template>
  <v-app-bar
    app
    class="v-app-bar--hide-shadow app-bar"
    height="72"
    :clipped-left="$vuetify.breakpoint.xsOnly"
  >
    <v-app-bar-nav-icon
      v-if="$vuetify.breakpoint.xsOnly"
      @click="$emit('toggle-menu')"
      class="toggle-menu"
      color="#fff"
    ></v-app-bar-nav-icon>
    <template>
      <div v-if="$vuetify.breakpoint.xsOnly">
        <router-link
          :to="agency_customer ? `/organizations/${userDetails.currentBusinessId}` : '/dashboard'"
        >
          <v-img class="logo" src="@/assets/svg/logo.svg" contain />
        </router-link>
      </div>
      <!-- <div v-else>
        <div v-if="showBreadcrumbs">
          <v-breadcrumbs
            :items="items"
            large
            class="app-bar-title"
            :class="title === 'Content Kit' ? 'multiple' : 'single'"
            exact
          >
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item
                :to="item.to"
                :disabled="item.disabled"
                exact
               :class="item.disabled ? 'breadcrumb-item' : ''"
              >
               <div class="text-ellipsis"
               :class="item.text === 'Projects' || item.text === 'Dashboard'
               || item.text === 'Asset Library' ? '' : 'notranslate'">
                 {{ item.text }} </div>
              </v-breadcrumbs-item>
            </template>
              <template v-slot:divider>
                  <v-icon class="pt-1" >mdi-chevron-right</v-icon>
              </template>
          </v-breadcrumbs>
        </div>
        <div v-else :key="title">
          <v-toolbar-title v-if="titleChecking" class="app-bar-title">
            <div :key="title">{{title}}</div>
          </v-toolbar-title>
          <v-toolbar-title v-else class="app-bar-title pl-1">
            <v-icon
              @click="onClick"
              v-if="$vuetify.breakpoint.smAndUp"
            >
              mdi-arrow-left
            </v-icon>
            Your {{title}}
          </v-toolbar-title>
        </div>
        </div> -->
      <v-spacer></v-spacer>
      <router-link to="/notifications">
        <v-btn
          icon
          small
          height="36"
          width="36"
          class="notification-icon"
          v-if="title !== 'Notifications'"
        >
        <BellIcon :color="$vuetify.breakpoint.xsOnly ? '#FFFFFF' : '#262626'" />
        </v-btn>
      </router-link>
      <div class="pl-md-4 pr-md-3 nav-buttons">
        <v-menu offset-y left content-class="admin-menu">
          <template v-slot:activator="{ on, attrs }">
            <div color="primary" dark v-bind="attrs" v-on="on">
              <template v-if="role === 'creative'">
                <v-badge bordered dot bottom overlap :color="availabilityCheck">
                  <v-avatar left tile>
                    <img
                      :src="getOptimizedS3ImageURL(profilePic, { width: 48, height: 48 })"
                      class="profile-icon"
                    /> </v-avatar
                ></v-badge>
              </template>
              <v-avatar v-else left tile>
                <img
                  :src="getOptimizedS3ImageURL(profilePic, { width: 48, height: 48 })"
                  class="profile-icon"
                />
              </v-avatar>
              <v-icon
                dense=""
                class="dropdown pl-2"
                :color="$vuetify.breakpoint.xsOnly ? '#fff' : '#d8d8d8'"
              >
                mdi-chevron-down
              </v-icon>
            </div>
          </template>
          <v-list class="user-menu">
            <v-list-item :key="'user'" v-if="role !== 'admin'">
              <div class="user-profile">
                <v-row>
                  <v-col cols="4" class="pb-1">
                    <v-avatar left tile>
                      <img
                        :src="getOptimizedS3ImageURL(profilePic, { width: 48, height: 48 })"
                        class="profile-icon"
                      />
                    </v-avatar>
                  </v-col>
                  <v-col cols="8" class="d-flex align-center pa-1 pt-3">
                    <div class="profile-name">
                      <v-list-item-title>
                        <div class="user-name text-ellipsis notranslate">
                          {{
                            `
                          ${userDetails.firstName} ${userDetails.lastName}
                          `
                          }}
                        </div>
                        <div class="user-status pt-1" v-if="this.role === 'creative'">
                          <v-badge dot inline :color="availabilityCheck"></v-badge>
                          <span class="user-status-text">{{
                            `
                            ${userDetails.availability === '1' ? 'Active' : 'Inactive'}
                            `
                          }}</span>
                        </div>
                      </v-list-item-title>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-list-item>
            <v-list-item class="edit-profile" :key="'profile'" v-if="user" @click="gotoProfile">
              <v-list-item-title>
                Profile
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              class="profile-settings"
              :key="'settings'"
              v-if="canAccessPreference"
              @click="gotoSettings"
            >
              <v-list-item-title>
                Preferences
              </v-list-item-title>
            </v-list-item>
            <v-list-item class="logout" :key="'logout'" @click="onLogout">
              <v-list-item-title>Log out</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
  <!-- <v-container
      v-if="creditsModal"
    >
      <Modal
        persistent
        content-class="new-user-form"
        :modal="creditsModal"
      >
        <Lowcredits
        @close-modal="closeLowcredits"/>
      </Modal>
    </v-container> -->
      </div>
    </template>
    <v-container fluid v-if="showLogoutConfirm">
      <Modal
        :fullscreen="$vuetify.breakpoint.xsOnly"
        :modal="showLogoutConfirm"
        width="650"
        persistent
      >
        <v-card class="pa-5 confirm-popup">
          <v-row class="pa-0">
            <v-col>
              Logout may cancel upload/download process in progress. Are you sure you want to
              continue?
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex justify-end py-0">
              <v-btn text @click="showLogoutConfirm = false" class="py-0" color="primaryGray1">
                Cancel
              </v-btn>
              <v-btn text type="submit" class="ml-2 btn-purple" @click="logout()">
                Yes, Logout.
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </Modal>
    </v-container>
    <template v-if="userStatusConfirm">
      <v-dialog v-model="userStatusConfirm" max-width="680">
        <v-card class="modal-card pa-4" flat>
          <v-card-text class="pa-2 black--text fontSize font-family-2 card-text">
            Are you sure you want to update your status to inactive? You will not receive Content
            Sweep invites in inactive status.
          </v-card-text>
          <v-card-actions class="pa-0">
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="userStatusConfirm = false"
              class="py-0 status-btn"
              color="primaryGray1"
            >
              No
            </v-btn>
            <v-btn text color="info" @click="updateUserStatusApi()" class="py-0 pr-2 status-btn">
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-app-bar>
</template>

<script>
/* eslint-disable global-require */
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import Modal from '@/components/common/Modal';
import { getOptimizedS3ImageURL } from '@/helpers/';
import BellIcon from '@/components/common/svg/BellIcon';
// import Lowcredits from '@/components/dashboard/Lowcredits';

export default {
  name: 'AppBar',
  components: {
    Modal,
    BellIcon,
    // Lowcredits,

  },
  data() {
    return {
      // creditsModal: false,
      menu: false,
      showLogoutConfirm: false,
      userStatusConfirm: false,
    };
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    ...mapGetters('project', ['currentProject', 'userProjects']),
    ...mapGetters('contentKit', ['currentContentKit']),
    ...mapGetters(['uploadingItems', 'downloadItems']),
    currentContentKitName() {
      return _.get(this.currentContentKit, 'name', '');
    },
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    canAccessPreference() {
      return !['client', 'member', 'agency_owner', 'agency_member', 'admin'].includes(this.role);
    },
    customer() {
      return _.includes(['client', 'member'], this.role);
    },
    agency_customer() {
      return _.includes(['agency_owner', 'agency_member'], this.role);
    },
    availabilityCheck() {
      const indication = this.userDetails.availability;
      if (indication === '1') {
        return 'green !important';
      }
      return 'red !important';
    },
    user() {
      return _.includes(['creative', 'executive', 'producer', 'agency_owner', 'agency_member', 'client', 'member', 'community_manager', 'editor'], this.role);
    },
    title() {
      switch (this.$route.name) {
        case 'Client Content Kit':
          return this.currentContentKitName;
        case 'Customer Brand Kit':
          return 'Brand Kit';
        case 'Customer Management':
        case 'User Management':
          return 'Users';
        case 'Project':
          return 'Businesses';
        case 'Organization':
          if (this.agency_customer) {
            return 'Businesses';
          }
          return 'Organization';
        default:
          return this.$route.name;
      }
    },
    titleChecking() {
      if (this.title !== 'Notifications' && this.title !== 'Content Plan') {
        return true;
      }
      return false;
    },
    profilePic() {
      const profilePic = _.get(this.userDetails, 'profilePic');
      // FIXME: check why 'null' coming?
      if (profilePic && profilePic !== 'null') {
        return profilePic;
      }
      return require('@/assets/svg/theme/user.svg');
    },
    uploadingItemsCount() {
      return _.filter(this.uploadingItems, { completed: false }).length;
    },
    downloadingItemsCount() {
      return _.filter(this.downloadItems, { completed: false }).length;
    },
    getStatus() {
      const status = _.get(this.userDetails, 'availability');
      if (status === '1') {
        return 'inactive';
      }
      return 'active';
    },
  },
  methods: {
    ...mapActions('user', ['logoutUser', 'creativeStatusUpdate']),
    ...mapActions('dashboard', ['getDashboardDetails']),
    getOptimizedS3ImageURL,
    closeLowcredits() {
      this.creditsModal = false;
    },
    logout() {
      this.logoutUser();
      this.$router.push('/');
    },
    gotoProfile() {
      this.$router.push('/profile');
    },
    gotoSettings() {
      this.$router.push('/settings');
    },
    onLogout() {
      if (this.uploadingItemsCount || this.downloadingItemsCount) {
        this.showLogoutConfirm = true;
      } else {
        this.logout();
      }
    },
    onClick() {
      if (this.title === 'Content Plan') {
        const { businessId } = this.$route.query;
        return this.$router.push(
          `/content_plan_history/${this.$route.params.projectId}?businessId=${businessId}`,
        );
      }
      if (this.title === 'Notifications') {
        return this.$router.go(-1);
      }
      return this.$router.push('/dashboard');
    },
    async updateUserStatus() {
      if (this.userDetails.availability === '1') {
        this.userStatusConfirm = true;
      } else {
        this.userStatusConfirm = false;
        this.updateUserStatusApi();
      }
    },
    async updateUserStatusApi() {
      const response = await this.creativeStatusUpdate({
        availability: this.userDetails.availability === '1' ? '2' : '1',
      });
      if (response.success) {
        this.userStatusConfirm = false;
      }
    },
  },
  mounted() {
    if (this.customer || this.agency_customer) {
      this.getDashboardDetails();
      // if (this.$route.query.referrer === 'login') {
      //  if (_.map(this.userDetails.userBusinesses, 'credits') < 20) {
      //    this.creditsModal = true;
      //  }
      // }
    }
  },
};
</script>

<style lang="scss" scoped>
.app-bar {
  background-color: $neutral6 !important;
  border-bottom: 1px solid #d8d8d8;
}
.fontSize {
  font-size: 16px;
}
::v-deep .v-badge--dot .v-badge__badge::after {
  border-width: 3px;
}
::v-deep .v-badge--dot .v-badge__badge {
  height: 13px;
  width: 13px;
  inset: calc(100% - 9px) auto auto calc(100% - 10px) !important;
  border-radius: 11px;
}
.app-bar-title,
::v-deep .v-breadcrumbs--large li {
  font-family: $fontFamily1 !important;
  font-size: 24px !important;
}
::v-deep .v-breadcrumbs--large {
  padding: 0 !important;
  .v-breadcrumbs__item--disabled {
    color: $neutral1;
  }
}
.notification-icon {
  margin-right: 10px;
}
.profile-icon {
  border-radius: 16px;
  object-fit: cover;
}
.v-breadcrumbs {
  text-transform: capitalize;
}
.logo {
  height: 64px;
  width: 170px;
}
.user-menu {
  .user-name {
    width: 110px;
    display: block;
  }
  .user-status {
    padding-top: 5px;
    ::v-deep .v-badge--dot .v-badge__badge {
      width: 9px;
      height: 9px;
    }
  }
}
@media only screen and (min-device-width: 320px) and
(max-device-width: 568px) and (-webkit-device-pixel-ratio: 2)
and (device-aspect-ratio: 40/71) and (orientation: portrait) {
  ::v-deep .v-toolbar__content {
    padding: 0;
  }
  .dropdown {
    padding-left: 2px !important;
    padding-right: 5px !important;
  }
  ::v-deep .v-toolbar__content .v-btn.v-btn--icon.v-size--default,
  .v-toolbar__extension .v-btn.v-btn--icon.v-size--default {
    padding-left: 10px;
  }
  .status-text {
    font-size: 14px !important;
    font-family: $fontFamily1 !important;
    word-break: break-word;
  }
  .status-btn {
    font-size: 16px;
  }
  .title {
    font-size: 14px;
  }
  .user-menu {
    font-family: $fontFamily1;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    .v-list-item__title {
      font-size: 13px !important;
      color: $neutral1 !important;
    }
    .user-profile {
      display: flex;
      align-items: center;
      padding-bottom: 12px;
      .profile-name {
        padding-left: 10px !important;
        .user-name {
          font-size: 14px !important;
          width: 100px;
        }
        .user-status {
          padding-top: 5px;
          ::v-deep .v-badge--dot .v-badge__badge {
            width: 9px;
            height: 9px;
          }
          .user-status-text {
            text-align: left;
            color: #727272;
            padding-left: 6px;
          }
        }
      }
    }
  }
}
@media (max-width: 600px) {
  .app-bar {
    background-color: $charcoalBlack !important;
  }
  ::v-deep .v-toolbar__content {
    margin-top: 0;
    height: 64px !important;
  }
  .app-bar-title,
  ::v-deep .v-breadcrumbs--large li {
    font-family: $fontFamily1 !important;
    font-size: 20px !important;
    max-width: 185px;
  }
  .notification-icon {
    margin-right: 2px;
  }
}
::v-deep .v-breadcrumbs li:nth-child(even) {
  padding: 0 12px;
}
@media (min-width: 601px) and (max-width: 700px) {
  ::v-deep .v-toolbar__content {
    padding: 0 10px;
  }
  ::v-deep .v-breadcrumbs li:nth-child(even) {
    padding: 0 4px;
  }
  .single .breadcrumb-item {
    max-width: 235px;
    padding-top: 4px;
  }
  .multiple .breadcrumb-item {
    max-width: 90px;
  }
}
@media (min-width: 701px) {
  ::v-deep .v-toolbar__content {
    padding: 0 35px 0 45px;
  }
}
@media (max-width: 1200px) {
  ::v-deep .v-breadcrumbs--large {
    .v-breadcrumbs__item--disabled {
      font-size: 20px;
    }
  }
}
@media (min-width: 701px) and (max-width: 860px) {
  ::v-deep .v-breadcrumbs li:nth-child(even) {
    padding: 0 8px !important;
  }
  .single .breadcrumb-item {
    max-width: 300px;
    padding-top: 4px;
  }
  .multiple .breadcrumb-item {
    max-width: 90px;
  }
}
@media (min-width: 801px) and (max-width: 1100px) {
  ::v-deep .v-breadcrumbs li:nth-child(even) {
    padding: 0 8px !important;
  }
  .single .breadcrumb-item {
    max-width: 380px;
    padding-top: 4px;
  }
  .multiple .breadcrumb-item {
    max-width: 190px;
  }
}
@media (min-width: 1101px) {
  .single .breadcrumb-item {
    max-width: 500px;
    padding-top: 4px;
  }
  .multiple .breadcrumb-item {
    max-width: 250px;
  }
}
</style>
